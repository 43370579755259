import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import MobileStepper from '@material-ui/core/MobileStepper';
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Grid from "@material-ui/core/Grid";
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import ReactHtmlParser from 'react-html-parser';

import { ChevronLeft, Info, Close } from '@material-ui/icons';
import Drawer from '@material-ui/core/Drawer';
import { makeStyles } from '@material-ui/core/styles';
import React, { useState } from "react";
import { strings } from "../localizedStrings";
import {useCustomization, callHandlerNextQuestion, callHandlerPreQuestion } from "../customization/Customization";
import {questionAnswers, calculateResultOneQuestion} from "../util/testResults";
import moment from "moment";
import TextInputFormat from "../components/TextInputFormat";
import { Box } from "@material-ui/core";
import { useKiosk } from "../hooks/useKiosk";
import { FontSizeSelector } from "../components/FontSizeSelector";

const screenResults = require('../util/screenResults');
const _ = require('lodash');

const useStyles = makeStyles(() => ({
  headerText: {
    display: "inline-block",
    width: "85%",
  },
  backIcon: {
    display: "inline-block",
    position: "absolute",
    left: '8px',
    width:"32px",
    height:"32px",
  },
  questionText: {
    color: "#5A5A5A",
    marginTop:"20px",
    "& ul, ol": {
      paddingInlineStart:"1.5em"
    },
  },
  checkListText: {
    color: "#424242",
  },
  bottomCentered: {
    height: "80px",
    width: "100%",
    marginBottom: "3vh",
    textTransform: "none",
    textAlign: "center"
  },
  topContainer: {
    display:"flex",
    flexDirection:"column",
    minHeight:"100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  paper: {
    height: 'calc(100% - 200px)',
    top: 200
  },
  moreInfoText: {
    color: "#5A5A5A",
  },
  textInput: {
    width: "100%"
  },
  mobileStepper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    marginTop:"20px",
    marginBottom: "10px",
    width:"100%",
    padding:"0px",
    background:"#ffffff",
  },
  mobileStepperDots: {
    margin: "0 7.5px 0 7.5px",
  },
  nextButton: {
    marginTop:"10px",
    width: "25vw",
    minHeight: "5vh",
    maxWidth: "300px",
    textTransform: "none",
    color: "white",

    "&.Mui-disabled" : {
      color: "#939398",
      backgroundColor: "#E6E6E6",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#939398",
    },
  },
  yesNoButton: {
    minWidth: "25vw",
    minHeight: "5vh",
    maxWidth: "300px",
  },
  yesNoContainer: {
    padding: "0",
    display: "flex",
    flexWrap: "nowrap",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "stretch",
  },
}));


export default function QuestionPage(props) {

  const config = useCustomization(props.language).surveyConfig;
  const primaryColor = config.primaryColor;
  const qFlow = config.surveyFlow;

  const { isKiosk } = useKiosk();
  const displayFontSizeSelector = isKiosk ?
    _.get(config, "showFontSizeSelectorKiosk", false)
    :
    _.get(config, "showFontSizeSelectorMobile", false);

  const [openMoreInfo, setOpenMoreInfo] = useState(false);
  const [enableNextQuestion, setEnableNextQuestion] = useState(true);
  const [disableButton, setDisableButton] = useState(false);

  const buttonSelected = {
    textTransform: "none",
    backgroundColor: primaryColor,
    color: "white",
    marginTop:"10px",
  };
  const buttonNotSelected = {
    textTransform: "none",
    marginTop:"10px",
  };

  const classes = useStyles();

  const nbQuestions = qFlow.length;
  strings.setLanguage(props.language);


  // Store the answer, 0 for no, 1 for yes, -1 not answered yet
  const [qstate, setQstate] = React.useState(() => {
    let defaultAnswers = [];
    for (let i = 0; i< nbQuestions ; i++) {
      // A TextInput type can not have a default value of -1 since a string is expected as an answer. Other types can be -1
      if (qFlow[i].questionType === "TextInput") {
        defaultAnswers.push([questionAnswers.N_A_STRING]);
      } else if (qFlow[i].questionType === "DateInput") {
        defaultAnswers.push([questionAnswers.N_A_STRING]);
      } else {
        defaultAnswers.push([questionAnswers.N_A]);
      }
    }
    return {
      currentQuestion: 0,
      prevQuestions: [],
      answers: defaultAnswers,
      textInput: Array(nbQuestions).fill(''),
      dateInput: Array(nbQuestions).fill(''),
      yesButton: buttonNotSelected,
      noButton: buttonNotSelected,
      checkList: Array(20).fill(questionAnswers.NO),
      acceptAnswer: true,
    };
  });

  React.useEffect(() => {
    window.scrollTo(0, 0); // this will scroll up when displaying the first question
    if ( config.preQuestionHandler ) {
      handlePreQuestion();
    }
  }, [config.preQuestionHandler]);

  function handlePreQuestion() {
    if ( !config.preQuestionHandler) { return; }

    let nextQuestionInfo = callHandlerPreQuestion(config.preQuestionHandler, props.userInfo);

    let nextQuestion = 0;
    // FInd the index of the questionId
    let index = _.findIndex(qFlow, (question) => {
      return (question.questionId === nextQuestionInfo.startingQuestion);
    });
    if (index === -1) {
      // If not found, go to the beginning of the survey
      nextQuestion = 0;
    } else {
      nextQuestion = index;
    }
    let newstate = { ...qstate };

    // Check if we need to change the result of a question from the nextQuestionInfo
    if (nextQuestionInfo.resultQuestionId && !_.isNil(nextQuestionInfo.resultQuestionAnswer)) {
      const questionIndex = _.findIndex(qFlow, q => q.questionId === nextQuestionInfo.resultQuestionId);
      if (questionIndex !== -1) {
        _.set(newstate, `answers.${questionIndex}.0`, nextQuestionInfo.resultQuestionAnswer);
      }
    }

    if (nextQuestionInfo.continue) {
      newstate.currentQuestion = nextQuestion;
      setQstate(newstate);
      window.scrollTo(0, 0);
    } else {
      props.onQuestionsAnswered(newstate.answers, nextQuestion, nextQuestionInfo.skipReviewAndAcknowledged);
    }
  }

  // Handle Yes / No Question Answer
  function onAnswer(answer) {
    let q = { ...qstate };
    if (answer === "no") {
      q.answers[qstate.currentQuestion] = [questionAnswers.NO];
      q.yesButton = buttonNotSelected;
      q.noButton = buttonSelected;
    } else {
      q.answers[qstate.currentQuestion] = [questionAnswers.YES];
      q.yesButton = buttonSelected;
      q.noButton = buttonNotSelected;
    }

    moveToNextQuestion(q);
  }

  // Handle Checklist Question Answer
  function onAnswerCheckList() {
    let q = { ...qstate };

    let nbCheckBox = qFlow[qstate.currentQuestion].questionChecklist.length;

    let checkedAnswers = q.checkList.slice(0, nbCheckBox);
    q.answers[qstate.currentQuestion] = checkedAnswers;

    moveToNextQuestion(q);
  }

  // Handle Weighted Checklist Question Answer
  function onAnswerWeightedCheckList() {
    let q = { ...qstate };

    let nbCheckBox = qFlow[qstate.currentQuestion].questionWeightedChecklist.length;

    let checkedAnswers = q.checkList.slice(0, nbCheckBox);
    q.answers[qstate.currentQuestion] = checkedAnswers;

    moveToNextQuestion(q);
  }

  function onAnswerInformation() {
    let q = { ...qstate };
    moveToNextQuestion(q);
  }

  function onAnswerTextInput() {
    let q = { ...qstate };
    q.answers[q.currentQuestion] = [ q.textInput[q.currentQuestion] ];
    moveToNextQuestion(q);
    setEnableNextQuestion(true);
  }

  function setQuestionInput(newstate) {
    switch (qFlow[newstate.currentQuestion].questionFormat) {
      case "date":
        return moment();
      case "dateInput":
        return moment();
      default:
        return "";
    }
  }

  function moveToNextQuestion(q) {
    let curQuest = q.currentQuestion;
    let resLastQuestion = calculateResultOneQuestion(qFlow, q.answers[curQuest], curQuest, props.userInfo);

    let nextQuestion = curQuest+1;

    // If there's nextQuestion logic in the survey Flow, use it
    if (qFlow[curQuest].nextQuestion) {
      let nextQuestionId = null;

      // if there's a custom handler, call it
      if (qFlow[curQuest].nextQuestion.handler) {
        nextQuestionId = callHandlerNextQuestion(qFlow[curQuest].nextQuestion.handler, q.answers[curQuest], props.userInfo);
      }

      // If it's a yes/no question
      else if (qFlow[curQuest].questionType === "YesNo") {
        nextQuestionId = qFlow[curQuest].nextQuestion.yes;
        if (q.answers[curQuest][0] === questionAnswers.NO) {
          nextQuestionId = qFlow[curQuest].nextQuestion.no;
        }
      }

      // If it's a text input question
      else if (qFlow[curQuest].questionType === "TextInput") {
        if (qFlow[curQuest].nextQuestion.yes) {
          nextQuestionId = qFlow[curQuest].nextQuestion.yes;
        }
      }

      // If it's an information question
      else if (qFlow[curQuest].questionType === "Information") {
        if (qFlow[curQuest].nextQuestion.yes) {
          nextQuestionId = qFlow[curQuest].nextQuestion.yes;
        }
      }

      // If it's a date input question
      else if (qFlow[curQuest].questionType === "DateInput") {
        const userInputDate = moment(q.answers[curQuest][0]);
        const today = moment();
        const diffDays = today.diff(userInputDate, "days");

        if (diffDays >= qFlow[curQuest].thresholdValues.before) {
          nextQuestionId = qFlow[curQuest].nextQuestion.before;
        } else if (diffDays <= qFlow[curQuest].thresholdValues.after) {
          nextQuestionId = qFlow[curQuest].nextQuestion.after;
        } else {
          nextQuestionId = qFlow[curQuest].nextQuestion.between;
        }
      }

      else if (qFlow[curQuest].questionType === "WeightedChecklist") {
        const weightSum = _.reduce(q.answers[curQuest], (sum, currAns, index) => {
          if (currAns === questionAnswers.YES) {
            sum += _.get(qFlow, [curQuest, "questionWeightedChecklist", index, "itemWeight"], 0);
          }
          return sum;
        }, 0);

        if (weightSum >= qFlow[curQuest].thresholdValues.before) {
          nextQuestionId = qFlow[curQuest].nextQuestion.before;
        } else if (weightSum <= qFlow[curQuest].thresholdValues.after) {
          nextQuestionId = qFlow[curQuest].nextQuestion.after;
        } else {
          nextQuestionId = qFlow[curQuest].nextQuestion.between;
        }
      }

      // If it's a checklist question
      else if (qFlow[curQuest].questionType === "CheckList") {
        // Go to "yes:" if there's one or many checkbox selected
        // Go to "no:" if there's no checkbox selected
        nextQuestionId = qFlow[curQuest].nextQuestion.no;
        if (_.findIndex(q.answers[curQuest], (val) => {return val === questionAnswers.YES;}) !== -1) {
          nextQuestionId = qFlow[curQuest].nextQuestion.yes;
        }
      }

      // FInd the index of the questionId
      let index = _.findIndex(qFlow, (question) => {
        return (question.questionId === nextQuestionId);
      });

      if (index === -1) {
        // If not found, go to the end of the survey
        nextQuestion = nbQuestions;
      } else {
        nextQuestion = index;
      }
    }

    // Check if it was the last question
    if ( (nextQuestion < nbQuestions) &&
         !(qFlow[curQuest].exit && resLastQuestion >= screenResults.FAIL))  {
      // Move to the next question, with a 500ms delay, for the user to see their click
      q.acceptAnswer = false; // for now don't accept any click on yes/no buttons until we switch to the next question
      setTimeout(function () {
        let newstate = { ...qstate };
        newstate.prevQuestions.push(newstate.currentQuestion);  // save previous question, to be able to go backward
        newstate.currentQuestion = nextQuestion;
        newstate.acceptAnswer = true;
        newstate.yesButton = buttonNotSelected;
        newstate.noButton = buttonNotSelected;
        newstate.checkList = Array(20).fill(questionAnswers.NO);
        newstate.textInput[newstate.currentQuestion] = setQuestionInput(newstate);
        newstate.dateInput[newstate.currentQuestion] = setQuestionInput(newstate);
        setQstate(newstate);
        window.scrollTo(0, 0);    // Forrc scrolling up
      }, 500);

    } else {
      setDisableButton(true);
      // notify the home view
      props.onQuestionsAnswered(q.answers, curQuest);
    }

    // Refresh the UI
    setQstate(q);
  }

  // Use to get the number of dots in the mobile stepper control
  const getNbSteps = () => {
    let count = 0;

    for (let i = 0; i < qFlow.length; i++) {
      // Ignore the subQuestion in the count
      if (!qFlow[i].subQuestion) {
        count++;
      }
    }
    return count;
  };

  // Use to get the steops of the current question for the mobile stepper control
  const getActiveStep = () => {
    let position = 0;

    for (let i = 0; i < qFlow.length; i++) {
      if (i === qstate.currentQuestion ) {
        // Found it, if the question is a sub question, use the precedent position
        if (qFlow[i].subQuestion) {
          position--;
        }
        break;
      }

      // Ignore the subQuestion in the count
      if (!qFlow[i].subQuestion) {
        position++;
      }
    }
    return position;
  };

  function onBack () {
    // Check if it the first question
    if ((qstate.currentQuestion === 0) || (qstate.prevQuestions.length === 0)) {
      props.onBackScreen("questionPage");
    } else {
      let newState = { ...qstate };

      let prevQuestion = newState.prevQuestions.pop();

      // Overwrite current answer
      if (newState.answers[newState.currentQuestion]) {
        newState.answers[newState.currentQuestion] = [questionAnswers.N_A];
      }
      const currentQuestionType = qFlow[newState.currentQuestion].questionType;
      if (currentQuestionType === "DateInput") {
        newState.dateInput[newState.currentQuestion] = "";
        newState.answers[newState.currentQuestion] = [questionAnswers.N_A_STRING];
      }
      if (currentQuestionType === "TextInput") {
        newState.textInput[newState.currentQuestion] = "";
        newState.answers[newState.currentQuestion] = [questionAnswers.N_A_STRING];
      }

      newState.currentQuestion = prevQuestion;
      setQstate(newState);
    }
  }

  const handleCheckItem = (event) => {
    let newState = { ...qstate };

    let index = parseInt(event.target.name);

    if (event.target.checked) {
      newState.checkList[index] = questionAnswers.YES;
    } else {
      newState.checkList[index] = questionAnswers.NO;
    }
    setQstate(newState);
  };

  const handleShowMoreInfo = () => {
    setOpenMoreInfo(true);
  };

  const handleHideMoreInfo = () => {
    setOpenMoreInfo(false);
  };

  return (
    <Container className={classes.topContainer} >
      <Drawer anchor={"bottom"} open={openMoreInfo} onClose={handleHideMoreInfo} classes={{paper: classes.paper}}>
        <Container className={classes.topContainer} >
          <div className={classes.moreInfoText} style={{marginTop:"10px"}}>
            <Grid container direction="row" justify="flex-end">
              <IconButton onClick={() => {handleHideMoreInfo();}}>
                <Close></Close>
              </IconButton>
            </Grid>
            <Grid>
              <Typography variant="h6" color="primary">
                {ReactHtmlParser(qFlow[qstate.currentQuestion].moreInfoText)}
              </Typography>
            </Grid>
          </div>
        </Container>
      </Drawer>
      <div style={{marginTop:"20px", marginBottom:"10px", textAlign:"center"}} >
        <ChevronLeft color="primary"
          className={classes.backIcon}
          onClick={() => { onBack(); }}>
        </ChevronLeft>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box width={84}></Box>
          </Grid>
          <Grid item xs>
            <Typography variant='h5' color="primary" className={classes.headerText}>
              {qFlow[qstate.currentQuestion].questionTitle}
            </Typography>
          </Grid>
          <Grid item>
            <Box width={84}>
              <FontSizeSelector
                variant="menu"
                display={displayFontSizeSelector}
              />
            </Box>
          </Grid>
        </Grid>

      </div>

      <Divider></Divider>

      <div style={{flexGrow:"1"}}>
        <Typography variant="h6" className={classes.questionText}>
          {ReactHtmlParser(qFlow[qstate.currentQuestion].questionText)}
          {!_.isNil(qFlow[qstate.currentQuestion].moreInfoText) &&
            <Info color="primary" style={{marginLeft: "10px", cursor: "pointer", verticalAlign: "top", width:"2rem", height:"2rem" }} onClick={() => {handleShowMoreInfo();}}/>
          }
        </Typography>
        <div style={{marginTop:"20px"}}>
          {ReactHtmlParser(qFlow[qstate.currentQuestion].subtext)}
        </div>

        { qFlow[qstate.currentQuestion].questionType === "CheckList"
          &&
          <FormControl component="fieldset" className={classes.formControl} style={{float:"left", marginTop:"20px"}}>
            <FormGroup>
              {qFlow[qstate.currentQuestion].questionChecklist.map((item,index) => (
                <React.Fragment key={index}>
                  <FormControlLabel style={{textAlign:"left", marginTop:"5px"}}
                    control={<Checkbox color="primary"
                      checked={qstate.checkList[index] === questionAnswers.YES}
                      onChange={handleCheckItem} name={index.toString()} />}
                    label={
                      <Typography variant='body1' className={classes.checkListText}>
                        {item}
                      </Typography>
                    }
                  />
                </React.Fragment>
              ))}
            </FormGroup>
          </FormControl>
        }

        { qFlow[qstate.currentQuestion].questionType === "WeightedChecklist"
          &&
          <FormControl component="fieldset" className={classes.formControl} style={{float:"left", marginTop:"20px"}}>
            <FormGroup>
              {qFlow[qstate.currentQuestion].questionWeightedChecklist.map((item,index) => (
                <React.Fragment key={index}>
                  <FormControlLabel style={{textAlign:"left", marginTop:"5px"}}
                    control={<Checkbox color="primary"
                      checked={qstate.checkList[index] === questionAnswers.YES}
                      onChange={handleCheckItem} name={index.toString()} />}
                    label={
                      <Typography variant='body1' className={classes.checkListText}>
                        {item.itemText}
                      </Typography>
                    }
                  />
                </React.Fragment>
              ))}
            </FormGroup>
          </FormControl>
        }

        {
          (qFlow[qstate.currentQuestion].questionType === "TextInput"  ||
          qFlow[qstate.currentQuestion].questionType === "DateInput") &&
          <TextInputFormat
            className={classes.textInput}
            qFlow={qFlow}
            qstate={qstate}
            setQstate={setQstate}
            enableNextQuestion={enableNextQuestion}
            updateEnableNextQuestion={setEnableNextQuestion}
          />
        }
      </div>

      <div className={classes.bottomCentered}>

        { qFlow[qstate.currentQuestion].questionType === "YesNo" &&
          <Grid container className={classes.yesNoContainer} >
            <Button variant="outlined" color="primary"
              disabled={disableButton}
              onClick={() => {
                if (qstate.acceptAnswer) {
                  onAnswer("yes");
                }
              }}
              style={qstate.yesButton}
              className={classes.yesNoButton}
              data-cy="questionYesButton"
            >
              { qFlow[qstate.currentQuestion].questionYesString || strings.yes }
            </Button>
            <div style={{display:"inline-block", width:"50px"}} />
            <Button variant="outlined" color="primary"
              disabled={disableButton}
              onClick={() => {
                if (qstate.acceptAnswer) {
                  onAnswer("no");
                }
              }}
              style={qstate.noButton}
              className={classes.yesNoButton}
              data-cy="questionNoButton"
            >
              { qFlow[qstate.currentQuestion].questionNoString || strings.no }
            </Button>
          </Grid>
        }

        { qFlow[qstate.currentQuestion].questionType === "CheckList" &&
          <Button variant="contained" color="primary" className={classes.nextButton}
            disabled={disableButton}
            onClick={onAnswerCheckList} >
            {strings.next}
          </Button>
        }

        { qFlow[qstate.currentQuestion].questionType === "WeightedChecklist" &&
          <Button variant="contained" color="primary" className={classes.nextButton}
            disabled={disableButton}
            onClick={onAnswerWeightedCheckList} >
            {strings.next}
          </Button>
        }

        {
          qFlow[qstate.currentQuestion].questionType === "Information" &&
          <Button variant="contained" color="primary" className={classes.nextButton}
            disabled={disableButton}
            onClick={onAnswerInformation} >
            {strings.next}
          </Button>
        }

        {
          (qFlow[qstate.currentQuestion].questionType === "TextInput" ||
          qFlow[qstate.currentQuestion].questionType === "DateInput") &&
          <Button
            variant="contained"
            color="primary"
            className={classes.nextButton}
            onClick={onAnswerTextInput}
            disabled={!enableNextQuestion}
          >
            {strings.next}
          </Button>
        }

        <MobileStepper
          className={classes.mobileStepper}
          variant="dots"
          steps={getNbSteps()}
          position="static"
          activeStep={getActiveStep()}
          classes={{
            dot: classes.mobileStepperDots,
          }} >
        </MobileStepper>

      </div>
    </Container>
  );
}
