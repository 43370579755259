import { PrivacyPolicy_en, PrivacyPolicy_fr } from './PrivacyPolicy';
import LocalizedStrings from 'react-localization';
import _ from 'lodash';

export let strings = new LocalizedStrings({
  en: {
    aboutYourself: "Enter Your Information",
    firstName: "First Name",
    lastName: "Last Name",
    name: "Name",
    email: "Email",
    emailResults: "you will be emailed your screening results",
    phone: "Phone",
    unitShortForm: "Dept",
    locationSelectionDefaultTitle: "Which Location are you visiting?",
    location: "Location",
    locations: "Locations",
    locationShortForm: "Location",
    otherLocation: "Other",
    otherLocationLabel: "Other Location",
    date: "Date",
    next: "Next",
    yes: "Yes",
    no: "No",
    cancel: "Cancel",
    invalidDateMessage: "Invalid Date Format",
    maxDateMessage: "Date should not be after maximal date",
    minDateMessage: "Date should not be before minimal date",
    symptomReview: "Review",
    submit: "Submit",
    declaration: "Declaration",
    declarationConfirmation: "I declare that the information shared is true to the best of my knowledge.",
    done: "Done",
    continue: "Continue",
    ok: "Ok",
    pass: "PASS",
    passRestriction: "CAUTION",
    fail: "DO NOT ENTER",
    temperatureCheck: "Please proceed to the temperature check area to get your temperature checked prior to entry.",
    temperatureEnterTitle: "Please proceed to the temperature check area.",
    enterTemperature: "Please enter the temperature reading.",
    emailNote: "Your information has been successfully submitted. An email with the results will be sent to the email provided from the sender <orgEmail>",
    doNotReply: "This is an automated message. Please do not reply.",
    welcomeTitle: "Screening Tool for COVID-19",
    welcomeDisclaimer: "Personal information is being collected by ${customerName} only for COVID-19 screening purposes.",
    welcomeDisclaimerSelfServe: "Personal information is being collected in HealthQ only for COVID-19 screening purposes.",
    welcomeStart: "Start",
    submittingSurvey: "Submitting survey...",
    internalErrorMsg: "An error occurred. Please refresh your web browser and try again. If the problem persists then please contact your administrator.",
    submitErrorMsg: "An error occurred. You can retry submitting the survey again or refresh web browser then try again. If the problem persists then please contact your administrator.",
    consentTitle: 'Consent',
    notRegisteredUserDenied: "Only registered users are permitted to enter the facility.<br><br>Please ensure you are signing in with the name / email used to register to continue.<br><br>If you have not been registered, please contact the administrator to be registered.",
    noLocationsInRange: "No Locations In Range",
    urgentPhone: 'Phone number where we can reach you immediately',
    loading: "Loading...",
    offline: "No Internet connection! Please verify your Internet connection settings.",
    emailValidationTitle: "Enter Your Code",
    emailValidationInfo: "We have sent you an email message with a validation code. Enter it below to complete check-in",
    validationCode: "Validation Code",
    noCode: "Didn't get a code?",
    resendCode: "Resend code now",
    validationIssue: "Validation Issue",
    validationIssueInfo: "The code entered was not recognized",
    checkCode: "Check the code that was sent to your email.",
    tryAgain: "Try Again",
    newCode: "Alternatively, you can try again, with a new code.",
    getNewCode: "Get a New Code",
    sentCodeTo: "We sent a code to ${email}, if this is not your email, please check your information and check-in again.",
    checkDetails: "Check Information",
    privacyPolicy: "Privacy policy",
    privacyPolicyTitle:"Privacy Policy",
    healthDefinesUs: `
      <div style="text-align:left">
        <p>HealthQ is a COVID-19 attestation service to help minimize the spread of COVID-19 by helping users to identify if they are potentially experiencing COVID-19 symptoms, or have been exposed recently.</p>
        <br/>
        <p>HealthQ was developed by Macadamian Technologies, for more information please visit: <a href="https://www.macadamian.com/qsuite/healthq-covid-19-screening-app/">info.macadamian.com</a> to learn more.</p>
      </div>`,
    healthQLogoUrl: "https://healthq.joinhealthq.com/healthQ_logo.png",
    healthDefinesUsLogo: "/mac-HealthDefinesUs-en.svg",
    privacyPolicyText: PrivacyPolicy_en,
    covidTestingAllowSms: "I would like to receive updates about my COVID testing.",
    covidTestingAllowSmsNetflix: "Allow text notifications when necessary from the production HSS",
    userInfoConsent: "By Clicking \"Next\", you voluntarily consent to completing a questionnaire covering your current health symptoms and recent contacts.",
    loadingConfiguration: 'Loading configuration...',
    repeatSurveyPeriodMinutes: '<locked period> minutes',
    repeatSurveyPeriodHoursSingular: '<locked period> hour',
    repeatSurveyPeriodHoursPlural: '<locked period> hours',
    repeatSurveyMessage: `Please do not proceed into the facility.`,
    repeatSurveyMessageNetflix: `Please do not proceed to set. Please call the HSS officer on set at 613-555-1395 for further instructions.`,
    repeatSurveyDeniedText: `You can only complete one survey response every <locked period>, please contact the facility COVID assessment team if you think there was an error in completing your attestation, and to discuss options for entry.`,
    repeatSurveyDeniedTextNetflix: `You can only complete one survey response every <locked period>, please contact the HSC if you think there was an error in completing your attestation, and to discuss options for entry.`,
    timeoutWarningTitle: "Are you still there?",
    timeoutWarningText: "The screener will reset in %1 seconds, unless you need more time.",
    timeoutWarningExitOption: "Exit screener",
    timemoutWarningContinueOption: "I need more time",
    outbreak: {
      title: 'Notice of Outbreak',
      message1: 'This unit is currently not accepting visitors.',
      message2: 'Please come back another time.',
      resultPageText: '', // TODO
    },
    capacity: {
      title: 'Capacity Notice',
      message1: 'The <unit name> may be at the visitation capacity limits at this time.',
      message2: 'When you get up to the unit, please check in at the unit station desk to see if it’s OK to enter for your visit.',
      resultPageText: '', // TODO
    },
    editFontSizeLabel: 'Change font size',
  },
  fr: {
    aboutYourself: "Entrez vos informations",
    firstName: "Prénom",
    lastName: "Nom de famille",
    name: "Nom",
    email: "Courriel",
    emailResults: "vous recevrez votre résultat par courriel",
    phone: "Téléphone",
    unitShortForm: "Dept.",
    locationSelectionDefaultTitle: "Quel établissement visitez-vous ?",
    location: "Établissement",
    locations: "Établissements",
    locationShortForm: "Établ.",
    otherLocation: "Autre",
    otherLocationLabel: "Autre établissement",
    date: "Date",
    next: "Suivant",
    yes: "Oui",
    no: "Non",
    cancel: "Annuler",
    invalidDateMessage: "Format de date non valide",
    maxDateMessage: "La date ne doit pas être postérieure à la date maximale",
    minDateMessage: "La date ne doit pas être antérieure à la date minimale",
    symptomReview: "Révision",
    submit: "Soumettre",
    declaration: "Déclaration",
    declarationConfirmation: "Je déclare que les renseignements partagés sont, à ma connaissance, exacts et complets.",
    done: "Terminer",
    continue: "Continuer",
    ok: "Ok",
    pass: "SUCCÈS",
    passRestriction: "ATTENTION",
    fail: "ENTRÉE INTERDITE",
    temperatureCheck: "Veuillez vous présentez au poste de contrôle de la température avant d'entrer dans l'édifice.",
    temperatureEnterTitle: "Veuillez vous présentez au poste de contrôle de la température.",
    enterTemperature: "Entrez votre température.",
    emailNote: "Votre information a été transmis avec succès. Un courriel contenant les résultats en provenance de <orgEmail> vous sera envoyé à l’adresse courriel que vous avez fourni.",
    doNotReply: "Ceci est un message auto-généré, s.v.p. ne pas répondre.",
    welcomeTitle: "Questionnaire de dépistage de la COVID-19",
    welcomeDisclaimer: "Les renseignements personnels sont recueillis par ${customerName} seulement pour les besoins de dépistage de la COVID-19.",
    welcomeDisclaimerSelfServe: "Les renseignements personnels sont recueillis dans HealthQ seulement pour les besoins de dépistage de la COVID-19.",
    welcomeStart: "Commencer",
    submittingSurvey: "Soumission de l'enquête...",
    internalErrorMsg: "Une erreur interne s'est produite. Veuillez actualiser votre navigateur Web et réessayer. Si le problème persiste, veuillez contacter votre administrateur.",
    submitErrorMsg: "Une erreur interne s'est produite. Vous pouvez réessayer de soumettre vos réponses à nouveau ou rafraîchir le navigateur web puis réessayer. Si le problème persiste, veuillez contacter votre administrateur.",
    consentTitle: 'Consentement',
    notRegisteredUserDenied: "Seuls les utilisateurs enregistrés sont autorisés à entrer dans l'établissement.<br><br>Veuillez vous assurer que vous vous connectez avec le nom / email utilisé pour vous enregistrer afin de continuer.<br><br>Si vous n'avez pas été enregistré, veuillez contacter l'administrateur pour vous enregistrer.",
    noLocationsInRange: "Aucun établissement près",
    urgentPhone: 'Numéro de téléphone auquel on peut vous joindre immédiatement',
    loading: "Chargement en cours...",
    offline: "Pas de connexion à l'Internet. Veuillez vérifier les paramètres de votre connexion Internet.",
    emailValidationTitle: "Entrez votre code",
    emailValidationInfo: "Nous vous avons envoyé un message électronique avec un code de validation. Saisissez-le ci-dessous pour compléter l'enregistrement",
    validationCode: "Code de validation",
    noCode: "Vous n'avez pas reçu de code ?",
    resendCode: "Renvoyer le code maintenant",
    validationIssue: "Erreur de validation",
    validationIssueInfo: "Le code saisi n'a pas été reconnu",
    checkCode: "Vérifiez le code qui a été envoyé à votre adresse électronique.",
    tryAgain: "Réessayer",
    newCode: "Vous pouvez aussi essayer à nouveau, avec un nouveau code.",
    getNewCode: "Obtenir un nouveau code",
    sentCodeTo: "Nous avons envoyé un code à ${email}, si ce n'est pas votre adresse électronique, veuillez vérifier vos informations et vous enregistrer à nouveau.",
    checkDetails: "Vérifier mes informations",
    privacyPolicy: "Politique de confidentialité",
    privacyPolicyTitle:"Politique sur la protection des renseignements personnels",
    healthDefinesUs:
      `<div style="text-align:left">
        <p>HealthQ est un service d'attestation de la présence du COVID-19 qui vise à minimiser la propagation du COVID-19 en aidant les utilisateurs à identifier s'ils présentent potentiellement des symptômes du COVID-19 ou s'ils ont été exposés récemment.</p>
        <br/>
        <p>HealthQ a été développé par Macadamian Technologies, pour plus d'informations, veuillez visiter le site: <a href="https://www.macadamian.com/qsuite/healthq-covid-19-screening-app/">info.macadamian.com</a> pour en savoir plus.</p>
      </div>`,
    healthQLogoUrl: "https://healthq.joinhealthq.com/healthQ_logo.png",
    healthDefinesUsLogo: "/mac-HealthDefinesUs-fr.svg",
    privacyPolicyText: PrivacyPolicy_fr,
    covidTestingAllowSms: "J'aimerais recevoir des mises à jour sur mon test COVID.",
    covidTestingAllowSmsNetflix: "Autoriser les notifications par SMS si nécessaire à partir du HSS de la production.",
    userInfoConsent: "En cliquant sur \"Suivant\", vous consentez volontairement à remplir un questionnaire portant sur vos symptômes de santé actuels et vos contacts récents.",
    loadingConfiguration: 'Chargement de la configuration...',
    repeatSurveyPeriodMinutes: '<locked period> minutes',
    repeatSurveyPeriodHoursSingular: '<locked period> heure',
    repeatSurveyPeriodHoursPlural: '<locked period> heures',
    repeatSurveyMessage: `Veuillez ne pas entrer dans l'établissement.`,
    repeatSurveyMessageNetflix: `Veuillez ne pas vous rendre sur le plateau. Veuillez appeler l'agent HSS sur le plateau au 613-555-1395 pour de plus amples instructions.`,
    repeatSurveyDeniedText: `Vous ne pouvez remplir qu'une seule réponse à l'enquête par <locked period>. Veuillez contacter l'équipe d'évaluation COVID de l'établissement si vous pensez qu'une erreur s'est glissée dans votre attestation, et pour discuter des options de saisie.`,
    repeatSurveyDeniedTextNetflix: `Vous ne pouvez remplir qu'une seule réponse à l'enquête toutes les <locked period>. Veuillez contacter le HSC si vous pensez qu'il y a eu une erreur dans le remplissage de votre attestation, et pour discuter des options d'entrée.`,
    timeoutWarningTitle: "Vous êtes toujours là?",
    timeoutWarningText: "Le questionnaire de dépistage se réinitialisera en %1 secondes, sauf si vous demandez un délai supplémentaire.",
    timeoutWarningExitOption: "Quitter",
    timemoutWarningContinueOption: "J'ai besoin de temps supplémentaire",
    outbreak: {
      title: "Avis d'éclosion",
      message1: "Cette unité n'accepte pas actuellement les visiteurs.",
      message2: 'Revenez une autre fois, s\'il vous plaît.',
      resultPageText: '', // TODO
    },
    capacity: {
      title: 'Avis de capacité',
      message1: "L'unité <unit name> peut être à la limite de la capacité de visite en ce moment.",
      message2: "Lorsque vous arrivez à l'unité, veuillez vous présenter au bureau du poste de l'unité pour savoir si vous pouvez entrer pour votre visite.",
      resultPageText: '', // TODO
    },
    editFontSizeLabel: 'Modifier la taille de la police',
  },
  es: {
    aboutYourself: "Ingrese su información",
    firstName: "Nombre de pila",
    lastName: "Apellido",
    name: "Nombre",
    email: "Correo electrónico",
    emailResults: "se le enviarán los resultados por correo electrónico",
    phone: "teléfono",
    // TODO not sure about this one (below)
    unitShortForm: "Dept",
    locationSelectionDefaultTitle: "¿Qué lugar va a visitar?",
    location: "Ubicación",
    locations: "Ubicaciónes",
    locationShortForm: "Ubicación",
    otherLocation: "Otra",
    otherLocationLabel: "Otra ubicación",
    date: "Fecha",
    next: "Siguiente",
    yes: "Sí",
    no: "No",
    cancel: "Cancelar",
    invalidDateMessage: "Formato de fecha no válido",
    maxDateMessage: "La fecha no debe ser posterior a la fecha máxima",
    minDateMessage: "La fecha no debe ser anterior a la fecha mínima",
    symptomReview: "Revisión",
    submit: "Enviar",
    declaration: "Declaración",
    // TODO not sure about this one (below)
    declarationConfirmation: "Declaro que la información compartida es verdadera según mi leal saber y entender.",
    done: "Hecho",
    continue: "Continuar",
    ok: "Ok",
    pass: "ÉXITO",
    passRestriction: "PRECAUCIÓN",
    fail: "NO ENTRAR",
    temperatureCheck: "Por favor, diríjase al área de control de temperatura para comprobar su temperatura antes de entrar.",
    temperatureEnterTitle: "Por favor, diríjase al área de comprobación de la temperatura.",
    enterTemperature: "Por favor, introduzca la lectura de la temperatura.",
    emailNote: "Su información ha sido enviada con éxito. Se enviará un correo electrónico con los resultados al correo electrónico proporcionado por <orgEmail>",
    doNotReply: "Este es un mensaje automático. Por favor, no responda.",
    welcomeTitle: "Herramienta de detección para COVID-19",
    welcomeDisclaimer: "${customerName} recopila información personal para fines de detección de COVID-19.",
    welcomeDisclaimerSelfServe: "HealthQ recopila información personal para fines de detección de COVID-19.",
    welcomeStart: "Comenzar",
    // TODO Spanish translations
    submittingSurvey: "Presentación de la encuesta...",
    internalErrorMsg: "Se ha producido un error interno. Por favor, actualiza tu navegador e inténtalo de nuevo. Si el problema persiste, póngase en contacto con su administrador.",
    submitErrorMsg: "Se produjo un error. Puedes volver a intentar enviar la encuesta o actualizar el navegador web e intentarlo de nuevo. Si el problema persiste, ponte en contacto con tu administrador.",
    consentTitle: 'Consentimiento',
    notRegisteredUserDenied: "Sólo los usuarios registrados pueden entrar en la instalación.<br><br>Por favor, asegúrese de iniciar la sesión con el nombre / correo electrónico utilizado para registrarse con el fin de continuar.<br><br>Si no se ha registrado, póngase en contacto con el administrador para hacerlo.",
    noLocationsInRange: "No Ubicación",
    urgentPhone: 'Número de teléfono accesible en cualquier momento.',
    loading: "Cargando...",
    offline: "¡No hay conexión a Internet! Por favor, verifique la configuración de su conexión a Internet.",
    emailValidationTitle: "Introduzca su código",
    emailValidationInfo: "Le hemos enviado un mensaje de correo electrónico con un código de validación. Introdúzcalo a continuación para completar el registro",
    validationCode: "Código de validación",
    noCode: "¿No has recibido un código?",
    resendCode: "Reenviar el código ahora",
    validationIssue: "Problema de validación",
    validationIssueInfo: "El código introducido no ha sido reconocido",
    checkCode: "Comprueba el código que se ha enviado a tu correo electrónico.",
    tryAgain: "Inténtalo de nuevo",
    newCode: "Como alternativa, puedes volver a intentarlo con un nuevo código.",
    getNewCode: "Obtener un nuevo código",
    sentCodeTo: "Hemos enviado un código a ${email}, si este no es tu correo electrónico, por favor comprueba tus datos y vuelve a registrarte.",
    checkDetails: "Comprobar la información",
    covidTestingAllowSms: "Me gustaría recibir información actualizada sobre mis pruebas de COVID.",
    covidTestingAllowSmsNetflix: "Permitir las notificaciones de texto cuando sea necesario desde el HSS de producción",
    userInfoConsent: "Al hacer clic en \"Siguiente\", acepta voluntariamente llenar un cuestionario sobre sus síntomas de salud actuales y sus contactos recientes.",
    loadingConfiguration: 'Cargando la configuración...',
    repeatSurveyPeriodMinutes : '<locked period> minutos',
    repeatSurveyPeriodHoursSingular: '<locked period> hora',
    repeatSurveyPeriodHoursPlural: '<locked period> horas',
    repeatSurveyMessage: `Por favor, no entre en las instalaciones.`,
    repeatSurveyMessageNetflix: `Por favor, no proceda al plató. Por favor, llame al oficial de HSS en el set al 613-555-1395 para obtener más instrucciones.`,
    repeatSurveyDeniedText: `Sólo puede completar una respuesta a la encuesta cada <locked period>, por favor, póngase en contacto con el equipo de evaluación COVID de la instalación si cree que hubo un error al completar su atestado, y para discutir las opciones de entrada.`,
    repeatSurveyDeniedTextNetflix: `Sólo puede completar una respuesta a la encuesta cada <locked period>, por favor, póngase en contacto con el HSC si cree que hubo un error al completar su atestado, y para discutir las opciones de entrada.`,
    timeoutWarningTitle: "¿Sigues aquí?",
    timeoutWarningText: "El cuestionario se reiniciará en %1 segundos, a menos que necesite más tiempo.",
    timeoutWarningExitOption: "Salir",
    timemoutWarningContinueOption: "Necesito más tiempo",
    outbreak: {
      title: 'Aviso de brote',
      message1: 'Esta unidad no acepta actualmente visitas.',
      message2: 'Por favor, vuelva en otro momento.',
      resultPageText: '', // TODO
    },
    editFontSizeLabel: 'Cambiar el tamaño de la letra',
  }
});

const LANGUAGE_MAP = {
  "en": "English",
  "fr": "Français",
  "es": "Español",
  "de": "Deutsch",
  "pt": "Português",
  "yue": "粵語",
};

export const getLanguageFromAbbreviation = (lang) => {
  return _.get(LANGUAGE_MAP, _.toLower(lang), "");
};
