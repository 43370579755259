import React, { useState, useCallback } from 'react';
import { Button, CircularProgress, Container, Grid, IconButton, makeStyles, Typography } from '@material-ui/core';
import OtpInput from 'react-otp-input';
import { ChevronLeft } from '@material-ui/icons';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import WrongCodeDialog from '../components/WrongCodeDialog';
import { strings } from "../localizedStrings";
import { createHiveBee } from "../services/HiveService";
import {
  ConnectionService,
  utils as ConnectionServiceUtils,
} from "hive-client-utils";
import { useCookies } from "react-cookie";

const { waitForInvocationReaction } = ConnectionServiceUtils;

const useStyles = makeStyles((theme) => ({
  pageContainer: {
    display:"flex",
    flexDirection:"column",
    minHeight:"100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  titleContainer: {
    marginTop:"20px",
    marginBottom:"10px",
    textAlign:"center",
  },
  headerText: {
    fontWeight: "700",
    display: "inline-block",
    width: "85%",
  },
  backIcon: {
    width:"32px",
    height:"32px",
  },
  backButton: {
    position: "absolute",
    display: "inline-block",
    left: '8px',
    padding: 0,
  },
  text: {
    color: "black",
    opacity: "0.6",
    fontStyle: "italic",
    marginBottom: "8px",
  },
  link: {
    color: "#1B7FD1",
    fontWeight: "700",
    textDecoration: "underline",
    fontStyle: "italic",
    textTransform: "none",
  },
  linkRoot: {
    padding: 0,
  },
  sendEmailContainer: {
    padding: "0 16px",
    marginTop: "20px",
    textAlign: "center",
  },
  verificationCodeGrid: {
    flex: 1,
  },
  verificationCode: {
    fontWeight: "bold",
    fontStyle: "italic",
    lineHeight: "1.4"
  },
  inputFocus: {
    borderColor: theme.palette.primary.main,
    borderStyle: "solid",
  },
  optInputDiv: {
    marginTop: "12px",
    marginBottom: "32px",
    justifyContent: 'center',
    width: '100%'
  },
  optInputs: {
    fontSize: "2rem",
    width: "45px !important",
    borderRadius: '13px',
    height: '70px',
    outline: 'none',
  },
  optInputsSmall: {
    fontSize: "1.5rem",
    width: "30px !important",
    borderRadius: "8px",
    height:"45px",
    outline: "none",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "center",
    alignItems: "flex-start",
    height: "80px",
    width: "100%",
    marginBottom: "20px",
  },
  nextButton: {
    width: "25vw",
    minHeight: "5vh",
    maxWidth: "300px",
    textTransform: "none",
    color: "white",

    "&.Mui-disabled" : {
      color: "#939398",
      backgroundColor: "#E6E6E6",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#939398",
    },
  }
}));


export default function EmailValidationPage(props) {

  const [openError, setOpenError] = useState(false);
  const [code, setCode] = useState("");
  const [cookies, , removeCookies] = useCookies(["language", "userInfo", "screenerUserId"]);
  const [loading, setLoading] = useState(false);

  const classes = useStyles();
  const matches = useMediaQuery('(max-width:321px)');

  const handleChangeCode = (event) => {
    setCode(event);
  };

  const isNextButtonDisabled = useCallback(() => {
    return code.length < 6;
  }, [code]);

  const handleGoBack = useCallback(() => {
    props.onBackScreen("emailValidationPage");
  }, [props.onBackScreen]);

  const closeErrorDialog = useCallback(() => {
    setOpenError(false);
  }, []);

  const handleNext = async () => {
    setLoading(true);
    let bee;
    try {
      [bee] = await createHiveBee(process.env.REACT_APP_BEE_NAME, process.env.REACT_APP_BEE_PASS);
      let result = await bee.actions.invoke('screening_client.validateCode', props.userEmail, code, cookies.screenerUserId)
        .then(waitForInvocationReaction(bee, r => r.details.message));

      setTimeout(() => {
        setLoading(false);
        if (result) {
          removeCookies("screenerUserId");
          props.onValidated();
        } else {
          setOpenError(true);
        }
      }, 1000);
      ConnectionService.releaseBee(bee);
    } catch (e) {
      console.error(e);
      ConnectionService.releaseBee(bee);
    }

  };

  const handleResendCode = async () => {
    let bee;
    try {
      [bee] = await createHiveBee(process.env.REACT_APP_BEE_NAME, process.env.REACT_APP_BEE_PASS);
      await bee.actions.invoke('screening_client.resendEmailValidationCode', cookies.userInfo.firstName, cookies.userInfo.email, cookies.language, cookies.screenerUserId)
        .then(waitForInvocationReaction(bee, r => r.details.message));
      ConnectionService.releaseBee(bee);
    } catch (e) {
      console.error(e);
      ConnectionService.releaseBee(bee);
    }
  };

  return (
    <>
      <WrongCodeDialog
        userEmail={props.userEmail}
        open={openError}
        handleCheckInfo={handleGoBack}
        handleResendCode={handleResendCode}
        handleCloseErrorDialog={closeErrorDialog}
      />

      <Container className={classes.pageContainer}>
        <Container className={classes.titleContainer} >

          <IconButton  className={classes.backButton} onClick={handleGoBack}>
            <ChevronLeft className={classes.backIcon} color="primary" />
          </IconButton>

          <Typography className={classes.headerText} variant="h5" color="primary" >
            {strings.emailValidationTitle}
          </Typography>

        </Container>

        <Container className={classes.sendEmailContainer}>
          <Typography variant="body1" className={classes.text}>
            {strings.emailValidationInfo}
          </Typography>
        </Container>

        <Grid className={classes.verificationCodeGrid} container direction="column" justify="center" alignItems="center" spacing={1} >
          <Typography color="primary" className={classes.verificationCode} >
            {strings.validationCode}
          </Typography>

          <OtpInput
            style={{ margin: "0 auto" }}
            shouldAutoFocus={true}
            focusStyle={classes.inputFocus}
            isInputNum={true}
            containerStyle={classes.optInputDiv}
            inputStyle={matches? classes.optInputsSmall : classes.optInputs}
            numInputs={6}
            value={code}
            onChange={(e) => handleChangeCode(e)}
            separator={matches ? <span>-</span> : <span style={{ width: '8px' }}></span>}
          />

          <Typography variant="body1" className={classes.text}>
            {strings.noCode}
          </Typography>

          <Button
            variant="text"
            classes={{
              label: classes.link,
              root: classes.linkRoot,
            }}
            onClick={handleResendCode} >
            {strings.resendCode}
          </Button>

        </Grid>

        <Container className={classes.buttonContainer} >
          <Button
            variant="contained"
            color="primary"
            onClick={handleNext}
            disabled={isNextButtonDisabled()}
            className={classes.nextButton} >
            {
              loading ?
                <CircularProgress size="24px" style={{ color: "white" }} />
                :
                strings.next
            }
          </Button>
        </Container>
      </Container>
    </>
  );
}