import { MuiButton } from "./button";
import { MuiToggleButton } from "./toggleButton";
import { MuiTypography } from "./typography";

export const mainTheme = {
  overrides: {
    MuiButton,
    MuiToggleButton,
    MuiTypography,
  },
};