import { LocationsAndUnitsService } from 'healthq-shared-library';
import { waitForInvocationReaction } from 'hive-client-utils/src/utils';
import _ from 'lodash';
import { createHiveBee } from './HiveService';

const checkCanProceed = (
  bee,
  language,
  phone,
  email,
  onRepeatSurvey = _.identity
) =>
  bee.actions
    .invoke('screening.canProceed', language, phone, email.trim())
    .then(waitForInvocationReaction(bee, (r) => r.details.message))
    .catch((error) => {
      const disablePeriod = _.get(error, 'message.disablePeriod');
      const lastSurveyTime = _.get(error, 'message.lastSurveyTime');
      if (disablePeriod && lastSurveyTime) {
        onRepeatSurvey(disablePeriod, lastSurveyTime);
      }

      // Rethrowing will bypass the subsequent steps
      throw error;
    });

const checkLocationAndUnitForOutbreak = (
  bee,
  type,
  arrivalTime,
  location,
  unit,
  onError = _.identity
) =>
  LocationsAndUnitsService.validateUserLocationAndUnit(
    { bee },
    type,
    arrivalTime,
    location,
    unit
  ).catch((error) => {
    const reason = _.get(error, 'message');
    onError(reason);

    // Rethrowing will bypass the subsequent steps
    throw error;
  });

async function checkUserInfo(
  language,
  properties = {},
  onRepeatSurvey = _.identity,
  onLocationOrUnitError = _.identity,
  options = {}
) {
  let { phone, email, type, arrivalTime, location, unit } = properties;
  phone = _.trim(phone);
  email = _.trim(email);

  const doCheckCanProceed = _.get(options, 'checkCanProceed', true);
  const doCheckLocationOrUnit = _.get(options, 'checkLocationOrUnit', false);

  return createHiveBee(process.env.REACT_APP_BEE_NAME, process.env.REACT_APP_BEE_PASS)
    .then(([bee]) =>
      Promise.resolve()
        .then(() => {
          if (doCheckCanProceed) {
            return checkCanProceed(bee, language, phone, email, onRepeatSurvey);
          }
        })
        .then(() => {
          if (doCheckLocationOrUnit) {
            return checkLocationAndUnitForOutbreak(bee, type, arrivalTime, location, unit, onLocationOrUnitError);
          }
        })
    );
}

export const SurveyService = {
  checkCanProceed,
  checkLocationAndUnitForOutbreak,
  checkUserInfo,
};
