import React, { useCallback, useEffect, useState } from "react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import TextFormatIcon from "@material-ui/icons/TextFormat";
import {
  Box,
  Button,
  Grid,
  Menu,
  MenuItem,
  Typography,
} from "@material-ui/core";
import CheckIcon from "@material-ui/icons/Check";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import _ from "lodash";
import { strings } from "../localizedStrings";

const FONT_SIZE_NORMAL = "16px";
const FONT_SIZE_LARGE = "20px";
const FONT_SIZE_LARGER = "24px";

export function FontSizeSelector({ variant, display }) {
  const [fontSize, setFontSize] = useState(() => localStorage.getItem("rootFontSize") || FONT_SIZE_LARGE);

  const onFontSizeChange = useCallback(
    // To disable deselection, we use `|| zoom` since we receive null on deselection
    (_event, newFontSize) => {
      const val = newFontSize || fontSize;
      setFontSize(val);
      localStorage.setItem("rootFontSize", val);
    },
    [fontSize]
  );

  // Apply any change
  useEffect(() => {
    document.documentElement.style.fontSize = fontSize;
  }, [fontSize]);

  const FontSizeSelector = () => (
    <Grid container justifyContent="center" alignItems="center" spacing={2}>
      <Grid item>
        <Typography variant="h5" style={{ fontSize: "24px" }}>
          {strings.editFontSizeLabel}
        </Typography>
      </Grid>
      <Grid item>
        <ToggleButtonGroup
          exclusive
          value={fontSize}
          onChange={onFontSizeChange}
        >
          <ToggleButton value={FONT_SIZE_NORMAL}>
            <TextFormatIcon style={{ fontSize: "16px" }} />
          </ToggleButton>
          <ToggleButton value={FONT_SIZE_LARGE}>
            <TextFormatIcon style={{ fontSize: "20px" }} />
          </ToggleButton>
          <ToggleButton value={FONT_SIZE_LARGER}>
            <TextFormatIcon style={{ fontSize: "24px" }} />
          </ToggleButton>
        </ToggleButtonGroup>
      </Grid>
    </Grid>
  );

  const FontSizeMenu = () => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
      setAnchorEl(null);
    };
    const handleFontSizeClick = (fontSize) => () => {
      onFontSizeChange(undefined, fontSize);
      handleClose();
    };
    const fontSizes = [FONT_SIZE_NORMAL, FONT_SIZE_LARGE, FONT_SIZE_LARGER];
    return (
      <Box>
        <Button
          fullWidth
          variant={open ? "contained" : "outlined"}
          id="fontSize-button"
          aria-controls={open ? "fontSize-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
          onClick={handleClick}
          color="primary"
          endIcon={<ExpandMoreIcon />}
        >
          <TextFormatIcon />
        </Button>
        <Menu
          id="fontSize-menu"
          anchorEl={anchorEl}
          getContentAnchorEl={null}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
          open={open}
          onClose={handleClose}
          MenuListProps={{
            "aria-labelledby": "fontSize-button",
          }}
        >
          {_.map(fontSizes, (fs) => (
            <MenuItem key={fs} onClick={handleFontSizeClick(fs)}>
              <Box height={36} width="100%" display="flex">
                <Grid container alignItems="center" justifyContent="center">
                  <Grid item>
                    {fontSize === fs ? (
                      <CheckIcon style={{ fontSize: "12px" }} />
                    ) : (
                      <Box width={12} height={24}></Box>
                    )}
                  </Grid>
                  <Grid item>
                    <Box height={fs}>
                      <Typography style={{ fontSize: fs }}>
                        <TextFormatIcon style={{ fontSize: fs }} />
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            </MenuItem>
          ))}
        </Menu>
      </Box>
    );
  };

  if (display) {
    switch (variant) {
      case "menu":
        return <FontSizeMenu />;
      default:
        return <FontSizeSelector />;
    }
  }
  return null;
}
