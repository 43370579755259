import React, { useEffect, useState, useRef, useCallback } from "react";

import { makeStyles , withStyles} from "@material-ui/core/styles";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import Slider from '@material-ui/core/Slider';
import { IconButton, Typography, Container, InputBase } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import {strings} from "../localizedStrings";

const useStyles = makeStyles((theme) => ({
  container: {
    alignItems: "center",
    justifyContent: "center"
  },
  search: {
    flex: 1,
    margin: "0 12px 0 0",
  },
  searchInput: {
    border: "2px solid grey",
    borderRadius: "6px !important"
  },
  hideComponent: {
    display: "none"
  },
  filterMethodIcon: {
    color: "white",
  },
  filterMethodButton: {
    outline: '0 !important',
    ':focus': {
      outline: '0 !important'
    }
  },
  searchRadiusSliderGrid: {
    flex: 1,
    padding: theme.spacing(0,1.5,0,0),
  },
  searchRadiusContainer: {
    padding: 0,
    flexGrow: 0,
    flexShrink: 0,
  },
  txtContainer: {
    justifyContent: "center",
    width: "60px",
    margin: 0,
    boxStyle: "border-box",
  },
  txt: {
    textAlign: 'center',
    fontStyle: 'bold',
  },
}));

const FilterMethodButton = withStyles((theme) => ({
  root: {
    padding: theme.spacing(0, 1, 0, 1),
    boxStyle: "border-box",
  },
  label: {
    background: theme.palette.primary.main + '!important',
    height: '43px !important',
    width: '43px !important',
    padding: 0,
    borderRadius: '25%'
  },
}))(IconButton);

const SearchRadiusSlider = withStyles({
  root: {
    padding: 0
  },
  markLabel: {
    color: "rgba(255,255,255, 0.7)",
  },
  markLabelActive: {
    color: "white"
  }
})(Slider);

export default function SearchLocation(props) {
  const [searchVisible, toggleSearch] = useState(false);
  const [searchRadiusVisible, toggleSearchRadius] = useState(false);
  const classes = useStyles();
  const searchRef = useRef();

  const handleSearchClick = useCallback(() => {
    if (searchVisible) {
      toggleSearch(false);
    } else {
      toggleSearch(true);
    }
    toggleSearchRadius(false);
  }, [searchVisible]);

  const handleSearchRadiusClick = useCallback(() => {
    if (searchRadiusVisible) {
      toggleSearchRadius(false);
    } else {
      toggleSearchRadius(true);
    }
    toggleSearch(false);
  }, [searchRadiusVisible]);

  useEffect(() => {
    if (searchVisible && searchRef && searchRef.current) {
      searchRef.current.focus();
    }
  }, [searchVisible, searchRef]);

  return (
    <Grid className={classes.container} container direction="row">
      <FilterMethodButton className={searchRadiusVisible? classes.hideComponent: classes.filterMethodButton} onClick={handleSearchClick}>
        <SearchIcon className={classes.filterMethodIcon} fontSize="large"/>
      </FilterMethodButton>
      {props.showFilter ? <FilterMethodButton className={searchVisible? classes.hideComponent: props.locationAllowed? classes.filterMethodButton: classes.hideComponent} onClick={handleSearchRadiusClick}>
        <LocationOnIcon className={classes.filterMethodIcon} fontSize="large"/>
      </FilterMethodButton> : null}
      <InputBase
        onChange={props.handleSearchChange}
        inputRef={searchRef}
        className={searchVisible ? classes.search : classes.hideComponent}
        variant="standard"
        placeholder={strings.searchVenuesPlaceholder}
        margin="dense"
        classes={{
          input: classes.searchInput
        }}
      />
      <Grid className={props.locationAllowed && searchRadiusVisible? classes.searchRadiusSliderGrid: classes.hideComponent} container direction="row">
        <SearchRadiusSlider
          defaultValue = {5000}
          step={1000}
          valueLabelDisplay="off"
          max={25000}
          min={0}
          onChangeCommitted={props.handleSearchRadiusChange}
        />
      </Grid>
      {props.showFilter ? <Container className={props.locationAllowed && !searchVisible? classes.txtContainer: classes.hideComponent}>
        <Typography variant="h5">
          {props.currentSearchRadius === 100? "100m": props.currentSearchRadius/1000 + "km"}
        </Typography>
      </Container> : null}
    </Grid>
  );
}

