import React, { useState, useCallback } from "react";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";

import { makeStyles } from '@material-ui/core/styles';
import { strings } from "../localizedStrings.js";
import { useCustomization } from "../customization/Customization.js";
import ReactHtmlParser from 'react-html-parser';

import HealthDefinesUsDialog from '../components/HealthDefinesUsDialog';
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog.js";

import 'moment/locale/fr';
import 'moment/locale/es';

import _ from 'lodash';

const screenResults = require('../util/screenResults');

const useStyles = makeStyles(() => ({
  topContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    minHeight: "100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  resultDiv: {
    minHeight: 'calc(100vh - 195px)',    // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: 'calc(var(--vh, 1vh) * 100 - 195px)',
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
  },
  gradient: {
    height: "30px"
  },
  logo: {
    boxSizing: "border-box",
    height: "inherit",
    padding: '10px 20px',
    maxWidth: '100%',
    objectFit: "contain"
  },
  maclogo: {
    cursor: 'pointer',
    width: '70%',
    maxWidth: '350px'
  },
  CenteredButton: {
    marginTop: "10px",
    marginBottom: "15px",
    backgroundColor: "white",
    textTransform: "none",
    minWidth: "256px",
    border: "2px, #206002",
    boxShadow: "0px 8px 12px 0px rgb(0 0 0 / 50%)",
  },
  infoLogo: {
    position: "absolute",
    right: "5px",
    top: "130px",
    marginLeft: '10px',
    marginTop: '15px',
    height: '24px',
    width: '39px'
  },
  divider: {
    backgroundColor: "white",
    marginTop: "10px",
    marginBottom: "10px",
    width: "50%",
    alignSelf: "center"
  },
  bottomCentered: {
    height: "60px",
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
  },
  textContainer: {
    color: "white",
    margin: "15px 16px",
    textAlign: "center",
    "& ul, ol": {
      paddingInlineStart: "1.5em"
    },
    "& p": {
      margin: 0,
    },
  },
  consentDeniedText: {
    color: "white",
    margin: "0px 16px 0px 16px",
    textAlign: "center",
  }
}));

export default function Warning(props) {
  const classes = useStyles();
  const config = useCustomization(props.language).surveyConfig;

  const redBackground = { background: "#A3002B" };
  const greenBackground = { background: "#299400" };
  const yellowBackground = { background: "#DDBB00" };
  const redGradient = { background: "linear-gradient(#74001f, #A3002B)" };
  const greenGradient = { background: "linear-gradient(#1c6300, #299400)" };
  const yellowGradient = { background: "linear-gradient(#9f8300, #DDBB00)" };
  const redText = { color: "#A3002B" };
  const greenText = { color: "#206002" };
  const yellowText = { color: "#7F6B00" };
  const darkGreenInfoBackground = { background: "#206002" };
  const darkYellowInfoBackground = { background: "#7F6B00" };
  const darkRedInfoBackground = { background: "#A3002B" }; // currently same as normal red

  // For the 'Health defines us' dialog
  const [isHealthDefinesUsDialogOpen, setIsHealthDefinesUsDialogOpen] = useState(false);
  const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false);

  strings.setLanguage(props.language);

  let background = greenBackground;
  let textColor = greenText;
  let gradient = greenGradient;
  let infoBackground = darkGreenInfoBackground;
  if (props.result.rawResultCode >= screenResults.FAIL) {
    background = redBackground;
    textColor = redText;
    gradient = redGradient;
    infoBackground = darkRedInfoBackground;
  } else if (props.result.rawResultCode >= screenResults.PASS_RESTRICTION) {
    background = yellowBackground;
    textColor = yellowText;
    gradient = yellowGradient;
    infoBackground = darkYellowInfoBackground;
  }

  const exitMessage = _.find(config.exitMessages, (obj) => obj.code === props.result.rawResultCode);
  background.background = _.get(exitMessage, "background", background.background);
  textColor.color = _.get(exitMessage, "text", textColor.color);
  gradient.background = _.get(exitMessage, "gradient", gradient.background);
  infoBackground.background = _.get(exitMessage, "infoBackground", infoBackground.background);

  function getResultScreenTitle() {
    if (props.title) {
      return props.title;
    }

    let resultScreenTitle = strings.pass;
    if ((props.result.rawResultCode < screenResults.FAIL) && (props.result.rawResultCode >= screenResults.PASS_RESTRICTION)) {
      resultScreenTitle = strings.passRestriction;
    } else if (props.result.rawResultCode >= screenResults.FAIL) {
      resultScreenTitle =  strings.fail;
    }

    const exitMessage = _.find(config.exitMessages, (obj) => obj.code === props.result.rawResultCode);
    resultScreenTitle = _.get(exitMessage, "title", resultScreenTitle);

    return resultScreenTitle;
  }

  const handleCloseHealthDefinesUsDialog = useCallback(() => {
    setIsHealthDefinesUsDialogOpen(false);
  }, []);

  const handleOpenPrivacyPolicyDialog = useCallback(() => {
    setIsPrivacyPolicyDialogOpen(true);
  });

  const handleClosePrivacyPolicyDialog = useCallback(() => {
    setIsPrivacyPolicyDialogOpen(false);
  }, []);

  return (
    <Container className={classes.topContainer}>
      <PrivacyPolicyDialog
        open={isPrivacyPolicyDialogOpen}
        handleClosePrivacyPolicyDialog={handleClosePrivacyPolicyDialog}
        strings={strings}>
      </PrivacyPolicyDialog>

      <HealthDefinesUsDialog
        open={isHealthDefinesUsDialogOpen}
        handleCloseHealthDefinesUsDialog={handleCloseHealthDefinesUsDialog}
        openPrivacyPolicyDialog={handleOpenPrivacyPolicyDialog}
        strings={strings}>
      </HealthDefinesUsDialog>

      {
        props.logoUrl &&
        <div style={{ height: "100px" }}>
          <img src={props.logoUrl} alt="Client Logo" className={classes.logo} />
        </div>
      }

      <div className={classes.gradient} style={gradient} />
      <div className={classes.resultDiv} style={background} >

        <div style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}>
          <div>
            <Typography variant="h3" style={{ fontStyle: "oblique", color: "white" }} >
              {getResultScreenTitle()}
            </Typography>

            <div className={classes.textContainer}>
              <Typography>
                {ReactHtmlParser(props.result.resultMsg)}
              </Typography>

            </div>
          </div>

          {
            props.result.alternateDeniedText &&
            <Typography className={classes.consentDeniedText}>
              {props.result.alternateDeniedText}
            </Typography>
          }
        </div>

        {
          props.onDone &&
          <div className={classes.bottomCentered}>
            <Button variant="contained"
              onClick={() => { props.onDone(); }}
              style={textColor}
              className={classes.CenteredButton} >
              {strings.done}
            </Button>
          </div>
        }

        {
          props.onContinue &&
          <div className={classes.bottomCentered}>
            <Button variant="contained"
              onClick={() => { props.onContinue(); }}
              style={textColor}
              className={classes.CenteredButton} >
              {strings.continue}
            </Button>
          </div>
        }
      </div>

      <p>
        {strings.getLanguage() === "fr"
          ? <img src='/mac-HealthDefinesUs-fr.svg' alt="Macadamian Logo" className={classes.maclogo} onClick={() => setIsHealthDefinesUsDialogOpen(true)} />
          : <img src='/mac-HealthDefinesUs-en.svg' alt="Macadamian Logo" className={classes.maclogo} onClick={() => setIsHealthDefinesUsDialogOpen(true)} />
        }
      </p>

    </Container>

  );
}

