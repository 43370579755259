import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, makeStyles } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import ReactHtmlParser from 'react-html-parser';

export default function PrivacyPolicyDialog(props) {
  const [isOpen, setIsOpen] = useState(props.open);

  const useStyles = makeStyles((theme) => ({
    dialogTitle: {
      color: theme.palette.primary.main,
      padding: "10px 24px",
    },
    dialogActions: {
      justifyContent: "center"
    },
    closeButton: {
      position: 'absolute',
      top: theme.spacing(0.5),
      right: theme.spacing(1),
    },
  }));

  const closeDialog = () => {
    setIsOpen(false);
    props.handleClosePrivacyPolicyDialog();
  };

  const classes = useStyles();

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  return (
    <Dialog open={isOpen}>
      <DialogTitle className={classes.dialogTitle} id="info-dialog">
        {props.strings.privacyPolicyTitle}

        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={closeDialog} >
          <CloseIcon />
        </IconButton>

      </DialogTitle>
      <DialogContent dividers>
        {ReactHtmlParser(props.strings.privacyPolicyText)}
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <Button onClick={closeDialog} variant="contained" color="primary" autoFocus className={classes.okButton}>
          {props.strings.ok}
        </Button>
      </DialogActions>
    </Dialog>
  );
}