import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

import { strings } from "../localizedStrings";

export default function InactivityWarningDialog(props) {

  const handleExit = () => {
    props.handleChangeOpen(false);
    props.inactivityRedirect();
  };

  const handleContinue = () => {
    props.handleChangeOpen(false);
  };

  return (
    <div>
      <Dialog
        open={true}
        onClose={handleContinue}
      >
        <DialogTitle id="alert-dialog-title">
          {strings.timeoutWarningTitle}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {strings.timeoutWarningText.replace("%1", Math.floor(props.countdown / 1000))}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleExit}
            color="primary"
          >
            {strings.timeoutWarningExitOption}
          </Button>
          <Button
            onClick={handleContinue}
            color="primary"
            variant="contained"
            autoFocus
          >
            {strings.timemoutWarningContinueOption}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
