import { useEffect, useState, useMemo} from 'react';
const REGEX_LOCATION_ID = /^\/kiosk(\/([\w-]*))?$/;

export function useKiosk() {
  const [ isKiosk, setIsKiosk ] = useState(!!REGEX_LOCATION_ID.exec(window.location.pathname));
  const [ locationId, setLocationId] = useState();

  useEffect(
    () => {
      const match = REGEX_LOCATION_ID.exec(window.location.pathname);

      if (match) {
        const [ ,, locationId] = match;
        setIsKiosk(true);
        setLocationId(locationId);
      } else {
        setIsKiosk(false);
        setLocationId(undefined);
      }
    },
    [ window.location ]
  );

  return useMemo(
    () => ({ isKiosk, locationId }),
    [ isKiosk, locationId ]
  );
}
