import { BehaviorSubject } from "rxjs";
import _ from 'lodash';


const LOCATION_UNKNOWN = {
  latitude: 0,
  longitude: 0,
  accuracy: Number.MAX_SAFE_INTEGER,
};

const currentGpsLocation = new BehaviorSubject(
  _.cloneDeep(LOCATION_UNKNOWN)
);
let didPublishGpsLocation = false;

/**
 * Beyond this an the GPS is probably not working
 */
export const MAXIMUM_ALLOWED_ACCURACY = 30000;

/**
 * Returns an Observable which will be updated when+if the browser gives an update.
 * You must call updateGpsLocationNow !!!while processing a user interaction!!! such as a click to start the process.
 */
export function watchGpsLocation() {
  return currentGpsLocation;
}

/**
 * The actual object from the browser API has overrides to prevent serialization so we copy the args we need manually
 * @param gpsLocationUpdate The location update from the browser
 */
function copyGeo(gpsLocationUpdate) {
  return {
    latitude: gpsLocationUpdate.coords.latitude,
    longitude: gpsLocationUpdate.coords.longitude,
    accuracy: gpsLocationUpdate.coords.accuracy,
  };
}

/**
 * Attempts to fetch the user's current location.
 * This function should only be called in the context of a user action such as a click.
 * Calling this function at any other time may result in the api ignoring the call.
 */
export function updateGpsLocationNow() {
  if (navigator.geolocation) {
    navigator.geolocation.getCurrentPosition(
      (l) => {
        const gpsLocation = copyGeo(l);
        if (gpsLocation.accuracy < MAXIMUM_ALLOWED_ACCURACY) {
          didPublishGpsLocation = true;
          currentGpsLocation.next(gpsLocation);
          // console.debug("Updated users location", gpsLocation);
        } else {
          console.warn(
            "User location updated, but the accuracy wasn't good enough to use for distance checking"
          );
        }
      },
      (err) => {
        didPublishGpsLocation = true;
        currentGpsLocation.next(_.cloneDeep(LOCATION_UNKNOWN));
        console.error("gps location fetch failed", err);
      }
    );
  } else {
    console.error("Featured Not Supported or Not Authorized by User");
  }
}

export function usersGpsLocationHasBeenDetermined() {
  return didPublishGpsLocation;
}
