const styleOverrides = {
  root: {
    '.visit-information .header &': {
      display: "inline-block",
      position: "absolute",
      left: '8px',
      width: "32px",
      height: "32px",
    }
  },
};

export const MuiSvgIcon = {
  ...styleOverrides,
};
