const PASS = 0;
const PASS_RESTRICTION = 5;
const PASS_RESTRICTION_NO_EMAIL = 6;
const FAIL = 10;
const FAIL_NO_EMAIL = 11;

module.exports = {
  PASS,
  PASS_RESTRICTION,
  PASS_RESTRICTION_NO_EMAIL,
  FAIL,
  FAIL_NO_EMAIL
};
