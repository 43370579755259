import Container from "@material-ui/core/Container";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Grid from "@material-ui/core/Grid";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormLabel from "@material-ui/core/FormLabel";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import NumberFormat from 'react-number-format';
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import { ChevronLeft } from '@material-ui/icons';
import React, { useContext, useEffect, useMemo, useState } from "react";
import PropTypes from 'prop-types';
import { strings } from "../localizedStrings.js";
import { useCustomization } from "../customization/Customization.js";
import Ajv from 'ajv';

import PhoneInput from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import IntlPhoneTextField from '../components/IntlPhoneTextField';
import { Box, Checkbox, CircularProgress } from "@material-ui/core";
import { useCookies } from "react-cookie";
import CustomField from "../components/CustomField.js";
import { DataContext } from '../contexts/DataContext';
import { ExternalUnits } from "../components/ExternalUnits.js";
import { SurveyService } from "../services/SurveyService.js";
import { getKioskableValue } from "../util/getKioskableValue.js";
import { useKiosk } from "../hooks/useKiosk";
import { FontSizeSelector } from "../components/FontSizeSelector.js";

const _ = require('lodash');

const IS_NETFLIX = process.env.REACT_APP_ENTERPRISE_NAME === "netflix";

const useStyles = makeStyles(() => ({
  headerText: {
    display: "inline-block",
    width: "85%",
  },
  backIcon: {
    display: "inline-block",
    position: "absolute",
    left: '8px',
    width: "32px",
    height: "32px",
  },
  labelText: {
    marginTop: "5px",
    marginBottom: "0px",
  },
  bottomCentered: {
    textAlign: 'center'
  },
  topContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  nextButton: {
    width: "25vw",
    minHeight: "5vh",
    maxWidth: "300px",
    textTransform: "none",
    color: "white",
    "&.Mui-disabled" : {
      color: "#939398",
      backgroundColor: "#E6E6E6",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#939398",
    },
  },
  checkboxLabel: {
    marginBottom: "2px",
  },
  normalText: {
    fontFamily: "Arial",
    textAlign: "center",
    margin: "15px 10px 30px 10px"
  },
}));

// TODO: find a way to pass the phone number format from TextField to here. For the moment, we use one of the
// following two formatters.
function NumberFormatPhoneCustomWithExtension(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      type="tel"
      mask=" "
      format="(###) ###-####  ext. #####"
      isNumericString
    />
  );
}

function NumberFormatPhoneCustom(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      type="tel"
      mask=" "
      format="(###) ###-####"
      isNumericString
    />
  );
}

NumberFormatPhoneCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

const SCHEMA_EMAIL = {
  anyOf: [
    { type: 'string', format: 'email' },
  ]
};

const validateEmail = new Ajv().compile(SCHEMA_EMAIL);

const formatIntlPhoneNumber = (phone, extSeparator = '') => {
  const match = /(\+1\d{10})[,]*(\d*)/.exec(phone);
  if (match) {
    const [ , number, extension ] = match;
    if (extension) {
      phone = `${number}${extSeparator}${extension}`;
    } else {
      phone = number;
    }
  }
  return phone || "";
};

const formatNAPhoneNumber = (phone) => {
  return phone || "";
};

export default function UserInfoPage(props) {
  const classes = useStyles();
  const { effectiveLocations: locationsList } = useContext(DataContext);
  const config = useCustomization(props.language).surveyConfig;
  const selectLocationOnSeparatePage = _.get(config, 'userInfo.selectLocationOnSeparatePage', false);
  const visitInformation = _.get(config, 'visitInformation');
  const visitInformationSeparateFromUserInfo = _.get(visitInformation, 'separateFromUserInfo', false);
  const visitInformationReasonEnabled = _.get(visitInformation, "reason", false);
  const { isKiosk } = useKiosk();
  const displayFontSizeSelector = isKiosk ?
    _.get(config, "showFontSizeSelectorKiosk", false)
    :
    _.get(config, "showFontSizeSelectorMobile", false);
  const visitUnitEnabled = isKiosk ?
    _.get(visitInformation, "unitKiosk", false)
    :
    _.get(visitInformation, "unit", false);
  const [firstName, setFirstName] = React.useState(props.userInfo.firstName ? props.userInfo.firstName : "");
  const [lastName, setLastName] = React.useState(props.userInfo.lastName ? props.userInfo.lastName : "");
  const [email, setEmail] = React.useState(props.userInfo.email ? props.userInfo.email : "");
  const [phone, setPhone] = React.useState(
    config.userInfo.intlPhone
      ? formatIntlPhoneNumber(props.userInfo.phone)
      : formatNAPhoneNumber(props.userInfo.phone)
  );
  const [unit, setUnit] = React.useState(props.userInfo.unit ? props.userInfo.unit : "");
  const [otherLocation, setOtherLocation] = React.useState(props.userInfo.otherLocation ? props.userInfo.otherLocation : "");
  const [customFields, setCustomFields] = React.useState({});
  const [isPhoneUrgent] = React.useState(config.urgentPhone === true);
  const [customFieldDependent] = React.useState(() => config.userInfo.customFieldsDependentProp ? config.userInfo.customFieldsDependentProp : "");
  const [customFieldsToRender, setCustomFieldsToRender] = React.useState([]);
  const isNameEnabled = getKioskableValue(props.isKiosk, config, "userInfo.name");
  const isLastNameEnabled =
    isNameEnabled && !_.get(config, "hideLastName", false);
  const isPhoneEnabled = getKioskableValue(props.isKiosk, config, "userInfo.phone");
  const isPhoneOptionalEnabled = getKioskableValue(props.isKiosk, config, "userInfo.phoneOptional");
  const isEmailEnabled = getKioskableValue(props.isKiosk, config, "userInfo.email");
  const isEmailOptionalEnabled = getKioskableValue(props.isKiosk, config, "userInfo.isEmailOptional");
  const userInfoUnitEnabled = getKioskableValue(isKiosk, config, "userInfo.unit");
  const isTypesEnabled = getKioskableValue(isKiosk, config, "userInfo.types");
  const isLocationsEnabled = getKioskableValue(isKiosk, config, "userInfo.locations");
  const [cookies, setCookies] = useCookies(["countryCode", "userInfo"]);
  const [requiresCovidTestTracking] = React.useState(() => _.get(config, 'requiresCovidTestTracking', false));
  const [allowSmsNotifications, setAllowSmsNotifications] = React.useState(() => {
    const cookiesAllowed = _.get(cookies, 'userInfo.allowSmsNotifications');
    return _.isNil(cookiesAllowed)
      ? requiresCovidTestTracking
      : cookiesAllowed
    ;
  });

  strings.setLanguage(props.language);

  const typesList = useMemo(
    () => _.flow([
      config => _.get(config, 'userInfo.typesList', []),
      list => _.filter(list, v => !!v),
      list => _.get(config, 'userInfo.typesListSorted', false)
        ? _.orderBy(list)
        : list,
    ])(config),
    [ config ]
  );

  const [ type, setType ] = useState(() => _.includes(typesList, props.userInfo.type) ? props.userInfo.type : "");

  const locationsRadio = useMemo(
    () => !!_.get(config, 'userInfo.locationsRadio', false) || locationsList.length <= 6,
    [ config, locationsList ]
  );

  const [ location, setLocation ] = useState(() => _.get(props, 'userInfo.location')
    || _.get(config, 'userInfo.defaultLocation', '')
  );

  const emailCollectionMessage = () => {
    const baseMessage = _.get(config, "emailCollectionText", strings.emailResults);

    if (props.isKiosk) {
      const kioskMessage = _.get(config, "emailCollectionTextOnKiosk", baseMessage);
      return kioskMessage;
    }
    return baseMessage;
  };

  // Determine the default country code. Use whatever is stored in the cookes. If we do not have
  // such cookies, we fetch from a GeoIP service. The country code will be stored in the cookies, once
  // the user clicks continue.
  const [countryCode, setCountryCode] = useState(cookies.countryCode);

  const [ defaultCountryCode, setDefaultCountryCode ] = useState(countryCode);
  useEffect(
    () => {
      if (_.get(config, 'userInfo.intlPhone')) {
        fetch('https://get.geojs.io/v1/ip/country.json')
          .then(res => res.json())
          .then(res => setDefaultCountryCode(res.country))
          .catch(console.error)
        ;
      }
      if (_.get(config, 'hideLastName')) {
        setLastName("");
      }
    },
    [config]
  );

  const handleLocationChange = (event) => {
    checkCustomFieldRules("location", event.target.value);
    setLocation(event.target.value);
  };

  const handleTypeChange = (event) => {
    checkCustomFieldRules("type", event.target.value);
    setType(event.target.value);
  };

  const [ proceeding, setProceeding ] = useState(false);
  function saveUserInfo() {
    // Send User Info to home view. Home view will switch the view

    let resCustomFields = {};

    if (config.userInfo.customFields) {
      // return an object for the customFields: e.g. {age:"15", dept:"Finance", floor:"5th"}
      _.forEach(customFieldsToRender, fieldKey => {
        // sets only the custom fields applicable for the rules given
        _.set(resCustomFields, fieldKey, customFields[fieldKey] || "");
      });
    }

    let formattedPhone = config.userInfo.intlPhone
      ? formatIntlPhoneNumber(phone, ',')
      : formatNAPhoneNumber(phone)
    ;

    let emailAddr = email;
    if (((config.userInfo.isEmailOptional && email !== "") || !config.userInfo.isEmailOptional) && !validateEmail(email)) {
      emailAddr = "";
      setEmail("");
    }

    // Remove any leading or trailing spaces from the email
    const newUserInfo = {
      ...props.userInfo,
      firstName,
      lastName,
      email: emailAddr.trim(),
      phone: formattedPhone.trim(),
      unit,
      type,
      location,
      otherLocation,
      customFields: resCustomFields,
      allowSmsNotifications,
    };

    setProceeding(true);
    SurveyService.checkUserInfo(
      props.language,
      newUserInfo,
      props.onRepeatSurvey,
      props.onLocationOrUnitOutbreak,
      {
        // there is no reason to check for outbreaks if units aren't enabled or no types are affected
        checkLocationOrUnit: visitUnitEnabled
          && (_.get(config, "userInfo.typesAffectedByOutbreaks", []).length > 0)
          && !visitInformationSeparateFromUserInfo // no need to check location or unit yet if there is additional visit information yet to be collected
      }
    )
      .then(() => {
        // Remove any leading or trailing spaces from the email
        props.onSetUserInfo(newUserInfo);

        // Store the country code in the cookies, to avoid fetching GeoIP too often.
        // We put a max-age of 1 day in order to refresh (for travellers)
        setCookies("countryCode", countryCode, { path: "/", maxAge: 86400 });
      })
      .catch(() => {
        // Nothing to do here, just need to catch it
      })
      .finally(() => setProceeding(false))
    ;
  }

  function shouldValidateEmail() {
    return isEmailEnabled
      && (!isEmailOptionalEnabled
        || (isEmailOptionalEnabled && email));
  }

  function shouldValidatePhone() {
    return isPhoneEnabled
      && (!isPhoneOptionalEnabled
        || (isPhoneOptionalEnabled && phone));
  }

  function isNextDisable() {
    if (proceeding) {
      return true;
    }

    if (isNameEnabled && ((firstName.trim() === "") || (!config.hideLastName && lastName.trim() === ""))) {
      return true;
    }

    if (shouldValidateEmail() && !validateEmail(email)) {
      return true;
    }

    if (shouldValidatePhone() && !config.userInfo.intlPhone && phone <= 999999999) {
      return true;
    }

    // 7 digits seems to be the shortest possible international phone number.
    if (shouldValidatePhone() && config.userInfo.intlPhone && (phone === undefined || phone.length < 7)) {
      return true;
    }

    if (userInfoUnitEnabled && unit.trim() === "") {
      return true;
    }

    if (isLocationsEnabled && location === "") {
      return true;
    }

    if (isTypesEnabled && type === "" && !visitInformationReasonEnabled) {
      return true;
    }

    if (config.userInfo.otherLocation && location === "Other" && otherLocation.trim() === "") {
      return true;
    }

    if (config.userInfo.customFields) {

      for (let fieldKey of customFieldsToRender) {

        let field = _.find(config.userInfo.customFields, field => field.key === fieldKey);
        let fieldValue = _.get(customFields, `${fieldKey}`);

        if (_.get(field, "type") !== "checkbox" && !fieldValue) {
          return true;
        }
      }
    }

    return false;
  }

  function updateCustomField(newValue, index) {
    let newcf = { ...customFields };
    newcf[index] = newValue;
    setCustomFields(newcf);
  }

  // Add flex-basis style on the first element to fix a layout issue with IE11.
  function ie11styleFix(index) {
    if (index === 0) {
      return { flexBasis: "100vw" };
    } else {
      return {};
    }
  }

  function checkCustomFieldRules(prop, val) {
    if (prop !== customFieldDependent) {
      return;
    }

    let fieldsToRender = [];
    const selectedField = _.find(config.userInfo.customFieldsRules, { forValue: val });

    if (selectedField) {
      fieldsToRender = _.map(
        selectedField.enabledFields,
        index => _.get(config, `userInfo.customFields.${index}`, {}).key
      );
    }

    setCustomFieldsToRender(fieldsToRender);
  }

  useEffect(() => {
    if (customFieldDependent) {
      let defaultVal = type;
      if (customFieldDependent === "location") {
        defaultVal = location;
      }
      checkCustomFieldRules(customFieldDependent, defaultVal);
    } else if (config.userInfo.customFields) {
      const allKeys = _.map(config.userInfo.customFields, field => field.key);
      setCustomFieldsToRender(allKeys);
    }

  }, [customFieldDependent, config.userInfo, props.userInfo.customFields]);

  useEffect(() => {
    const savedCustomFields = _.get(props.userInfo, "customFields", {});

    const defaultCustomFields = _.pick(
      savedCustomFields,
      customFieldsToRender,
    );

    setCustomFields(defaultCustomFields);
  }, [ customFieldsToRender, props.userInfo ]);

  const emailLabel = useMemo( () => {
    return isEmailOptionalEnabled ? _.get(config, "emailFieldLabelOptional", strings.email) : _.get(config, "emailFieldLabelMandatory", strings.email);
  }, [isEmailOptionalEnabled, config]);

  const phoneLabel = useMemo( () => {
    return isPhoneOptionalEnabled ? _.get(config, "phoneFieldLabelOptional", strings.phone) : _.get(config, "phoneFieldLabelMandatory", strings.phone);
  }, [isPhoneOptionalEnabled, config]);

  return (
    <Container className={classes.topContainer}>

      <div style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }} >
        <ChevronLeft
          className={classes.backIcon}
          color="primary"
          onClick={() => { props.onBackScreen("userInfoPage"); }}>
        </ChevronLeft>
        <Grid container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Box width={84}></Box>
          </Grid>
          <Grid item xs>
            <Typography variant='h5' color="primary" className={classes.headerText}>
              {strings.aboutYourself}
            </Typography>
          </Grid>
          <Grid item>
            <Box width={84}>
              <FontSizeSelector
                variant="menu"
                display={displayFontSizeSelector}
              />
            </Box>
          </Grid>
        </Grid>
      </div>

      <div>
        {isNameEnabled &&
          <TextField required label={!isLastNameEnabled ? strings.name : strings.firstName} variant="outlined" fullWidth margin="dense"
            value={firstName}
            style={{ marginBottom: "15px" }}
            InputProps={{ 'data-cy': 'input-firstname' }}
            onChange={e => setFirstName(e.target.value)} />
        }
        {isLastNameEnabled &&
          <TextField required label={strings.lastName} variant="outlined" fullWidth margin="dense"
            value={lastName}
            style={{ marginBottom: "15px" }}
            InputProps={{ 'data-cy': 'input-lastname' }}
            onChange={e => setLastName(e.target.value)} />
        }
        {isPhoneEnabled &&
          <div style={{ marginBottom: "15px" }} >
            {config.userInfo.intlPhone ?
              <PhoneInput
                value={phone}
                defaultCountry={defaultCountryCode}
                onChange={setPhone}
                onCountryChange={setCountryCode}
                inputComponent={IntlPhoneTextField}
                style={{ "--PhoneInputCountryFlag-height": "1em", flexDirection: "row-reverse" }} />
              :
              <TextField
                required={!isPhoneOptionalEnabled}
                label={phoneLabel}
                variant="outlined"
                fullWidth
                margin="dense"
                style={{ marginBottom: 0 }}
                value={phone}
                InputProps={{
                  inputComponent: _.get(config, 'userInfo.disablePhoneExt', false)
                    ? NumberFormatPhoneCustom
                    : NumberFormatPhoneCustomWithExtension,
                  'data-cy': 'input-phone',
                }}
                error={phone < 999999999 && phone !== ""}
                onChange={(e) => { setPhone(e.target.value); }} />
            }

            {isPhoneUrgent &&
              <Typography variant='body2' style={{ fontStyle: "italic", fontWeight: "200" }}>
                {strings.urgentPhone}
              </Typography>
            }

            {
              requiresCovidTestTracking &&
              <FormControlLabel
                control={
                  <Checkbox
                    color="primary"
                    checked={allowSmsNotifications}
                    onChange={(e) => setAllowSmsNotifications(e.target.checked)}
                  />
                }
                classes={{
                  label: classes.checkboxLabel
                }}
                label={IS_NETFLIX ? strings.covidTestingAllowSmsNetflix : strings.covidTestingAllowSms}
              />
            }
          </div>
        }
        {isEmailEnabled &&
          <div style={{ marginBottom: "15px" }}>
            <TextField
              required={!isEmailOptionalEnabled}
              label={emailLabel}
              variant="outlined"
              fullWidth margin="dense"
              error={!validateEmail(email) && (email !== "")}
              value={email}
              InputProps={{ 'data-cy': 'input-email' }}
              onChange={e => setEmail(_.trim(e.target.value))} />
            <Typography variant='body2' style={{ fontStyle: "italic" }}>
              {emailCollectionMessage()}
            </Typography>
          </div>
        }
        {userInfoUnitEnabled && !visitInformationSeparateFromUserInfo &&
          <TextField required label={config.userInfo.unitLabel} variant="outlined" fullWidth margin="dense"
            placeholder={config.userInfo.unitPlaceHolder}
            value={unit}
            InputProps={{ 'data-cy': 'input-unit' }}
            style={{ marginBottom: "15px" }}
            onChange={e => setUnit(e.target.value)} />
        }

        {isTypesEnabled &&
          !visitInformationReasonEnabled &&
          <Grid container style={{ marginBottom: "15px" }}>
            <FormControl variant="outlined" margin='dense' fullWidth>
              <InputLabel required id="type-input-label">{config.userInfo.typesLabel[0]}</InputLabel>
              <Select
                labelId="type-select-label"
                label={config.userInfo.typesLabel[0]}
                value={type} onChange={handleTypeChange}>
                {/* Sorts the types */}
                {typesList
                  .map((t, index) => (<MenuItem key={index} value={t}>{t}</MenuItem>))
                }
              </Select>
            </FormControl>
          </Grid>
        }

        {props.didNearMeAlready === false && isLocationsEnabled && !selectLocationOnSeparatePage &&
          <Grid container style={{ marginBottom: "15px" }}>
            {!locationsRadio
              ? <FormControl variant="outlined" margin='dense' fullWidth>
                <InputLabel id="location-input-label">{config.userInfo.locationsLabel || strings.location}</InputLabel>
                <Select
                  labelId="location-select-label"
                  label={config.userInfo.locationsLabel || strings.location}
                  value={location} onChange={handleLocationChange}>
                  {/* Sorts the locations */}
                  {locationsList
                    .map((loc, index) => (<MenuItem key={index} value={loc.id}>{loc.name}</MenuItem>))
                  }
                  {/* TODO handle the 'other' option */}
                </Select>
              </FormControl>
              : <FormControl component="fieldset">
                <FormLabel className={classes.labelText} component="legend">{config.userInfo.locationsLabel || strings.location}</FormLabel>
                <RadioGroup aria-label="position" name="loc1" value={location} row onChange={handleLocationChange}>
                  {locationsList.map((loc, index) => (
                    <React.Fragment key={index}>
                      {/* Add flexBasis style to the first element to fix a bug in IE11 */}
                      <Grid item xs={12} style={ie11styleFix(index)}>
                        <FormControlLabel value={loc.id} control={<Radio color="primary" />} style={{ marginLeft: "0px", float: "left" }} label={loc.name} />
                      </Grid>
                    </React.Fragment>
                  ))}

                  {/* Add other location if supported in the config */}
                  {config.userInfo.otherLocation &&
                    <React.Fragment key={1000}>
                      {/* Add flex-basis style to fix an issue with IE11 */}
                      <Grid item xs={12} style={{ flexBasis: "100vw" }} >
                        <FormControlLabel value={"Other"} control={<Radio color="primary" />} style={{ marginLeft: "0px", float: "left" }} label={strings.otherLocation} />
                        {location === "Other" &&
                          <TextField
                            label={strings.otherLocationLabel}
                            value={otherLocation} variant="outlined" margin="dense"
                            style={{ display: "flex", marginTop: "0px", maxWidth: "300px" }}
                            onChange={e => setOtherLocation(e.target.value)} />
                        }
                      </Grid>
                    </React.Fragment>
                  }
                </RadioGroup>
              </FormControl>
            }
          </Grid>
        }

        {
          !visitInformationSeparateFromUserInfo &&
          <ExternalUnits
            classes={classes}
            locationId={location}
            unit={unit}
            setUnit={setUnit}
          />
        }

        {
          config.userInfo.customFields &&
            _.map(config.userInfo.customFields, field => {
              if (_.includes(customFieldsToRender, field.key)) {
                return <CustomField
                  key={field.key}
                  field={field}
                  value={_.get(customFields, field.key, "")}
                  onChange={e => updateCustomField(e.target.value, field.key)}
                />;
              }
            })
        }
      </div>
      <div className={classes.bottomCentered}>

        {
          IS_NETFLIX &&
            <Typography variant="h5" className={classes.normalText}>
              {strings.userInfoConsent}
            </Typography>
        }

        <Button variant="contained" color="primary"
          onClick={() => { saveUserInfo(); }}
          disabled={isNextDisable()}
          className={classes.nextButton} >
          {
            (props.isLoading || proceeding) ?
              <CircularProgress size="24px" style={{ color: "white" }} />
              :
              strings.next
          }
        </Button>
      </div>

    </Container>
  );
}
