import React from "react";
import { forwardRef } from "react";
import TextField from "@material-ui/core/TextField";
import { strings } from "../localizedStrings.js";

const IntlPhoneTextField = (props, ref) => {
  return (
    <TextField
      {...props}
      required
      label={strings.phone}
      inputRef={ref}
      size="small"
      fullWidth
      style={{ marginRight: "10px" }}
      variant="outlined"
      InputProps={{ "data-cy": "input-phone" }}
    />
  );
};

export default forwardRef(IntlPhoneTextField);
