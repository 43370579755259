const _fp = require("lodash/fp");

function generateName( firstName, lastName , hideLastName = false, separator = ' ', lastNameFirst = false) {
  return _fp.flow([
    // Put in array
    () => [ firstName, hideLastName ? null : lastName ],

    _fp.map(_fp.trim),

    // Removes any undefined values
    _fp.compact,

    // Reverse the array or keep as is
    lastNameFirst ? _fp.reverse : _fp.identity,

    _fp.join(separator),
  ])();
};

module.exports = {generateName};