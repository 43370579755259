import React, { useCallback, useEffect } from "react";
import { TextField } from "@material-ui/core";
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from "@date-io/moment";
import moment from "moment";
import { useMemo } from "react";
import { strings } from "../localizedStrings";

export default function TextInputFormat({
  qstate,
  setQstate,
  qFlow,
  className,
  enableNextQuestion,
  updateEnableNextQuestion,
}) {

  const handleDateInput = useCallback((date) => {
    let newState = { ...qstate };
    newState.textInput[newState.currentQuestion] = date;

    setQstate(newState);
  }, [qstate]);

  const handleTextInput = useCallback((event) => {
    updateEnableNextQuestion(true);

    let newState = { ...qstate };
    newState.textInput[newState.currentQuestion] = event.target.value;
    setQstate(newState);
  }, [qstate]);

  const timeValue = useMemo(
    () => {
      // date value blank until user enters a date
      // https://github.com/mui/material-ui-pickers/issues/1613
      return qstate.textInput[qstate.currentQuestion] || null;
    },
    [qstate]
  );

  const renderDate = useMemo(
    () => {
      return qFlow[qstate.currentQuestion].questionFormat === "date" ||
      qFlow[qstate.currentQuestion].questionType === "DateInput";
    },
    [qFlow, qstate]
  );

  // prevent users from advancing to the next question if date input is missing, invalid, or in the future
  useEffect(() => {
    if (renderDate && (
      !timeValue ||
      isNaN(timeValue) ||
      !moment(timeValue).isValid() ||
      moment() < moment(timeValue))
    ) {
      updateEnableNextQuestion(false);
    } else {
      updateEnableNextQuestion(true);
    }
  }, [qstate,
    renderDate,
    timeValue,
    enableNextQuestion,
    updateEnableNextQuestion,
  ]);

  return (
    <div>
      {
        !renderDate &&
        <TextField
          className={className}
          multiline={!!qFlow[qstate.currentQuestion].questionTextInputMultiline}
          rows={5}
          value={qstate.textInput[qstate.currentQuestion]}
          onChange={handleTextInput}
          inputProps={{ maxLength: 256 }}
        />
      }
      {
        renderDate &&
        <MuiPickersUtilsProvider libInstance={moment} utils={MomentUtils}>
          <KeyboardDatePicker
            disableFuture
            autoOk
            className={className}
            cancelLabel={strings.cancel}
            invalidDateMessage={strings.invalidDateMessage}
            maxDateMessage={strings.maxDateMessage}
            minDateMessage=""
            value={timeValue}
            onChange={handleDateInput}
            format="YYYY-MM-DD" // ISO 8601
          />
        </MuiPickersUtilsProvider>
      }
    </div>
  );
}