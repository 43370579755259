import { useCallback, useEffect, useState } from "react";
import moment from 'moment';

const generateUrl = () => {
  // Generate a URL from our current location, but add an ever changing fake search param
  // in order to prevent hitting the cache.
  const url = new URL(window.location.origin);
  url.searchParams.append('temp', moment().unix());
  return url.toString();
};

export default function useOnline() {
  // Assume online until proven otherwise. This will avoid flickers of things that are shown
  // only when offline.
  const [online, setOnline] = useState(true);

  const checkOnline = useCallback(
    (type) => {
      console.log('useOnline: checking', type);

      // Note: we use HEAD in order to prevent receiving actual data, which we don't care about.
      fetch(generateUrl(), { method: 'HEAD' })
        .then(() => {
          // Regardless of the status code, we got a response, so we are online
          console.log('useOnline: success', type);
          setOnline(true);
        })
        .catch(error => {
          console.log('useOnline: error', type, error);
          setOnline(false);
        })
      ;
    },
    []
  );

  useEffect(
    () => {
      console.log('useOnline: adding listeners');
      const onOnline = () => checkOnline('online');
      const onOffline = () => checkOnline('offline');

      window.addEventListener('online', onOnline);
      window.addEventListener('offline', onOffline);

      checkOnline('init');

      return () => {
        console.log('useOnline: removing listeners');
        window.removeEventListener('online', onOnline);
        window.removeEventListener('offline', setOnline);
      };
    },
    [ checkOnline ]
  );

  return online;
}
