const styleOverrides = {
  root: {
    '.visit-information .buttons &': {
      textTransform: "none",
      fontSize: "1rem",
      maxWidth: "150px",
      width: "25vw",
      '&.Mui-disabled': {
        color: '#939398',
        backgroundColor: '#E6E6E6',
        borderWidth: '1px',
        borderStyle: 'solid',
        borderColor: '#939398',
      },
    },
  },
};

export const MuiButton = {
  ...styleOverrides,
};
