import React, { useCallback, useContext, useEffect, useMemo } from 'react';
import {
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import _ from 'lodash';
import { DataContext } from '../../contexts/DataContext';
import moment from 'moment';
import { useCookies } from 'react-cookie';
import { getKioskableValue } from '../../util/getKioskableValue';

const getHours = m => moment(m).local().hours();
const getMinutes = m => {
  m = moment(m).local().minutes();
  return Math.floor(m / 10) * 10;
};

const formatHours = (t, language = 'en') => {
  const m = moment().hours(t);

  if (language === 'fr') {
    return m.format('HH');
  }

  return m.format('h A');
};

const formatMinutes = (t) => {
  const m = moment().minutes(t);
  return m.format('mm');
};

export function ArrivalTime({
  arrivalTime = moment().utc(),
  setArrivalTime = _.identity,
  isKiosk = false,
}) {
  const [ { language }] = useCookies([ 'language' ]);
  const { surveyConfig } = useContext(DataContext);
  const visitInformation = _.get(surveyConfig, 'visitInformation');

  const arrivalTimeEnabled = getKioskableValue(isKiosk, visitInformation, "arrivalTime");
  const arrivalTimeLabel = _.get(visitInformation, 'arrivalTimeLabel', 'TODO: arrivalTime');

  // Format the initial time
  useEffect(
    () => setArrivalTime(p => moment(p)
      .local()
      .seconds(0)
      .milliseconds(0)
      .utc()),
    []
  );

  const onHoursChange = useCallback(
    (event) =>  {
      // Need to keep the value for the method in setArrivalTime to use it without crashing du
      // to the event disappearing
      const hours = Number(event.target.value);
      setArrivalTime((p) => moment(p)
        .local()
        .hours(hours)
        .utc()
      );
    },
    [setArrivalTime]
  );

  const onMinutesChange = useCallback(
    (event) => {
      // Need to keep the value for the method in setArrivalTime to use it without crashing du
      // to the event disappearing
      const minutes = Number(event.target.value);
      setArrivalTime(p => moment(p)
        .local()
        .minutes(minutes)
        .utc()
      );
    },
    [setArrivalTime]
  );

  const hourItems = useMemo(
    ()  => {
      const h = [];
      const min = moment().hours();
      for (let i = min; i < 24; i++) {
        h.push({
          value: i,
          display: formatHours(i, language),
        });
      }
      return h;
    },
    [language]
  );

  const currentHours = useMemo(() => getHours(arrivalTime), [arrivalTime]);

  const minuteItems = useMemo(
    () => {
      const h = moment().local().hour();
      const min = currentHours <= h
        ? getMinutes(moment())
        : 0;

      const m = [];
      for (let i = min; i < 60; i += 10) {
        m.push(formatMinutes(i));
      }
      return m;
    },
    [currentHours]
  );

  const currentMinutes = useMemo(
    () => formatMinutes(getMinutes(arrivalTime)),
    [arrivalTime]
  );

  useEffect(
    () => {
      if (!_.includes(minuteItems, currentMinutes)) {
        onMinutesChange({
          target: {
            value: _.first(minuteItems),
          }
        });
      }
    },
    [currentMinutes, minuteItems, onMinutesChange]
  );

  if (!arrivalTimeEnabled) {
    return null;
  }

  return (
    <Grid item container direction="column">
      <Typography variant="h5" color="primary">{arrivalTimeLabel}</Typography>

      <Grid container justifyContent='center'>
        <Grid item sm={1}>
          <Select
            variant="outlined"
            name="hours"
            value={currentHours}
            onChange={onHoursChange}
          >
            {
              _.map(hourItems, ({ value, display }) => <MenuItem key={value} value={value}>{display}</MenuItem>)
            }
          </Select>
        </Grid>

        <Grid item sm={1}>
          <Select
            variant="outlined"
            name="minutes"
            value={currentMinutes}
            onChange={onMinutesChange}
          >
            {
              _.map(minuteItems, m => <MenuItem key={m} value={m}>{m}</MenuItem>)
            }
          </Select>
        </Grid>
      </Grid>

    </Grid>
  );
}
