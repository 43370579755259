import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Grid from '@material-ui/core/Grid';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { ChevronLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React, { useCallback, useState } from "react";
import { strings } from "../localizedStrings.js";
import {questionAnswers} from "../util/testResults";
import {useCustomization} from "../customization/Customization";
import ReactHtmlParser from 'react-html-parser';
import { CircularProgress, TextField } from "@material-ui/core";
import useOnline from "../util/useOnline.js";
import moment from "moment";

const _ = require('lodash');

const useStyles = makeStyles(() => ({
  reviewSection: {
    height: 'calc(100vh - 129px)',    // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    height: 'calc(var(--vh, 1vh) * 100 - 129px)',
    position: "relative",
    left: "0px",
    top:"0px",
    overflowY:"scroll"
  },
  headerText: {
    textAlign: "center",
  },
  subheaderText: {
    textAlign: "center",
    display: "block",
  },
  questionText: {
    fontFamily: "'Roboto', 'Helvetica', 'Arial', 'sans-serif'",
    // p tags add margin by default. This removes them if there are paragraphs at the beginning or the end
    "& p:first-child": {
      marginTop:0
    },
    "& p:last-child": {
      marginBottom:0
    }
  },
  answerButton: {
    textTransform: "none",
    padding: "4px 4px",
    minWidth: "40px",
    float:"right"
  },
  bottomAnchored: {
    textAlign: "center",
    position:"relative",
    bottom: "0px",
    height: "60x",
    width: "100%",
    marginBottom: "10px",
  },
  textInput: {
    width: "100%"
  },
  nextButton: {
    marginTop:"10px",
    marginBottom:"15px",
    width: "25vw",
    minHeight: "3vh",
    maxHeight: "50px",
    maxWidth: "150px",
    textTransform: "none",
    color: "white",

    "&.Mui-disabled" : {
      color: "#939398",
      backgroundColor: "#E6E6E6",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#939398",
    },
  },
  noInternet: {
    color: 'red',
    marginBottom: "-12px"
  },
}));

export default function ReviewPage(props) {
  const classes = useStyles();
  const [answers, setAnswers] = React.useState(props.answers);
  const [submitting, setSubmitting] = useState(false);
  const online = useOnline();

  strings.setLanguage(props.language);

  const config = useCustomization(props.language).surveyConfig;

  const qFlow = config.surveyFlow;
  const reviewEditable = config.reviewEditable;

  const handleCheckItem = (event) => {
    if (reviewEditable) {
      let newAnswers = { ...answers };

      let indexes = event.target.name.match(/\d+/g);
      // Should get 2 numbers, position 0 is the question, position 1 is the check item
      if (indexes.length === 2) {
        if (event.target.checked) {
          newAnswers[indexes[0]][indexes[1]] = questionAnswers.YES;
        } else {
          newAnswers[indexes[0]][indexes[1]] = questionAnswers.NO;
        }
      }

      setAnswers(newAnswers);
    }
  };

  const handleTextInput = useCallback((event) => {
    if (reviewEditable) {
      let newAnswers = { ...answers };
      const index = parseInt(event.target.name);
      newAnswers[index] = [ event.target.value ];
      setAnswers(newAnswers);
    }
  }, [ answers ]);

  const formatAnswer = useCallback((format, value) => {
    switch (format) {
      case "date":
        return value.format("YYYY-MM-DD");
      default:
        return value;
    }
  }, []);

  // Return a list of indexes of questions to display.
  const findQuestionsToDisplay = () => {
    let res=[];
    _.map(answers, (a, index) => {
      // Only display questions that has been answered
      if ((a[0] !== questionAnswers.N_A) && (a[0] !== questionAnswers.N_A_STRING)) {
        res.push(index);
      }
    });
    return res;
  };

  const stripStyles = htmlString => {
    htmlString = htmlString.replace(/style=('|")[^('|")]*/g, ""); // Remove all inline styles
    htmlString = htmlString.replace(/(<(b|strong|em|u)>)|(<(\/b|\/strong|\/em|\/u)>)/g, ""); // Remove styling tags (<b>, <strong>, <em>, <u>).
    // Just... don't put any attribute inside those tags... The TextEditor will not, but it might happen in manual customizations.
    return htmlString;
  };

  const handleReviewDone = useCallback(
    () => {
      setSubmitting(true);
      props.onReviewDone(answers, () => setSubmitting(false));
    },
    [props.onReviewDone, answers]
  );

  return (
    <Container style={{padding:"0px"}}>
      <div style={{textAlign: "center"}} >
        <ChevronLeft color="primary" style={{display: "inline-block", float:"left", width:"32px", height:"32px" }}
          onClick={() => {props.onBackScreen("reviewPage");}}>
        </ChevronLeft>
        <Typography variant='h5' color="primary" className={classes.headerText} style={{display: "inline-block", marginLeft:"-32px",}}>
          {strings.symptomReview}
        </Typography>
        {
          config.reviewPageSubHeading &&
          <>
            <Typography
              variant='h5'
              color="primary"
              className={classes.subheaderText}
            >
              {config.reviewPageSubHeading}
            </Typography>
          </>
        }
      </div>

      <Box className={classes.reviewSection} >
        <List className={classes.list}>
          {findQuestionsToDisplay(qFlow).map((index, i) => (
            <React.Fragment key={i}>
              <ListItem style={(i % 2) ? {backgroundColor:"#FFFFFF"}:{backgroundColor:"#F0F0F0"} }>

                {/* Yes/No questions */}
                {( qFlow[index].questionType === "YesNo") &&
                  <Grid container direction='row' >
                    <Grid item xs={10}>
                      <div className={classes.questionText}>
                        <Typography variant="h6">
                          {ReactHtmlParser(stripStyles(qFlow[index].questionText))}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={2}>
                      <Button
                        variant="contained"
                        color="primary"
                        className={classes.answerButton}
                        data-cy={`button-answer-${i}`}
                        onClick={() => {
                          if (reviewEditable) {
                            // Switch the answer
                            let a = { ...answers };
                            a[index][0] = (a[index][0]===questionAnswers.YES) ? questionAnswers.NO : questionAnswers.YES;
                            setAnswers(a);
                          }
                        }}
                      >
                        {(answers[index][0] === questionAnswers.YES)? (qFlow[index].questionYesString || strings.yes) : (qFlow[index].questionNoString || strings.no)}
                      </Button>
                    </Grid>
                  </Grid>
                }
                {/* Checklist questions */}
                {( qFlow[index].questionType === "CheckList") && (answers[index][0] !== questionAnswers.N_A) &&
                  <Grid container direction='row' >
                    <Grid item xs={12}>
                      <div className={classes.questionText}>
                        <Typography variant="h6">
                          {ReactHtmlParser(stripStyles(qFlow[index].questionText))}
                        </Typography>

                      </div>
                    </Grid>
                    { qFlow[index].questionChecklist.map((symptom, aIndex) => (
                      <React.Fragment key={aIndex}>
                        <Grid item xs={12}>
                          <FormControlLabel style={{textAlign:"left", marginLeft:"4px"}}
                            control={
                              <Checkbox
                                color="primary"
                                checked={answers[index][aIndex] === questionAnswers.YES}
                                onChange={handleCheckItem} name={index.toString()+"_"+aIndex.toString()}
                              />
                            }
                            label={
                              <Typography variant='body2'>
                                {symptom}
                              </Typography>
                            }
                          />
                        </Grid>
                      </React.Fragment>
                    ))
                    }
                  </Grid>
                }

                {/* Weighted Checklist questions */}
                {( qFlow[index].questionType === "WeightedChecklist") && (answers[index][0] !== questionAnswers.N_A) &&
                  <Grid container direction='row' >
                    <Grid item xs={12}>
                      <div className={classes.questionText}>
                        <Typography variant="h6">
                          {ReactHtmlParser(stripStyles(qFlow[index].questionText))}
                        </Typography>
                      </div>
                    </Grid>
                    { qFlow[index].questionWeightedChecklist.map((itemObj, aIndex) => (
                      <React.Fragment key={aIndex}>
                        <Grid item xs={12}>
                          <FormControlLabel style={{textAlign:"left", marginLeft:"4px"}}
                            control={
                              <Checkbox
                                color="primary"
                                checked={answers[index][aIndex] === questionAnswers.YES}
                                onChange={handleCheckItem}
                                name={index.toString()+"_"+aIndex.toString()}
                              />
                            }
                            label={
                              <Typography variant='body2'>
                                {itemObj.itemText}
                              </Typography>
                            }
                          />
                        </Grid>
                      </React.Fragment>
                    ))
                    }
                  </Grid>
                }

                {
                  qFlow[index].questionType === "TextInput" &&
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      <div className={classes.questionText}>
                        <Typography variant="h6">
                          {ReactHtmlParser(stripStyles(qFlow[index].questionText))}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={index.toString()}
                        className={classes.textInput}
                        multiline={!!qFlow[index].questionTextInputMultiline}
                        rows={5}
                        value={formatAnswer(qFlow[index].questionFormat, _.first(answers[index]))}
                        disabled={!reviewEditable}
                        onChange={handleTextInput}
                        inputProps={{ maxLength: 256 }}
                      />
                    </Grid>
                  </Grid>
                }

                {
                  qFlow[index].questionType === "DateInput" &&
                  <Grid container direction='column'>
                    <Grid item xs={12}>
                      <div className={classes.questionText}>
                        <Typography variant="h6">
                          {ReactHtmlParser(stripStyles(qFlow[index].questionText))}
                        </Typography>
                      </div>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        name={index.toString()}
                        className={classes.textInput}
                        multiline={!!qFlow[index].questionTextInputMultiline}
                        rows={5}
                        value={moment(_.first(answers[index])).format("YYYY-MM-DD")}
                        disabled={!reviewEditable}
                        onChange={handleTextInput}
                        inputProps={{ maxLength: 256 }}
                      />
                    </Grid>
                  </Grid>
                }

              </ListItem >
            </React.Fragment>
          ))
          }
        </List>
      </Box>

      <Box className={classes.bottomAnchored}>
        {
          !online &&
          <Typography variant="h5" className={classes.noInternet}>{strings.offline}</Typography>
        }
        <Button variant="contained" color="primary"
          disabled={submitting || !online}
          onClick={handleReviewDone}
          className={classes.nextButton}>
          {
            config.showAcknowledgePage === false
              ? submitting
                ? <CircularProgress size="24px" style={{ color: "white" }} />
                : strings.submit
              : strings.next
          }
        </Button>
      </Box>
    </Container>
  );
}
