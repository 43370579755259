import React, { useState, useCallback } from "react";

import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Popover from "@material-ui/core/Popover";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";

import { makeStyles } from '@material-ui/core/styles';
import { strings } from "../localizedStrings.js";
import { useCustomization } from "../customization/Customization.js";
import ReactHtmlParser from 'react-html-parser';

import HealthDefinesUsDialog from '../components/HealthDefinesUsDialog';
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog.js";

import 'moment/locale/fr';
import 'moment/locale/es';

import _ from 'lodash';
import { generateName } from "healthq-shared-library/src/utilities/generateName";
import { Box, Grid } from "@material-ui/core";

const screenResults = require('../util/screenResults');
const moment = require('moment');

const useStyles = makeStyles(() => ({
  topContainer: {
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
    padding: "0px",
    minHeight: "100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  resultDiv: {
    minHeight: 'calc(100vh - 195px)',    // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: 'calc(var(--vh, 1vh) * 100 - 195px)',
    flexGrow: "1",
    display: "flex",
    flexDirection: "column",
  },
  gradient: {
    height: "30px"
  },
  logo: {
    boxSizing: "border-box",
    height: "inherit",
    padding: '10px 20px',
    maxWidth: '100%',
    objectFit: "contain"
  },
  maclogo: {
    cursor: 'pointer',
    width: '70%',
    maxWidth: '350px'
  },
  CenteredButton: {
    marginTop: "10px",
    marginBottom: "15px",
    backgroundColor: "white",
    textTransform: "none",
    minWidth: "256px",
    border: "2px, #206002",
    boxShadow: "0px 8px 12px 0px rgb(0 0 0 / 50%)",
  },
  infoLogo: {
    marginLeft: '10px',
    marginTop: '15px',
    height: '24px',
    width: '39px'
  },
  divider: {
    backgroundColor: "white",
    marginTop: "10px",
    marginBottom: "10px",
    width: "50%",
    alignSelf: "center"
  },
  bottomCentered: {
    height: "60px",
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
  },
  textContainer: {
    color: "white",
    margin: "15px 16px",
    textAlign: "left",
    "& ul, ol": {
      paddingInlineStart: "1.5em"
    },
    "& p": {
      margin: 0,
    },
  },
  consentDeniedText: {
    color: "white",
    margin: "0px 16px 0px 16px",
    textAlign: "left",
  }
}));

export default function ResultPage(props) {
  const classes = useStyles();
  // Need to reformat timestamp in the proper language locale
  moment.locale(props.language);
  const day = moment(props.timestamp.format()).format("dddd");
  const datestamp = moment(props.timestamp.format()).format("LL");
  const timestamp = moment(props.timestamp.format()).format("H:mm:ss");

  const config = useCustomization(props.language).surveyConfig;

  const redBackground = { background: "#A3002B" };
  const greenBackground = { background: "#299400" };
  const yellowBackground = { background: "#DDBB00" };
  const redGradient = { background: "linear-gradient(#74001f, #A3002B)" };
  const greenGradient = { background: "linear-gradient(#1c6300, #299400)" };
  const yellowGradient = { background: "linear-gradient(#9f8300, #DDBB00)" };
  const redText = { color: "#A3002B" };
  const greenText = { color: "#206002" };
  const yellowText = { color: "#7F6B00" };
  const darkGreenInfoBackground = { background: "#206002" };
  const darkYellowInfoBackground = { background: "#7F6B00" };
  const darkRedInfoBackground = { background: "#A3002B" }; // currently same as normal red

  // For the 'Health defines us' dialog
  const [isHealthDefinesUsDialogOpen, setIsHealthDefinesUsDialogOpen] = useState(false);
  const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false);

  // For popup for additional information
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const handlePopoverOpen = event => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };


  strings.setLanguage(props.language);

  let background = greenBackground;
  let textColor = greenText;
  let gradient = greenGradient;
  let infoBackground = darkGreenInfoBackground;
  if (props.result.rawResultCode >= screenResults.FAIL) {
    background = redBackground;
    textColor = redText;
    gradient = redGradient;
    infoBackground = darkRedInfoBackground;
  } else if (props.result.rawResultCode >= screenResults.PASS_RESTRICTION) {
    background = yellowBackground;
    textColor = yellowText;
    gradient = yellowGradient;
    infoBackground = darkYellowInfoBackground;
  }

  const exitMessage = _.find(config.exitMessages, (obj) => obj.code === props.result.rawResultCode);
  background.background = _.get(exitMessage, "background", background.background);
  textColor.color = _.get(exitMessage, "text", textColor.color);
  gradient.background = _.get(exitMessage, "gradient", gradient.background);
  infoBackground.background = _.get(exitMessage, "infoBackground", infoBackground.background);

  function getResultScreenTitle() {
    if (props.title) {
      return props.title;
    }

    let resultScreenTitle = strings.pass;
    if ((props.result.rawResultCode < screenResults.FAIL) && (props.result.rawResultCode >= screenResults.PASS_RESTRICTION)) {
      resultScreenTitle = strings.passRestriction;
    } else if (props.result.rawResultCode >= screenResults.FAIL) {
      resultScreenTitle =  strings.fail;
    }

    const exitMessage = _.find(config.exitMessages, (obj) => obj.code === props.result.rawResultCode);
    resultScreenTitle = _.get(exitMessage, "title", resultScreenTitle);

    return resultScreenTitle;
  }

  const handleCloseHealthDefinesUsDialog = useCallback(() => {
    setIsHealthDefinesUsDialogOpen(false);
  }, []);

  const handleOpenPrivacyPolicyDialog = useCallback(() => {
    setIsPrivacyPolicyDialogOpen(true);
  });

  const handleClosePrivacyPolicyDialog = useCallback(() => {
    setIsPrivacyPolicyDialogOpen(false);
  }, []);

  const configureTypesLabelText = () => {
    if (config.customerName === "The Ottawa Hospital") {
      return "";
    }
    return props.userInfoConfiguration.typesLabel[0] + ": ";
  };

  const configureTypeFontSize = () => {
    if (config.customerName === "The Ottawa Hospital") {
      return "1.6rem";
    } else {
      return "1.25rem";
    }
  };

  return (
    <Container className={classes.topContainer}>
      <PrivacyPolicyDialog
        open={isPrivacyPolicyDialogOpen}
        handleClosePrivacyPolicyDialog={handleClosePrivacyPolicyDialog}
        strings={strings}>
      </PrivacyPolicyDialog>

      <HealthDefinesUsDialog
        open={isHealthDefinesUsDialogOpen}
        handleCloseHealthDefinesUsDialog={handleCloseHealthDefinesUsDialog}
        openPrivacyPolicyDialog={handleOpenPrivacyPolicyDialog}
        strings={strings}>
      </HealthDefinesUsDialog>

      <div style={{ height: "100px" }}>
        <img src={props.logoUrl} alt="Client Logo" className={classes.logo} />
      </div>
      <div className={classes.gradient} style={gradient} />
      <div className={classes.resultDiv} style={background} >

        <div style={{ display: "flex", flexGrow: "1", flexDirection: "column" }}>

          <Grid container justifyContent="space-between" wrap="nowrap">

            <Grid item>
              <Box width={49}>

              </Box>
            </Grid>
            <Grid item>

              <div>
                <Typography variant="h3" style={{ fontStyle: "oblique", fontWeight: "700", color: "white" }} >
                  {getResultScreenTitle()}
                </Typography>
              </div>

            </Grid>

            <Grid item>
              {
                !props.hideMailIcon &&
            <img src="/MailInfo.svg" alt="More Information" onClick={handlePopoverOpen}
              className={classes.infoLogo} />
              }

              <Popover id={id}
                className={classes.popover}
                // classes={{paper: classes.paper}}
                open={open}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
                transformOrigin={{ vertical: "top", horizontal: "left" }}
                onClose={handlePopoverClose}
                disableRestoreFocus >

                <Typography style={{ margin: "10px 10px 10px 10px" }}>
                  {strings.emailNote.replace("<orgEmail>", config.orgEmail)}
                </Typography>
              </Popover>
            </Grid>

          </Grid>

          <div className={classes.textContainer}>
            {ReactHtmlParser(props.result.resultMsg)}
          </div>

          {
            props.result.alternateDeniedText &&
            <Typography className={classes.consentDeniedText}>
              {props.result.alternateDeniedText}
            </Typography>
          }

          {props.result.rawResultCode < screenResults.FAIL &&
          <>
            <div style={{ display: "flex", flexDirection: "column", ...infoBackground, borderRadius: "5px", margin: "0px 10px 0px 10px", padding: "10px 0px 10px 0px" }}>
              <Typography variant="h4" style={{ color: "white" }} >
                {generateName(props.userInfo.firstName, props.userInfo.lastName, config.hideLastName) }
              </Typography>

              {props.userInfoConfiguration.showTypeOnResultScreen &&
                <Typography
                  variant="h6"
                  style={{ color: "white", fontSize: configureTypeFontSize() }}
                >
                  {configureTypesLabelText() + props.userInfo.type}
                </Typography>
              }

              {props.userInfoConfiguration.showLocationOnResultScreen &&
                <Typography variant="h6" style={{ color: "white" }} >
                  {(props.userInfoConfiguration.locationsLabel || strings.location) + ": " + props.userInfo.location}
                </Typography>
              }

              <Divider className={classes.divider} />

              <Typography variant="h4" style={{ color: "white" }} >
                {day}
              </Typography>

              <Typography variant="h4" style={{ color: "white" }} >
                {datestamp}
              </Typography>

              <Typography variant="h4" style={{ color: "white" }} >
                {timestamp}
              </Typography>
            </div>
            {exitMessage.followUpMessage &&
                <div className={classes.textContainer}>
                  <Typography>
                    {ReactHtmlParser(exitMessage.followUpMessage)}
                  </Typography>
                </div>
            }
          </>
          }
        </div>

        {
          props.onDone &&
          <div className={classes.bottomCentered}>
            <Button variant="contained"
              onClick={() => { props.onDone(); }}
              style={textColor}
              className={classes.CenteredButton} >
              {strings.done}
            </Button>
          </div>
        }

        {
          props.onContinue &&
          <div className={classes.bottomCentered}>
            <Button variant="contained"
              onClick={() => { props.onContinue(); }}
              style={textColor}
              className={classes.CenteredButton} >
              {strings.continue}
            </Button>
          </div>
        }
      </div>

      <p>
        {strings.getLanguage() === "fr"
          ? <img src='/mac-HealthDefinesUs-fr.svg' alt="Macadamian Logo" className={classes.maclogo} onClick={() => setIsHealthDefinesUsDialogOpen(true)} />
          : <img src='/mac-HealthDefinesUs-en.svg' alt="Macadamian Logo" className={classes.maclogo} onClick={() => setIsHealthDefinesUsDialogOpen(true)} />
        }
      </p>

    </Container>

  );
}

