import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { Box, Button, Container, Grid, Typography } from '@material-ui/core';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import _ from 'lodash';

import { strings } from '../../localizedStrings.js';
import { DataContext } from '../../contexts/DataContext.js';

import { Reason } from './Reason.js';
import { Unit } from './Unit.js';
import { Visiting } from './Visiting.js';
import { ArrivalTime } from './ArrivalTime.js';

import moment from 'moment';
import { SurveyService } from '../../services/SurveyService.js';
import { getKioskableValue } from '../../util/getKioskableValue.js';
import { FontSizeSelector } from '../../components/FontSizeSelector.js';

export default function VisitInformation({
  language,
  onBackScreen,
  userInfo = {},
  onSetUserInfo,
  onRepeatSurvey,
  onLocationOrUnitOutbreak,
  isKiosk = false,
}) {
  const { surveyConfig } = useContext(DataContext);
  const visitInformation = _.get(surveyConfig, 'visitInformation');

  const [reason, setReason] = useState(userInfo.reason || "");
  const [unit, setUnit] = useState("");
  const [needsVisiting, setNeedsVisiting] = useState(true);
  const [visiting, setVisiting] = useState(userInfo.visiting || "");
  const [arrivalTime, setArrivalTime] = useState(() => moment(userInfo.arrivalTime));

  const reasonEnabled = getKioskableValue(isKiosk, visitInformation, "reason");
  const unitEnabled = getKioskableValue(isKiosk, visitInformation, "unit");
  const visitingEnabled = getKioskableValue(isKiosk, visitInformation, "visiting");
  const visitingTextRequiredList = _.get(visitInformation, 'visitingTextRequiredList', []);
  const arrivalTimeEnabled = getKioskableValue(isKiosk, visitInformation, "arrivalTime");

  const displayFontSizeSelector = isKiosk ?
    _.get(surveyConfig, "showFontSizeSelectorKiosk", false)
    :
    _.get(surveyConfig, "showFontSizeSelectorMobile", false);

  const title = _.get(visitInformation, 'title')
    || "TODO: title";

  const [ proceeding, setProceeding ] = useState(false);
  const [visitingPromptExpected, setVisitingPromptExpected] = useState(true);

  useEffect(() => {
    const visitingTextExpectedForRole = visitingTextRequiredList.includes(reason);
    setVisitingPromptExpected(visitingEnabled && needsVisiting && visitingTextExpectedForRole);
  }, [reason, visitingTextRequiredList, needsVisiting, visitingEnabled]);

  const onNextClicked = useCallback(
    () => {
      const newUserInfo = {
        ...userInfo,
        reason,
        visiting,
        arrivalTime,
        unit,
      };

      const typesList = _.get(surveyConfig, "userInfo.typesList", []);
      if (reasonEnabled && typesList.length) {
        newUserInfo.type = reason;
      }

      setProceeding(true);
      SurveyService.checkUserInfo(
        language,
        newUserInfo,
        onRepeatSurvey,
        onLocationOrUnitOutbreak,
        {
          checkCanProceed: false,
          checkLocationOrUnit: _.get(surveyConfig, "visitInformation.capacity.enabled", false) && unitEnabled,
        }
      )
        .then(() => {
          onSetUserInfo(newUserInfo)
            .catch(console.error);
        })
        .catch(() => {
          // Nothing to do here, just need to catch it
        })
        .finally(() => setProceeding(false));
    },
    [
      onSetUserInfo,
      language,
      userInfo,
      reason,
      visiting,
      visitingPromptExpected,
      arrivalTime,
      unit,
      onRepeatSurvey,
      onLocationOrUnitOutbreak,
    ]);

  const nextDisabled = useMemo(
    () => {
      if (proceeding) {
        return true;
      }
      if (reasonEnabled && !reason) {
        return true;
      }

      if (unitEnabled && !unit) {
        return true;
      }

      if (visitingPromptExpected && !visiting) {
        return true;
      }

      if (arrivalTimeEnabled) {
        if (!arrivalTime || !moment(arrivalTime).isValid()) {
          return true;
        }
      }

      return false;
    },
    [
      proceeding,
      reasonEnabled,
      reason,
      visitingEnabled,
      visitingPromptExpected,
      visiting,
      unitEnabled,
      unit,
      arrivalTimeEnabled,
      arrivalTime,
      isKiosk,
    ]
  );

  return (
    <Container className="visit-information">
      <Box padding={1} display="flex" flex={1} width="100%">
        <Grid container item xs direction='column' spacing={2}>
          <Grid item>
            <Box height={60} display="flex">
              <Grid container alignItems='center' justifyContent="space-between">
                <Grid item>
                  <Box width={84}>
                    <ChevronLeft
                      color="primary"
                      onClick={() => onBackScreen("userInfoPage")}
                    />
                  </Box>
                </Grid>
                <Grid item xs>
                  <Typography variant='h5' color="primary" align="center">
                    {title}
                  </Typography>
                </Grid>
                <Grid item>
                  <Box width={84}>
                    <FontSizeSelector
                      variant="menu"
                      display={displayFontSizeSelector}
                    />
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Grid>
          <Grid item xs>
            <Grid container direction='column' spacing={2}>
              <Reason
                reason={reason}
                setReason={setReason}
                isKiosk={isKiosk}
              />
              <Unit
                locationId={userInfo.location}
                unit={unit}
                setUnit={setUnit}
                setNeedsVisiting={setNeedsVisiting}
                isKiosk={isKiosk}
                alwaysDisplay
              />
              { visitingPromptExpected &&
                <Visiting
                  visiting={visiting}
                  setVisiting={setVisiting}
                  needsVisiting={needsVisiting}
                  isKiosk={isKiosk}
                />
              }
              <ArrivalTime
                arrivalTime={arrivalTime}
                setArrivalTime={setArrivalTime}
                isKiosk={isKiosk}
              />
            </Grid>

          </Grid>
          <Grid item>
            <Box height={60} display="flex">
              <Grid container justifyContent="center" alignItems='center' className="buttons">
                <Grid item>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={onNextClicked}
                    disabled={nextDisabled}
                  >
                    {strings.next}
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Container>
  );
}
