const styleOverrides = {
  root: {
    fontSize: "1rem",
    textTransform: "none",
    '&.MuiButton-containedSizeLarge': {
      fontSize: "1.5rem",
    },
  },
  endIcon: {
    marginLeft: "0px"
  }
};

export const MuiButton = {
  ...styleOverrides,
};
