const styleOverrides = {
  root: {
    '&.visit-information': {
      display: "flex",
      flexDirection: "column",
      minHeight: "100vh",
      // fallback for browsers that do not support custom properties
      // eslint-disable-next-line no-dupe-keys
      minHeight: "calc(var(--vh, 1vh) * 100)"
    },
  },
};

export const MuiContainer = {
  ...styleOverrides,
};
