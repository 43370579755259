import Container from "@material-ui/core/Container";
import React from "react";
import TemperatureCheck from "./TemperatureCheck";
import TemperatureEnter from "./TemperatureEnter";



export default function TemperaturePage(props) {
  const [enterTemperature, setEnterTemperature] = React.useState(false);

  const onOk = () => {
    setEnterTemperature(true);
  };

  const onTemperatureSet = (temp) => {
    props.onTemperatureSet(temp);
  };

  return (
    <Container style={{padding:"0px"}}>
      {!enterTemperature && <div>
        <TemperatureCheck
          language={props.language}
          onOk={onOk}
        />
      </div>}

      {enterTemperature && <div>
        <TemperatureEnter
          language={props.language}
          onTemperatureSet={onTemperatureSet}
        />
      </div>}

    </Container>
  );
}
