import React from 'react';
import './App.css';
import Loader from './Loader';
import { CookiesProvider } from 'react-cookie';
import { HiveProvider } from "./services/HiveContext";
import ErrorBoundary from './ErrorBoundary';
import { DataContextProvider } from './contexts/DataContext';

function App() {

  // Trick, to make sure we have the correct VH (viewport) on mobile phone when they show header and footer bar.
  // Reference: https://css-tricks.com/the-trick-to-viewport-units-on-mobile/

  // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
  let vh = window.innerHeight * 0.01;
  // Then we set the value in the --vh custom property to the root of the document
  document.documentElement.style.setProperty('--vh', `${vh}px`);

  // We listen to the resize event
  window.addEventListener('resize', () => {
    // We execute the same script as before
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  });


  return (
    <ErrorBoundary>
      <HiveProvider>
        <div className="App">
          <CookiesProvider>
            <DataContextProvider>
              <Loader />
            </DataContextProvider>
          </CookiesProvider>
        </div>
      </HiveProvider>
    </ErrorBoundary>
  );
}

export default App;
