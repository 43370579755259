import React, { useCallback, useEffect, useState } from "react";
import Container from "@material-ui/core/Container";
import Typography from "@material-ui/core/Typography";
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import IconButton from '@material-ui/core/IconButton';
import ArrowForward from '@material-ui/icons/ArrowForward';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useObservableState } from '../util/hooks';
import { watchGpsLocation, updateGpsLocationNow, usersGpsLocationHasBeenDetermined } from '../services/LocationService';
import SearchLocation from "../components/SearchLocation";
import { strings } from "../localizedStrings";

const DEFAULT_SEARCH_RADIUS = 5000;

const useStyles = makeStyles(() => ({
  headerText: {
    fontWeight: "700",
    display: "inline-block",
    marginLeft: "-32px"
  },
  labelText: {
    fontWeight: "400",
    marginTop: "5px",
    marginBottom: "0px"
  },
  bottomCentered: {
    textAlign: "center",
    height: "60px",
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
  },
  topContainer: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  list: {
    border: "solid",
    borderWidth: "2px",
    borderRadius: "5px"
  },
  logo: {
    boxSizing: "border-box",
    height: "inherit",
    padding: '10px 20px',
    maxWidth: '100%',
    objectFit: "contain"
  },
}));

const ForwardButton = withStyles((theme) => ({
  label: {
    background: theme.palette.primary.main + '!important',
    height: '35px !important',
    width: '35px !important',
    borderRadius: '25%'
  },
}))(IconButton);

const degToRad = (deg) => {
  return deg * (Math.PI / 180);
};

const distanceBetween = (lhsCoords, rhsCoords) => {
  // Haversine formula, based on https://stackoverflow.com/questions/639695/how-to-convert-latitude-or-longitude-to-meters
  var earthRadius = 6371;
  var dLat = degToRad(rhsCoords.latitude - lhsCoords.latitude);
  var dLon = degToRad(rhsCoords.longitude - lhsCoords.longitude);
  var a = Math.sin(dLat / 2) * Math.sin(dLat / 2) + Math.cos(degToRad(lhsCoords.latitude)) * Math.cos(degToRad(rhsCoords.latitude)) * Math.sin(dLon / 2) * Math.sin(dLon / 2);
  var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  var d = earthRadius * c; // Distance in km
  return d * 1000; // returns distance in m
};

export default function NearMe(props) {

  const classes = useStyles();
  const gpsPosition = useObservableState(watchGpsLocation());

  const [visibleLocations, setVisibleLocations] = useState([]);
  const [searchRadius, updateSearchRadius] = useState(DEFAULT_SEARCH_RADIUS);
  const [searchBarText, updateSearchText] = useState("");

  const [showPage, setShowPage] = useState(false);

  const startToUpdateGpsLocation = useCallback(() => {
    updateGpsLocationNow();
  }, []);

  const compareDistanceFromUser = (lhs, rhs) => {
    let lhsDistance = distanceBetween(lhs.coordinates, gpsPosition);
    let rhsDistance = distanceBetween(rhs.coordinates, gpsPosition);

    return lhsDistance - rhsDistance;
  };

  useEffect(() => {
    startToUpdateGpsLocation();
  }, [startToUpdateGpsLocation]);

  useEffect(() => {
    updateList();
  }, [gpsPosition]);

  useEffect(() => {
    if (gpsPosition && gpsPosition.latitude !== 0 || gpsPosition.longitude !== 0) {
      setShowPage(true);
    }
  }, [gpsPosition]);

  useEffect(() => {
    navigator.permissions.query({ name: 'geolocation' }).then((status) => {
      if (status.state === "denied") {
        props.onSelectLocation(null, true);
      }
    });
  }, []);

  const receiveSearchKeystrokes = useCallback((event) => {
    updateGpsLocationNow();
    updateSearchText(event.target.value);
    updateList();
  }, []);

  const setSearchRadius = useCallback((event, value) => {
    updateGpsLocationNow();
    // Minimum search radius is 100m
    if (value <= 100) {
      updateSearchRadius(100);
    } else {
      updateSearchRadius(value);
    }
    updateList();
  }, [updateSearchRadius]);

  const updateList = () => {
    if (usersGpsLocationHasBeenDetermined() && props.showFilter) {
      let sorted = props.locations.sort(compareDistanceFromUser);
      let searchFiltered = sorted.filter(location => location.location.toLowerCase().includes(searchBarText.toLowerCase()));

      // unfinished filter by distance from user
      let distanceFiltered = searchFiltered.filter(location => {
        return distanceBetween(location.coordinates, gpsPosition) <= searchRadius;
      });
      setVisibleLocations(distanceFiltered);
    } else {
      let searchFiltered = props.locations.filter(location => location.location.toLowerCase().includes(searchBarText.toLowerCase()));
      setVisibleLocations(searchFiltered);
    }
  };

  if (!showPage) {
    return (
      <Container className={classes.topContainer}>
        <p>{strings.loading}</p>
      </Container>
    );
  }

  return (
    <Container className={classes.topContainer}>
      <div style={{ marginTop: "20px", marginBottom: "10px", textAlign: "center" }} >
        {/* Logo container */}
        <div style={{ height: "100px" }}>
          <img src={props.logoUrl} alt="Client Logo" className={classes.logo} />
        </div>
      </div>

      <Typography variant="h5" color="primary">
        {strings.locations}
      </Typography>

      <List className={classes.list} justify="center">
        {visibleLocations.length === 0 ? <ListItem style={{ justifyContent: 'space-between' }}>
          <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">{strings.noLocationsInRange}</Typography>
        </ListItem> : null}
        {visibleLocations.map((location, index) => (
          <React.Fragment key={index}>
            <ListItem style={{ justifyContent: 'space-between' }} onClick={() => {props.onSelectLocation(location);}}>
              <Typography style={{ fontWeight: 'bold' }} variant="subtitle1">{location.location}</Typography>
              <ForwardButton aria-label="forward" className={classes.forward} size="small">
                <ArrowForward style={{ height: '30px', width: '30px', color: 'white' }} fontSize="inherit" />
              </ForwardButton>
            </ListItem>
          </React.Fragment>
        ))}
        <hr></hr>

        <SearchLocation
          handleSearchChange={receiveSearchKeystrokes}
          handleSearchRadiusChange={setSearchRadius}
          locationAllowed={(gpsPosition) && (gpsPosition.longitude !== 0) && (gpsPosition.latitude !== 0)}
          currentSearchRadius={searchRadius}
          showFilter={props.showFilter}
        />
      </List>
    </Container>
  );
}