import { Box, Button, Container, Divider, Grid, makeStyles, Typography } from "@material-ui/core";
import { ChevronLeft } from "@material-ui/icons";
import React, { useCallback, useState } from "react";
import { useCustomization } from "../customization/Customization";
import { strings } from "../localizedStrings";
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(() => ({
  topContainer: {
    display:"flex",
    flexDirection:"column",
    minHeight:"100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  headerContainer: {
    marginTop:"20px",
    marginBottom:"10px"
  },
  chevronLeft: {
    float:"left",
    width:"32px",
    height:"32px"
  },
  headerText: {
    textAlign: "center",
  },
  consentText: {
    flexGrow: 1,
    color: "#5A5A5A",
    // marginTop:"20px",
    "& ul, ol": {
      paddingInlineStart:"1.5em"
    },
  },
  bottomAnchored: {
    textAlign: "center",
    position:"relative",
    bottom: "20px",
    height: "60x",
    width: "100%"
  },
  contentButton: {
    textTransform: "none",
    marginTop:"10px",
    width: "100px",
  },
  buttonDivider: {
    display:"inline-block",
    width:"50px"
  }
}));

export default function ConsentPage(props) {
  const classes = useStyles();
  const config = useCustomization(props.language).surveyConfig;
  const [ consentText ] = useState(() => config.consent.text);

  const onBack = useCallback(() => {
    props.onBackScreen('consentPage');
  }, [ props.onBackScreen ]);

  const onAccepted = useCallback(() => {
    props.onAccepted();
  }, [ props.onAccepted ]);

  const onRejected = useCallback(() => {
    props.onRejected();
  }, [ props.onRejected ]);

  return (
    <Container className={classes.topContainer}>

      <Grid container className={classes.headerContainer} >
        <Grid item xs={1}>
          <ChevronLeft color="primary" className={classes.chevronLeft} onClick={onBack} />
        </Grid>
        <Grid item xs={11}>
          <Typography variant='h5' color="primary" className={classes.headerText}>
            {strings.consentTitle}
          </Typography>
        </Grid>
      </Grid>

      <Divider />

      <div className={classes.consentText}>
        <Typography>
          {ReactHtmlParser(`<p>${consentText}</p>`)}
        </Typography>
      </div>

      <Box className={classes.bottomAnchored}>
        <div>
          <Button className={classes.contentButton} variant="outlined" color="primary" onClick={onAccepted}>
            {strings.yes}
          </Button>

          <div className={classes.buttonDivider} />

          <Button className={classes.contentButton} variant="outlined" color="primary" onClick={onRejected}>
            {strings.no}
          </Button>
        </div>
      </Box>

    </Container>
  );
}