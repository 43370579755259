import React, { useState, useEffect, useCallback, useContext } from "react";
import { makeStyles } from '@material-ui/core/styles';
import Button from "@material-ui/core/Button";
import Container from "@material-ui/core/Container";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import { useCustomization } from "../customization/Customization.js";
import { calculateDisabledColor } from "../util/colors";
import ReactHtmlParser from 'react-html-parser';
import { getLanguageFromAbbreviation } from '../localizedStrings';
import HealthDefinesUsDialog from '../components/HealthDefinesUsDialog';
import PrivacyPolicyDialog from "../components/PrivacyPolicyDialog.js";
import _ from "lodash";
import { HiveContext } from "../services/HiveContext.js";
import { useKiosk } from "../hooks/useKiosk";
import { FontSizeSelector } from "../components/FontSizeSelector.js";

export default function Welcome(props) {
  const customization = useCustomization(props.language);
  const config = customization.surveyConfig;
  const { isKiosk } = useKiosk();
  const displayFontSizeSelector = isKiosk ?
    _.get(config, "showFontSizeSelectorKiosk", false)
    :
    _.get(config, "showFontSizeSelectorMobile", false);
  const supportedLanguages = config.supportedLanguages;
  const [selectedLanguage, setSelectedLanguage] = useState(props.language);
  const [isHealthDefinesUsDialogOpen, setIsHealthDefinesUsDialogOpen] = useState(false);
  const [isPrivacyPolicyDialogOpen, setIsPrivacyPolicyDialogOpen] = useState(false);
  const { orgName } = useContext(HiveContext);

  useEffect(() => {
    props.reset();
  }, [props.reset]);

  useEffect(() => {
    // It's possible that the user has never selected the language yet and it is undefined
    // in that case, select the first supported language
    let firstSupportedLanguage = config.supportedLanguages[0];
    if (!selectedLanguage) {
      setSelectedLanguage(firstSupportedLanguage);
      props.onSetLanguage(firstSupportedLanguage);
    } else { // Even though it shouldn't happen in prod, if the language stored in the cookies is no longer supported, select the first supported language
      if (config.supportedLanguages.includes(selectedLanguage)) {
        props.onSetLanguage(selectedLanguage);
      } else {
        props.onSetLanguage(firstSupportedLanguage);
      }
    }
  }, [selectedLanguage]);

  const useStyles = makeStyles(() => ({
    container: {
      display: "flex",
      flexDirection: "column",
      minHeight: 'calc(100vh - 100px)', // fallback for browsers that do not support custom properties
      // eslint-disable-next-line no-dupe-keys
      minHeight: 'calc(var(--vh, 1vh) * 100 - 100px)',
    },
    logo: {
      boxSizing: "border-box",
      height: "inherit",
      padding: '10px 20px',
      maxWidth: '100%',
      objectFit: "contain"
    },
    dialogPaper: {
      textAlign: "center",
      width: "400px",
    },
    normalText: {
      margin: "15px 10px 15px 10px"
    },
    startButton: {
      width: "50vw",
      maxWidth: "300px",
      textTransform: "none",
      color: "white",
    },
    customerText: {
      color: config.primaryColor
    },
    divider: {
      margin: "8px 10px 8px 10px",
      height: "2px",
      backgroundColor: calculateDisabledColor(config.primaryColor),
    },
    maclogo: {
      cursor: 'pointer',
      width: '70%',
      maxWidth: '350px'
    },
    languageButton: {
      width: "25vw",
      maxWidth: "200px",
      textTransform: "none"
    },
    languageButtonSelected: {
      backgroundColor: `${config.primaryColor} !important`,
      color: "white",
    },
    supportedLanguagesContainer: {
      width: "100%",
      margin: "32px 0",
    }
  }));
  const classes = useStyles();

  function isStartButtonDisabled() {
    let disabled = (props.selfServe) ? !props.configActive : false;
    // Disable the start button if disabling (override) is true
    disabled = disabled || _.get(config, "disableStartButton", false);
    // Disable the start button if no language is selected
    return (disabled || (supportedLanguages.length > 1 ? !supportedLanguages.includes(selectedLanguage) : false));
  }

  function handleSelectLanguage(language) {
    setSelectedLanguage(language);
  }

  function startSurvey() {
    // Set the language selected, the home page will display the proper next page
    if (supportedLanguages.includes(selectedLanguage)) {
      props.onSetLanguage(selectedLanguage);
    } else {
      props.onSetLanguage(supportedLanguages[0]);
    }
    props.onStartSurvey();
  }

  const handleCloseHealthDefinesUsDialog = useCallback(() => {
    setIsHealthDefinesUsDialogOpen(false);
  }, []);

  const handleOpenPrivacyPolicyDialog = useCallback(() => {
    setIsPrivacyPolicyDialogOpen(true);
  });

  const handleClosePrivacyPolicyDialog = useCallback(() => {
    setIsPrivacyPolicyDialogOpen(false);
  }, []);

  const [ disclaimerText, setDisclaimerText ] = useState();
  useEffect(
    () => {
      let welcomePageDisclaimerText = _.get(config, 'welcomePageDisclaimerText');

      if (_.isObject(welcomePageDisclaimerText)) {
        welcomePageDisclaimerText = _.get(welcomePageDisclaimerText, orgName)
          || _.get(welcomePageDisclaimerText, 'default');
      }

      setDisclaimerText(welcomePageDisclaimerText);
    },
    [ config, orgName ]
  );

  return (
    <Container style={{ textAlign: "center", padding: "0px" }} >
      {/* Logo container */}
      <div style={{ height: "100px" }}>
        <img src={props.logoUrl} alt="Client Logo" className={classes.logo} />
      </div>

      <PrivacyPolicyDialog
        open={isPrivacyPolicyDialogOpen}
        handleClosePrivacyPolicyDialog={handleClosePrivacyPolicyDialog}
        strings={props.strings}>
      </PrivacyPolicyDialog>

      <HealthDefinesUsDialog
        open={isHealthDefinesUsDialogOpen}
        handleCloseHealthDefinesUsDialog={handleCloseHealthDefinesUsDialog}
        openPrivacyPolicyDialog={handleOpenPrivacyPolicyDialog}
        strings={props.strings}>
      </HealthDefinesUsDialog>

      <Container className={classes.container}>
        {/* Title */}
        <div style={{ marginBottom: "30px" }}>
          <Typography variant="h3" color="primary">
            {config.welcomePageTitle ? ReactHtmlParser(config.welcomePageTitle) : props.strings.welcomeTitle}
          </Typography>
        </div>

        {/* Disclaimer */}
        <div className={classes.normalText}>
          <Typography variant="h5" style={{fontWeight: 400}}>
            {disclaimerText ?
              ReactHtmlParser(disclaimerText)
              :
              props.strings.welcomeDisclaimer.replace("${customerName}", config.customerName)
            }
          </Typography>
        </div>

        {/* Pushes everything below to the bottom and takes up the remaining space */}
        <div style={{ flexGrow: "1", display: "flex", flexDirection: "column", justifyContent: "center", margin: "0px 0px 36px 0px" }}>
          {/* Customer's custom text */}
          {config.welcomePageCustomerText &&
            <div className={classes.customerText}>
              <Typography variant="h5" component="p">
                {ReactHtmlParser(config.welcomePageCustomerText)}
              </Typography>
            </div>
          }

          {/* Generate as many buttons as there are supported languages.
          Can easily check the number of supported languages and render something else accordingly, like a dropdown. */}
          <Grid container direction="row" justifyContent="center" className={classes.supportedLanguagesContainer} spacing={1}>
            {supportedLanguages.length > 1 && supportedLanguages.map((sl, index) => (
              <Grid item key={index}>
                <Button variant="outlined" color="primary"
                  className={`${classes.languageButton} ${selectedLanguage === sl ? classes.languageButtonSelected : ""}`}
                  onClick={() => {
                    handleSelectLanguage(sl);
                  }}
                >
                  {getLanguageFromAbbreviation(sl)}
                </Button>
              </Grid>
            ))}
          </Grid>
          {/* Start button */}
          <Grid container direction="row" justifyContent="center">
            <Grid item>
              <Button variant="contained"
                disabled={isStartButtonDisabled()}
                color="primary"
                size="large"
                onClick={() => startSurvey()}
                className={classes.startButton}
              >
                {props.strings.welcomeStart}
              </Button>
            </Grid>
          </Grid>
        </div>

        <FontSizeSelector display={displayFontSizeSelector} />

        {/* Macadamian logo */}
        <br />
        <p>
          {props.strings.getLanguage() === "fr"
            ? <img src='/mac-HealthDefinesUs-fr.svg' alt="Macadamian Logo" className={classes.maclogo} onClick={() => setIsHealthDefinesUsDialogOpen(true)} />
            : <img src='/mac-HealthDefinesUs-en.svg' alt="Macadamian Logo" className={classes.maclogo} onClick={() => setIsHealthDefinesUsDialogOpen(true)} />
          }
        </p>

      </Container>
    </Container>
  );
}
