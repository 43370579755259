import { utils } from "hive-client-utils";
import moment from 'moment';

function enableUnitOutbreak(connection, unitId, enabled) {
  if (!connection || !connection.bee) {
    throw new Error('Need a connection!');
  }

  return connection.bee.actions.invoke('enableUnitOutbreak', unitId, enabled)
    .then(utils.waitForInvocationReaction(connection.bee))
  ;
}

function validateUserLocationAndUnit(connection, type, arrivalTime, locationId, unitId) {
  if (!connection || !connection.bee) {
    throw new Error('Need a connection!');
  }

  arrivalTime = moment(arrivalTime).utc().toISOString();
  return connection.bee.actions.invoke('validateUserLocationAndUnit', type, arrivalTime, locationId, unitId)
    .then(utils.waitForInvocationReaction(connection.bee))
  ;
}

function createOrUpdateLocationsAndUnits(connection, locations, units) {
  if (!connection || !connection.bee) {
    throw new Error('Need a connection!');
  }

  return connection.bee.actions.invoke('addLocationsAndUnitsAsAdmin', locations, units)
    .then(utils.waitForInvocationReaction(connection.bee))
  ;
}

function removeLocationAndAssociatedUnits(connection, locationId) {
  if (!connection || !connection.bee) {
    throw new Error('Need a connection!');
  }

  return connection.bee.actions.invoke('clearLocationAndUnitsById', locationId)
    .then(utils.waitForInvocationReaction(connection.bee))
  ;
}

function removeUnit(connection, uid) {
  if (!connection || !connection.bee) {
    throw new Error('Need a connection!');
  }

  return connection.bee.actions.invoke('clearUnitById', uid)
    .then(utils.waitForInvocationReaction(connection.bee))
  ;
}

export const LocationsAndUnitsService = {
  enableUnitOutbreak,
  validateUserLocationAndUnit,
  createOrUpdateLocationsAndUnits,
  removeLocationAndAssociatedUnits,
  removeUnit,
};
