import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { ChevronLeft } from '@material-ui/icons';
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import { useState, useCallback } from "react";
import { strings } from "../localizedStrings.js";
import {useCustomization} from "../customization/Customization.js";
import ReactHtmlParser from 'react-html-parser';
import useOnline from "../util/useOnline.js";
import { CircularProgress } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  topContainer: {
    textAlign: "center",
    display:"flex",
    flexDirection:"column",
    minHeight:"100vh",   // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    minHeight: "calc(var(--vh, 1vh) * 100)"
  },
  labelText: {
    color: "#5A5A5A"
  },
  noInternet: {
    color: 'red',
    marginBottom: "-12px"
  },
  bottomCentered: {
    height: "140px",
    width: "100%",
    marginBottom: "20px",
    marginTop: "10px",
  },
  submitButton: {
    textTransform: "none",
    display: "block",
    marginTop: "20px",
    marginBottom: "20px",
    marginLeft: "auto",
    marginRight: "auto",
    width: "25vw",
    minHeight: "3vh",
    maxHeight: "50px",
    maxWidth: "150px",
    color: "white",

    "&.Mui-disabled" : {
      color: "#939398",
      backgroundColor: "#E6E6E6",
      borderWidth: "1px",
      borderStyle: "solid",
      borderColor: "#939398",
    },
  },
}));

export default function AcknowledgePage(props) {
  const acknowledgeMessage = useCustomization(props.language).surveyConfig.acknowledgeMessage;
  const classes = useStyles();
  const online = useOnline();

  strings.setLanguage(props.language);

  const [submitting, setSubmitting] = useState(false);
  const handleSubmit = useCallback(
    () => {
      setSubmitting(true);
      props.onAcknowledged(() => setSubmitting(false));
    },
    [ props.onAcknowledged ]
  );

  return (
    <Container className={classes.topContainer}>
      <div style={{marginTop:"20px", marginBottom:"10px"}} >
        <ChevronLeft color="primary" style={{display: "inline-block", float:"left", width:"32px", height:"32px" }}
          onClick={() => {props.onBackScreen("acknowledgePage");}}>
        </ChevronLeft>
        <Typography variant='h5' color="primary" style={{display: "inline-block", marginLeft:"-32px"}}>
          {strings.declaration}
        </Typography>
      </div>
      <Typography variant='h5' className={classes.questionText} color="textPrimary" style={{marginTop:"70px"}}>
        {ReactHtmlParser(acknowledgeMessage ? acknowledgeMessage : strings.declarationConfirmation)}
      </Typography>

      <div style={{flexGrow:"1"}} />

      <div className={classes.bottomAnchored}>
        {
          !online &&
          <Typography variant="h5" className={classes.noInternet}>{strings.offline}</Typography>
        }
        <Button variant="contained" color="primary"
          className={classes.submitButton}
          disabled={submitting || !online}
          onClick={handleSubmit} >
          {
            submitting
              ? <CircularProgress size="24px" style={{ color: "white" }} />
              : strings.submit
          }
        </Button>
      </div>
    </Container>
  );
}
