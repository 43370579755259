const styleOverrides = {
  h1: {
    fontWeight: 700
  },
  h2: {
    fontWeight: 700
  },
  h3: {
    fontWeight: 700
  },
  h4: {
    fontWeight: 700
  },
  h5: {
    fontWeight: 700
  },
  h6: {
    fontWeight: 500
  }
};

export const MuiTypography = {
  ...styleOverrides,
};
