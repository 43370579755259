import _ from 'lodash';
import { useEffect, useState, useCallback} from 'react';
import { Subscriber } from 'rxjs';
import { DeferredPromise } from 'hive-client-utils';

export function useEffectOnce(fn) {
  useEffect(
    fn,
    // Disabling because of warning, but [] is valid and means that this is called once when component is created
    // eslint-disable-next-line
    [])
  ;
}

export function useObservable(observableOrFn, next = _.identity, error = _.identity) {
  useEffectOnce(() => {
    let observable$ = observableOrFn;
    if (typeof(observableOrFn) === 'function') {
      observable$ = observableOrFn();
    }

    const subscriber = new Subscriber(next, error);
    const subscription = observable$.subscribe(subscriber);
    return () => {
      subscription.unsubscribe();
    };
  });
}

const getInitialValue = (observableOrFn, initialValue) => () => {
  if (!_.isNil(initialValue)) {
    return initialValue;
  }

  if (typeof(observableOrFn) === 'function') {
    return initialValue;
  }

  let value = initialValue;
  observableOrFn
    .subscribe(v => value = v)
    .unsubscribe();

  return value;
};

export function useObservableState(observableOrFn, initialValue, error = _.identify) {
  const [ value, setValue ] = useState(getInitialValue(observableOrFn, initialValue));

  useObservable(observableOrFn, setValue, error);

  return value;
}

export function useAsyncCallback(fn, deps) {
  return useCallback(
    () => {
      // we must NOT return this async function as this would link its promise to that of React
      fn();
    },
    // eslint-disable-next-line
    deps
  );
}
export function useAsyncEffect(fn, deps) {
  // eslint-disable-next-line
  return useEffect(
    () => {
      // we must NOT return this async function as this would link its promise to that of React
      fn();
    },
    // eslint-disable-next-line
    deps,
  );
}

export function useThrowableObservable(observable) {
  // Since we do not want to add stuff to the given observable we wrap it in a state, which will
  // remain the same object always
  const [ wrapper ] = useState({});

  if (!wrapper.registered) {
    wrapper.registered = true;
    wrapper.observable = observable;
    wrapper.promise = new DeferredPromise();

    wrapper.subscription = observable.subscribe(
      value => {
        wrapper.value = value;
        wrapper.promise.resolveFn(value);
      },
      error => {
        wrapper.error = error;
        wrapper.promise.rejectFn(error);
      }
    );
  }

  if (wrapper.error) {
    throw wrapper.error;
  }

  if (!wrapper.value) {
    throw wrapper.promise.promise;
  }

  // Nothing to do, we just need to clean-up the subscription
  useEffect(
    () => () => wrapper.subscription.unsubscribe(),
    [wrapper]
  );

  return wrapper.value;
}

// Allows to capture user clicks outside a component.
export function useOnClickOutside(ref, eventHandler) {

  const outsideClickEventListener = useCallback((event) => {
    if (!ref.current.contains(event.target)) {
      eventHandler(event);
    }
  }, [eventHandler, ref]);

  useEffect(() => {

    document.addEventListener("mousedown", outsideClickEventListener);

    return () => {
      document.removeEventListener("mousedown", outsideClickEventListener);
    };
  }, [ref, eventHandler, outsideClickEventListener]);
}

const Hooks = {
  useAsyncCallback,
  useAsyncEffect,
  useEffectOnce,
  useObservable,
  useObservableState,
  useThrowableObservable,
};

export default Hooks;
