import {callHandlerOutcome} from "../customization/Customization";
import moment from 'moment';
const _ = require('lodash');
const screenResults = require ('./screenResults');

export const questionAnswers = {
  N_A: -1,
  YES: 0,
  NO: 1,
  BEFORE: 10,
  BETWEEN: 20,
  AFTER: 30,
  N_A_STRING: 'N/A',
};

export function answerToString (answer) {
  if (answer === questionAnswers.YES) {
    return "YES";
  } else if (answer === questionAnswers.NO) {
    return "NO";
  } else if (answer === questionAnswers.N_A) {
    return "N/A";
  } else {
    return "N/A";
  }
}

// This implementation should match ../../../Honey/surveyResult.js
export function calculateResult(qFlow, answers, userInfo) {
  let result = screenResults.PASS;
  let qOutcome;
  _.forEach(answers, (answer, index) => {
    qOutcome = calculateResultOneQuestion(qFlow, answer, index, userInfo);
    if (qOutcome > result) {
      result = qOutcome;
    }

  });

  return result;
}

// We accept list of answers and index and return result
// This implementation should match ../../../Honey/surveyResult.js
export function calculateResultOneQuestion(qFlow, answers, nQuestion, userInfo) {
  let q = qFlow[nQuestion];
  let result = screenResults.PASS;
  let qOutcome = screenResults.PASS;
  let nbAnswers = 0;

  // If we have a custom handler for the outcome, call it
  if (q.outcome.handler) {
    return callHandlerOutcome(q.outcome.handler, answers, userInfo);
  }

  if (q.questionType === "YesNo") {
    if (answers[0] === questionAnswers.YES) {
      qOutcome = q.outcome.yes;
    } else if (answers[0] === questionAnswers.NO) {
      qOutcome = q.outcome.no;
    } else {
      qOutcome = screenResults.PASS;
    }
    if (qOutcome > result) {
      result = qOutcome;
    }
  } else if (q.questionType === "CheckList") {
    nbAnswers = q.questionChecklist.length;
    let anyChecked = false;
    for (let i = 0; i < nbAnswers; i++) {
      if (answers[i] === questionAnswers.YES) {
        anyChecked = true;
        break;
      }
    }
    if (anyChecked) {
      result = q.outcome.yes;
    } else {
      result = q.outcome.no;
    }
  }

  if (q.questionType === "DateInput") {
    const userInputDate = moment(answers[0]);
    const today = moment();
    const diffDays = today.diff(userInputDate, "days");

    if (diffDays >= q.thresholdValues.before) {
      result = q.outcome.before;
    } else if (diffDays <= q.thresholdValues.after) {
      result = q.outcome.after;
    } else {
      result = q.outcome.between;
    }
  }

  if (q.questionType === "WeightedChecklist") {

    const weightSum = _.reduce(answers, (sum, currAns, index) => {
      if (currAns === questionAnswers.YES) {
        sum += _.get(q, ["questionWeightedChecklist", index, "itemWeight"], 0);
      }
      return sum;
    }, 0);

    if (weightSum >= q.thresholdValues.before) {
      result = q.outcome.before;
    } else if (weightSum <= q.thresholdValues.after) {
      result = q.outcome.after;
    } else {
      result = q.outcome.between;
    }
  }

  return result;
}

// Adjust result code to be one of the 3 possible outcome
// This implementation should match ../../../Honey/surveyResult.js
export function normalizeResult(res) {
  if (res >= screenResults.FAIL) {
    return screenResults.FAIL;
  } else if (res >= screenResults.PASS_RESTRICTION) {
    return screenResults.PASS_RESTRICTION;
  } else {
    return screenResults.PASS;
  }
}

export function getResultMessage(config, resCode, answers, userInfo) {
  // Go through all the answers, and find the highest outcome
  let outcome = screenResults.PASS;
  for (let i = 0; i < answers.length; i++)
  {
    let qOutcome = calculateResultOneQuestion(config.surveyFlow, answers[i], i, userInfo);
    if (qOutcome > outcome) {
      outcome = qOutcome;
    }
  }

  // Search the exit message to find the one matchine the outcome
  let res = _.find(config.exitMessages, {'code': resCode});

  if (res !== undefined) {
    return res.message;
  } else {
    console.log("Warning no exit message found for the exit code.");
    return "";
  }
}

export function getEmailFlag(config, flag, index) {
  let result = _.find(config.exitMessages, {'code': index});
  if (result !== undefined && result[flag] !== undefined) {
    return result[flag];
  }
  // If property is not specified
  return (flag === "userEmailReport") ? true : false;
}

export function getEmailShowSurveyDetailsFlag(config, flag, index) {
  let result = _.find(config.exitMessages, {'code': index});
  if (result !== undefined && result[flag] !== undefined) {
    return result[flag];
  }
  // We want to show the survey details by default
  return true;
}