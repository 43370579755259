import React from "react";
import {
  makeStyles,
  TextField,
  FormControl,
  FormControlLabel,
  Select,
  Checkbox,
  Grid,
  MenuItem,
  InputLabel,
  RadioGroup,
  Radio,
  FormLabel
} from "@material-ui/core";
import _ from "lodash";

const useStyles = makeStyles(() => ({
  field: {
    marginBottom: "15px",
  }
}));

const FIELD_TYPES = {
  TEXT: "text",
  LIST: "list",
  CHECK_BOX: "checkbox",
  RADIO: "radio",
};

export default function CustomField({ field, value, onChange }) {

  switch (field.type) {
    case FIELD_TYPES.TEXT:
      return <TypeText
        label={field.label}
        value={value}
        onChange={onChange}
      />;
    case FIELD_TYPES.CHECK_BOX:
      return <TypeCheckBox
        label={field.label}
        value={value}
        onChange={field.onChange}
      />;
    case FIELD_TYPES.LIST:
      return <TypeList
        label={field.label}
        value={value}
        onChange={onChange}
        options={field.options}
      />;
    case FIELD_TYPES.RADIO:
      return <TypeRadio
        label={field.label}
        value={value}
        onChange={onChange}
        options={field.options}
      />;
    default:
      return <div></div>;
  }

}

function TypeText({ label, value, onChange }) {
  const classes = useStyles();
  return (
    <TextField
      required
      label={label}
      variant="outlined"
      fullWidth
      margin="dense"
      value={value}
      className={classes.field}
      onChange={onChange}
    />
  );
}

function TypeCheckBox({ label, value, onChange }) {
  return (
    <FormControl margin='dense' fullWidth>
      <FormControlLabel
        control={<Checkbox checked={value}
          color="primary"
          onChange={onChange} />}
        label={label}
      />
    </FormControl>
  );
}

function TypeList({ label, value, onChange, options }) {
  return (
    <Grid container style={{ marginBottom: "15px" }}>
      <FormControl variant="outlined" margin='dense' fullWidth>
        <InputLabel id={`customField${label}-input-label`}>{label || ""}</InputLabel>
        <Select
          labelId={`customField${label}-input-label`}
          label={label || ""}
          value={value}
          onChange={onChange}>
          {
            _.map(options, (option, index) =>
              <MenuItem key={index} value={option}>{option}</MenuItem>
            )

          }
        </Select>
      </FormControl>
    </Grid>
  );
}

function TypeRadio({ label, value, onChange, options }) {
  return (
    <Grid container direction="row" style={{ marginBottom: "15px" }} >
      <FormControl component="fieldset">
        <FormLabel component="legend">{label}</FormLabel>
        <RadioGroup
          value={value}
          onChange={onChange}
        >
          {
            _.map(options,
              (option, index) =>
                <FormControlLabel
                  value={option}
                  key={index}
                  control={
                    <Radio color="primary"></Radio>
                  }
                  label={option}
                >
                </FormControlLabel>
            )
          }
        </RadioGroup>
      </FormControl>
    </Grid>
  );
}