import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogActions, Button, makeStyles, Typography } from '@material-ui/core';
import ReactHtmlParser from 'react-html-parser';

const useStyles = makeStyles(() => ({
  dialogPaper: {
    textAlign: "center",
    width: "400px",
    margin: "15px",
  },
  dialogActions: {
    justifyContent: "center",
  },
  dialogContent: {
    padding: "20px",
  },
  okButton: {
    width: "150px",
    height: "50px",
    borderRadius: "10px",
  },
  healthqLogo: {
    width: "90%",
  },
  healthDefinesUsLogo: {
    width: "90%",
  },
}));

export default function HealthDefinesUsDialog(props) {
  const [isOpen, setIsOpen] = useState(props.open);

  const closeDialog = () => {
    setIsOpen(false);
    props.handleCloseHealthDefinesUsDialog();
  };

  const openPrivacyPolicyDialog = (e) => {
    e.preventDefault();
    props.openPrivacyPolicyDialog();
  };

  const classes = useStyles();

  useEffect(() => {
    setIsOpen(props.open);
  }, [props.open]);

  return (
    <Dialog
      open={isOpen}
      classes={{
        paper: classes.dialogPaper,
      }}>
      <DialogContent className={classes.dialogContent}>
        <img className={classes.healthqLogo} src={props.strings.healthQLogoUrl} />

        <Typography>
          {ReactHtmlParser(props.strings.healthDefinesUs)}
        </Typography>
        <Typography><a href="" onClick={(e) => openPrivacyPolicyDialog(e)}>{props.strings.privacyPolicy}</a></Typography>
        <p>
          <Button onClick={closeDialog} variant="contained" color="primary" autoFocus className={classes.okButton}>
            {props.strings.ok}
          </Button>
        </p>
      </DialogContent>
      <DialogActions className={classes.dialogActions}>
        <img className={classes.healthDefinesUsLogo} src={props.strings.healthDefinesUsLogo} alt="Macadamian Logo" />
      </DialogActions>
    </Dialog>
  );
}