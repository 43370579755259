import React, { useContext, useEffect, useMemo, useState } from "react";
import { HiveContext } from "./services/HiveContext";
import Typography from "@material-ui/core/Typography";
import Home from './pages/Home';
import { CircularProgress, makeStyles } from "@material-ui/core";
import { useCookies } from "react-cookie";
import { strings } from "./localizedStrings";

const useStyles = makeStyles(() => ({
  loading: {
    width: '100vw',
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export default function Loader() {
  const classes = useStyles();
  const { configuration } = useContext(HiveContext);
  const [error, setError] = useState(false);
  const [ cookies ] = useCookies(['language']);

  const localizedStrings = useMemo(
    () => {
      strings.setLanguage(cookies.language || 'en');
      return strings;
    },
    [ cookies.language ]
  );

  useEffect(() => {
    const timer = setTimeout(() => {
      setError(true);}, 60000);  // a minute should be enough for slow connections.
    return () => clearTimeout(timer);
  }, []);

  return (
    <div>
      {
        !configuration && !error &&
        <div className={classes.loading}>
          <CircularProgress />
          &nbsp;
          <Typography variant="h6" color="primary">{ localizedStrings.loadingConfiguration }</Typography>
        </div>
      }
      {configuration &&
        <Home />
      }
      {!configuration && error &&
        <div>
          <Typography variant="h6" style={{margin:"0px 20px", color:"red"}}>
            Error Retrieving Survey configuration
          </Typography>
          <Typography variant="h6" style={{margin:"0px 20px", color:"red"}}>
            Erreur pour accéder à la configuration du questionnaire
          </Typography>
          <Typography variant="body1" style={{margin:"30px 20px"}}>
            Try Refresh your browser. Please contact your local administrator if this problem occurred again.
          </Typography>
          <Typography variant="body1" style={{margin:"0px 20px"}}>
            Rafraichissez votre navigateur. Contactez votre administrateur local si le problème persiste.
          </Typography>
        </div>
      }
    </div>
  );
}

