import React, { useContext } from 'react';
import { Grid, Typography } from '@material-ui/core';
import _ from 'lodash';
import { DataContext } from '../../contexts/DataContext';
import { ExternalUnits } from '../../components/ExternalUnits';
import { getKioskableValue } from '../../util/getKioskableValue';

export function Unit({
  locationId,
  unit,
  setUnit,
  setNeedsVisiting,
  isKiosk = false,
  alwaysDisplay
}) {
  const { surveyConfig } = useContext(DataContext);
  const visitInformation = _.get(surveyConfig, 'visitInformation');

  const unitEnabled = getKioskableValue(isKiosk, visitInformation, "unit");
  const unitLabel = _.get(visitInformation, 'unitLabel', 'TODO: unit');

  const unitPrompt = <Typography variant='h5' color="primary">{unitLabel}</Typography>;

  if (!unitEnabled) {
    return null;
  }

  return (
    <Grid item container direction="column" className="section unit">

      <ExternalUnits
        locationId={locationId}
        unit={unit}
        setUnit={setUnit}
        setNeedsVisiting={setNeedsVisiting}
        unitPrompt={unitPrompt}
        alwaysDisplay={alwaysDisplay}
      />

    </Grid>
  );
}
