import { MuiButton } from "./button";
import { MuiContainer } from "./container";
import { MuiFormGroup } from "./form";
import { MuiSvgIcon } from "./svg";

export const visitInformationTheme = {
  overrides: {
    MuiButton,
    MuiContainer,
    MuiFormGroup,
    MuiSvgIcon,
  },
};