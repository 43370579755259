import React, { useCallback, useContext } from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import _ from 'lodash';
import { DataContext } from '../../contexts/DataContext';
import { getKioskableValue } from '../../util/getKioskableValue';

export function Visiting({
  visiting,
  setVisiting,
  needsVisiting,
  isKiosk = false,
}) {
  const { surveyConfig } = useContext(DataContext);
  const visitInformation = _.get(surveyConfig, 'visitInformation');

  const visitingEnabled = getKioskableValue(isKiosk, visitInformation, "visiting");
  const visitingLabel = _.get(visitInformation, 'visitingLabel', 'TODO: visiting');

  const onChange = useCallback(
    (event) => setVisiting(event.target.value),
    [setVisiting]
  );

  if (!visitingEnabled) {
    return null;
  }

  if (!needsVisiting) {
    return null;
  }

  return (
    <Grid item container direction="column" className="section visiting">
      <Typography variant='h5' color="primary">
        {visitingLabel}
      </Typography>

      <TextField
        variant="outlined"
        color="primary"
        margin="dense"
        value={visiting}
        onChange={onChange}
      />

    </Grid>
  );
}
