import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import { strings } from "../localizedStrings.js";
import NumberFormat from 'react-number-format';
import {useCustomization} from "../customization/Customization.js";



export default function TemperaturePage(props) {
  const config = useCustomization(props.language).surveyConfig;

  const useStyles = makeStyles(() => ({
    headerText: {
      margin: "30px 4px 0px 4px"
    },
    questionText: {
      color: "#5A5A5A"
    },
    tempInput: {
      marginTop: "30px",
      width: "200px",
      height: "70px",
      fontSize: "2rem",
      color: config.primaryColor,
      borderWidth: "1px",
      borderStyle: "solid",
      borderRadius: "5%",
      textAlign: "center",
      borderColor: config.primaryColor,
      '&:focus': {outlineWidth: "0"}
    },
    bottomCenteredButton: {
      position:"absolute",
      bottom: "15px",
      left: "50%",
      transform: "translateX(-50%)",
      textTransform: "none",
    },
  }));

  const classes = useStyles();
  const [temperature, setTemperature] = React.useState(0.0);


  strings.setLanguage(props.language);


  return (
    <Container style={{padding:"0px"}}>
      <Typography variant='h5' color="primary" className={classes.headerText}>
        {strings.temperatureEnterTitle}
      </Typography>
      <Typography variant='h5' className={classes.questionText} style={{margin:"60px 4px 0px 4px"}}>
        {strings.enterTemperature}
      </Typography>
      <NumberFormat
        className={classes.tempInput}
        type="tel"
        displayType="input"
        inputMode="numeric"
        placeholder="     °C"
        format="##.# °C"
        mask="_"
        onValueChange={(values) => {
          setTemperature(values.floatValue/10.0);
        }}
      />

      <Button variant="contained" color="primary"
        disabled={!temperature || temperature < 32.0 || temperature > 43.0}
        onClick={() => {props.onTemperatureSet(temperature);}}
        className={classes.bottomCenteredButton} >
        {strings.submit}
      </Button>

    </Container>

  );
}
