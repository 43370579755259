import Container from "@material-ui/core/Container";
import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { makeStyles } from '@material-ui/core/styles';
import React from "react";
import { strings } from "../localizedStrings.js";
import {useCustomization} from "../customization/Customization.js";

const useStyles = makeStyles(() => ({
  resultDiv: {
    position: "absolute",
    top: "130px",
    bottom: "0",
    maxWidth: "inherit",
    width: "100%"
  },
  gradient: {
    height:"30px"
  },
  logo: {
    margin: '10px 20px 20px 20px',
    width: '85%',
    maxWidth: '375px'
  },
  bottomCentered: {
    width: "100%",
    position:"absolute",
    height: "180px",
    bottom: "15px",
    left: "50%",
    transform: "translateX(-50%)",
    textTransform: "none"
  },
  bottomCenteredButton: {
    position:"absolute",
    bottom: "0px",
    left: "50%",
    transform: "translateX(-50%)",
    backgroundColor: "white",
    textTransform: "none",
  },
}));

export default function TemperaturePage(props) {
  const classes = useStyles();

  const blueBackground = {background: "#3593f3"};
  const blueGradient = {background: "linear-gradient(#2973bd, #3593f3)"};
  const blueText = {color: "#3593f3"};

  const config = useCustomization(props.language).surveyConfig;

  strings.setLanguage(props.language);


  return (
    <Container style={{padding:"0px"}}>
      <div style={{height:"100px"}}>
        <img src={config.logo} alt="Client Logo" className={classes.logo} />
      </div>
      <div className={classes.gradient} style={blueGradient} />
      <div className={classes.resultDiv} style={blueBackground} >
        <Typography variant="h5" style={{margin:"100px 4px 0px 4px", color:"white"}} >
          {strings.temperatureCheck}
        </Typography>

        <div className={classes.bottomCentered} >
          <Button variant="contained"
            onClick={() => {props.onOk();}}
            style={blueText}
            className={classes.bottomCenteredButton} >
            {strings.ok}
          </Button>
        </div>
      </div>
    </Container>

  );
}
