import { HiveContext } from "../services/HiveContext";
import { useContext } from "react";
import { questionAnswers } from '../util/testResults';
const _ = require('lodash');
const screenResults = require ('../util/screenResults');

export function getCustomization(configuration, lang) {
  // We only care about config.properties
  let filteredConfig = _.map(configuration, 'properties');

  // If a specific survey name is specified, filter for it
  if (process.env.REACT_APP_THEME) {
    filteredConfig = _.filter(filteredConfig, {surveyName:process.env.REACT_APP_THEME});
  }

  // If a language is provided, filter for it
  if (lang) {
    let languageFilterConfig = _.filter(filteredConfig, {language:lang});
    if (languageFilterConfig.length === 0) {
      return _.get(filteredConfig, 0);
    } else {
      filteredConfig = languageFilterConfig;
    }
  }

  if (filteredConfig.length === 0) {
    console.log(`Error: cannot retrieve configuration for lang=${lang} surveyName=${process.env.REACT_APP_THEME}`);
    // This will return undefined and the application will crash.
    // (alternatively we could use the first configuration, but I'm afraid we will not realize the issue if we do that)
  }
  // Return the first configuration matching language and surveyName, or undefined if there's no match
  return _.get(filteredConfig, 0);
}

export function useCustomization(lang) {
  const { configuration } = useContext(HiveContext);

  if (!configuration) {
    return undefined;
  }

  return getCustomization(configuration, lang);
}

// Custom handlers table for nextQuestion
let handlerNextQuestionMap = {
  bruyere_handlerNextQuestionWorking,
  bruyere_handlerNextQuestionTestedPositive,

  quebec_handle_ageSelection_nextQuestion,

  perleyrideau_symptoms_handleNext,
  perleyrideau_isolation_handleNext,
  perleyrideau_test_handleNext,
  perleyrideau_contact_handleNext,
  perleyrideau_travel_handleNext,
  perleyrideau_household_handleNext,
  perleyrideau_support_worker_handleNext,

  ontarioshores_symptoms_next_handler,
  ontarioshores_contacts_next_handler,
  ontarioshores_outbreak_next_handler,
  ontarioshores_travel_next_handler,
  ontarioshores_isolation_next_handler,
  ontarioshores_covid_next_handler,
  ontarioshores_rapid_test_next_handler,

  novaScotiaHealth_nextQuestion_travel,
  novaScotiaHealth_nextQuestion_vaccination,

};

export function callHandlerNextQuestion(name, ...args) {
  let handler = _.get(handlerNextQuestionMap, name);

  if (handler === null) {
    console.log("Warning, Next question handler not found: ", name);
    return null; // Go to the end of the survey
  } else {
    return handler(...args);
  }
}

let handlerPreQuestionMap = {
  perleyrideau_prequestion_handler,
  royal_prequestion_handler,
  toh_prequestion_handler,
};

export function callHandlerPreQuestion(name, ...args) {
  let handler = _.get(handlerPreQuestionMap, name);

  if (handler === null) {
    console.log("Warning, Pre question handler not found: ", name);
    return null; // Go to the end of the survey
  } else {
    return handler(...args);
  }
}

// Custom handlers table for question outcomes
let handlerOutcomeMap = {
  sogefigroup_handleOutcome,

  ontarioSchoolCustomHandlerQ2,
  perleyrideau_outcome_handler_working,
  perleyrideau_outcome_attestations,
  perleyrideau_outcome_yesno,
  perleyrideau_outcome_checklist,

  ontarioshores_fever_outcome_handler,
  ontarioshores_symptoms_outcome_handler,
  ontarioshores_yesno_outcome_handler,
  ontarioshores_rapid_test_outcome_handler,
  ontarioshores_ppecc_outcome_handler,

  novaScotiaHealth_outcome,
  novaScotiaHealth_outcome_isolation,

  newscorp_outcome_symptoms_checklist,

  nordik_outcome_yesno,
  nordik_outcome_symptoms_checklist,

  toh_outcome_symptoms,
  toh_outcome_quarantine,
  toh_outcome_recent_activity,
};

export function callHandlerOutcome(name, ...args) {
  let handler = _.get(handlerOutcomeMap, name);

  if (handler === null) {
    console.log("Warning, Outcome handler not found: ", name);
    return null; // Go to the end of the survey
  } else {
    return handler(...args);
  }
}

// These need to stay up to date with the Bruyere Strings
const SLR = "Saint-Louis Residence";
const EBR = "Élisabeth Bruyère Residence";
const BV = "Bruyère Village";
const BRUYERE_LTC = [
  SLR,
  EBR,
  BV,
];

function bruyere_handlerNextQuestionWorking(answer) {
  if (answer[0] === questionAnswers.YES) {
    return "6b";
  } else {
    return "7";
  }
}

function bruyere_handlerNextQuestionTestedPositive(answer, userInfo) {
  const fullyVaccinated = userInfo.customFields.vaccination === "Yes"
  || userInfo.customFields.vaccination === "Oui";
  if (_.includes(BRUYERE_LTC, userInfo.location)) {
    return "10_LTC";
  } else if (!fullyVaccinated) {
    return "10_hospitals";
  }
  return null;
}

// PERLEY RIDEAU custom handlers
// these values must be consistent with ./Customers/Perley/perley.js
const prGeneralVisitor = "General Visitor";
const prSupportWorker = "Support Worker";
const prCaregiver = "Designated Caregiver";
const prStaff = "Staff";
const prVolunteer = "Volunteer";
const prStudent = "Student";
const prTenant = "Tenant";
const prOther = "Other";

const osStaff = "Staff";
const osSecurity = "Security";
const osContractor = "Contractor";
const osVisitor = "Visitor";



// SOGEFI GROUP custom handlers
// This logic must be kept up to date with ../../../Honey/surveyResults.js
function sogefigroup_handleOutcome(answers) {
  let yesCount = 0;
  for (let i = 0; i <= 3; i++) {
    if (answers[i] === questionAnswers.YES) {
      yesCount++;
      break;
    }
  }
  for (let i = 4; i <= 5; i++) {
    if (answers[i] === questionAnswers.YES) {
      yesCount++;
    }
  }
  if (yesCount === 0) {
    return screenResults.PASS;
  } else if (yesCount === 1) {
    return screenResults.PASS_RESTRICTION;
  }
  return screenResults.FAIL;
}

// QUEBEC Template custom handlers
// This logic must be kept up to date with '../../../common/src/ScreenerTemplates/quebecAug26.js'
function quebec_handle_ageSelection_nextQuestion(answer) {
  // 0: Age 0-5
  // 1: Age 6-17
  // 2: Age 18+

  if (_.get(answer, 2, questionAnswers.NO) === questionAnswers.YES) {
    return 'age18_1';
  }

  if (_.get(answer, 1, questionAnswers.NO) === questionAnswers.YES) {
    return 'age6_17_1';
  }

  if (_.get(answer, 0, questionAnswers.NO) === questionAnswers.YES) {
    return 'age0_5_1';
  }

  return 'age18_1';
}

// Ontario School handlers
// This logic must be kept up to date with ../../../Honey/surveyResults.js
function ontarioSchoolCustomHandlerQ2(answers) {
  let yesCount = 0;
  for (let i = 0; i < answers.length; i++) {
    if (answers[i] === 0) {
      yesCount++;
    }
  }
  if (yesCount === 0) {
    return screenResults.PASS;
  } else if (yesCount === 1) {
    return screenResults.PASS_RESTRICTION;
  } else {
    return screenResults.FAIL;
  }
}

// Royal Ottawa handlers

const ROYAL_LTC_LOCATIONS = [
  "Royal Ottawa Place – Long Term Care",
  "Place Royal Ottawa - Soins de longue durée"
];

const ROYAL_LTC_QUESTIONNAIRE_TYPES = [
  "Staff",
  "Student",
  "Volunteer",
  "Employé",
  "Étudiant",
  "Bénévole",
];

function royal_prequestion_handler(userInfo) {
  const location = _.get(userInfo, "location");
  const type = _.get(userInfo, "type");

  if (_.includes(ROYAL_LTC_LOCATIONS, location) && _.includes(ROYAL_LTC_QUESTIONNAIRE_TYPES, type)) {
    return {startingQuestion: "LTC_1", continue: true};
  }
  return {startingQuestion: "1", continue: true};
}

const PR_OFFSET_GENERAL_VISITORS          = 1;
const PR_OFFSET_OTHER_AND_SUPPORT_WORKERS = 2;
const PR_OFFSET_CAREGIVERS                = 3;
const PR_OFFSET_TENANTS                   = 4;

// Perely handlers
function perleyrideau_symptoms_handleNext(answers, userInfo) {
  if (userInfo.location === prStaff) {
    return "StaffIsolation";
  } else {
    return "Isolation";
  }
}

function perleyrideau_isolation_handleNext(answers, userInfo) {
  if (userInfo.location === prStaff) {
    return "StaffTest";
  } else {
    return "Test";
  }
}

function perleyrideau_test_handleNext(answers, userInfo) {
  if (userInfo.location === prStaff) {
    return "StaffContact";
  } else {
    return "Contact";
  }
}

function perleyrideau_contact_handleNext() {
  return "Travel";
}

function perleyrideau_travel_handleNext(answers, userInfo) {
  if (userInfo.location === prStaff) {
    return "StaffHousehold";
  } else {
    return "Household";
  }
}

function perleyrideau_household_handleNext(answers, userInfo) { // only unvaccinated staff, volunteers and students need to answer Q9 question
  const applicablePersonnel = userInfo.location === prStaff || userInfo.location === prVolunteer || userInfo.location === prStudent;

  if ( applicablePersonnel && userInfo.customFields.vaccinationStatus !== "Fully vaccinated") {
    return "Q9";
  } else {
    return "<End Survey>";
  }
}

function perleyrideau_support_worker_handleNext( answers, userInfo ) {
  if ( userInfo.location === prSupportWorker ) {
    return "A4";
  } else {
    return "A3";
  }
}

function perleyrideau_outcome_handler_working(answers, userInfo) {
  if (answers[0] === questionAnswers.N_A) {
    return screenResults.PASS;
  } else if (answers[0] === questionAnswers.NO) {
    return screenResults.PASS;
  } else {
    return perleyrideau_outcome_yesno(answers, userInfo);
  }
}

function perleyrideau_outcome_attestations(answers, userInfo) {
  if (answers[0] === questionAnswers.NO) {
    if (userInfo.location === prGeneralVisitor) {
      return screenResults.FAIL + PR_OFFSET_GENERAL_VISITORS;
    } else if (userInfo.location === prOther || userInfo.location === prSupportWorker) {
      return screenResults.FAIL + PR_OFFSET_OTHER_AND_SUPPORT_WORKERS;
    } else if (userInfo.location === prCaregiver) {
      return screenResults.FAIL + PR_OFFSET_CAREGIVERS;
    } else if (userInfo.location === prTenant) {
      return screenResults.FAIL + PR_OFFSET_TENANTS;
    } else {
      return screenResults.FAIL;
    }
  } else {
    if (userInfo.location === prGeneralVisitor || userInfo.location === prTenant) {
      return screenResults.PASS + PR_OFFSET_GENERAL_VISITORS;
    } else {
      return screenResults.PASS;
    }
  }
}

function perleyrideau_outcome_yesno(answers, userInfo) {
  if (answers[0] === questionAnswers.YES) {
    if (userInfo.location === prOther || userInfo.location === prSupportWorker) {
      return screenResults.FAIL + PR_OFFSET_OTHER_AND_SUPPORT_WORKERS;
    } else if (userInfo.location === prGeneralVisitor) {
      return screenResults.FAIL + PR_OFFSET_GENERAL_VISITORS;
    } else if (userInfo.location === prCaregiver) {
      return screenResults.FAIL + PR_OFFSET_CAREGIVERS;
    } else if (userInfo.location === prTenant) {
      return screenResults.FAIL + PR_OFFSET_TENANTS;
    } else {
      return screenResults.FAIL;
    }
  } else if (
    userInfo.customFields.vaccinationStatus !== "Fully vaccinated"
    && userInfo.location !== prOther
  ) {
    if (userInfo.location === prGeneralVisitor) {
      return screenResults.PASS_RESTRICTION + PR_OFFSET_GENERAL_VISITORS;
    } else if (userInfo.location === prTenant) {
      return screenResults.PASS_RESTRICTION + PR_OFFSET_TENANTS;
    } else {
      return screenResults.PASS_RESTRICTION;
    }
  } else if (userInfo.location === prGeneralVisitor || userInfo.location === prTenant) {
    return screenResults.PASS + PR_OFFSET_GENERAL_VISITORS; // general visitors and tenants get a custom pass message
  }
  else {
    return screenResults.PASS;
  }
}

function perleyrideau_outcome_checklist(answers, userInfo) {
  let yesCount = 0;
  for (let i = 0; i < answers.length; i++) {
    if (answers[i] === 0) {
      yesCount++;
    }
  }
  if (yesCount === 0) {
    if (userInfo.location === prGeneralVisitor || userInfo.location === prTenant) {
      return screenResults.PASS + PR_OFFSET_GENERAL_VISITORS;
    } else {
      return screenResults.PASS;
    }
  } else if (userInfo.location === prGeneralVisitor) {
    return screenResults.FAIL + PR_OFFSET_GENERAL_VISITORS;
  } else if (userInfo.location === prOther || userInfo.location === prSupportWorker) {
    return screenResults.FAIL + PR_OFFSET_OTHER_AND_SUPPORT_WORKERS;
  } else if (userInfo.location === prCaregiver) {
    return screenResults.FAIL + PR_OFFSET_CAREGIVERS;
  } else if (userInfo.location === prTenant) {
    return screenResults.FAIL + PR_OFFSET_TENANTS;
  } else {
    return screenResults.FAIL;
  }
}

function perleyrideau_prequestion_handler(userInfo) {
  const location = _.get(userInfo, 'location');

  const FAIL_IMMEDIATELY_IF_NOT_VACCINATED = {
    [prGeneralVisitor]: 'FullyVaccinatedGeneralVisitors',
    [prTenant]: 'FullyVaccinatedTenants',
    [prStaff]: 'UnvaccinatedStaff',
    [prVolunteer]: 'UnvaccinatedStaff',
    [prStudent]: 'UnvaccinatedStaff',
    [prSupportWorker]: 'UnvaccinatedSupportWorkers',
    [prCaregiver]: 'UnvaccinatedDesignatedCaregiver'
  };
  const resultQuestionId = _.get(FAIL_IMMEDIATELY_IF_NOT_VACCINATED, location);
  if (resultQuestionId) {
    const vaccinationStatus = _.get(userInfo, 'customFields.vaccinationStatus');
    if (vaccinationStatus !== "Fully vaccinated") {
      return {
        continue: false,
        resultQuestionId: resultQuestionId,
        resultQuestionAnswer: 1, // 1 is NO
        skipReviewAndAcknowledged: true,
      };
    }
  }

  const NEED_ATTESTATION = [
    prCaregiver,
    prGeneralVisitor,
  ];
  if (_.includes(NEED_ATTESTATION, location)) {
    return { startingQuestion: "A2", continue: true }; // go to second attestation
  } else {
    return { startingQuestion: "Q1", continue: true }; // goes to first screening question, no need for attestations
  }
}

function ontarioshores_symptoms_next_handler(answers, userInfo) {
  let yesCount = 0;
  for (let i = 0; i < answers.length; i++) {
    if (answers[i] === questionAnswers.YES) {
      yesCount++;
    }
  }
  if (yesCount===0) {
    return "3";
  } else if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
    return null;
  } else {
    return "2b";
  }
}

function ontarioshores_travel_next_handler(answers, userInfo) {
  if (answers[0] === questionAnswers.NO) {
    return "4";
  } else {
    if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
      return "3a";
    } else {
      return "3b";
    }
  }
}

function ontarioshores_contacts_next_handler(answers, userInfo) {
  if (answers[0] === questionAnswers.NO ) {
    return "5";
  } else {
    if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
      return null;
    }
    return "4b";
  }
}

function ontarioshores_outbreak_next_handler(answers) {
  if (answers[0] === questionAnswers.NO) {
    return "6";
  } else {
    return "5b";
  }
}

function ontarioshores_isolation_next_handler(answers, userInfo) {
  if (answers[0] === questionAnswers.NO) {
    return "8";
  } else {
    if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
      return null;
    } else {
      return "7b";
    }
  }
}

function ontarioshores_covid_next_handler(answers, userInfo) {
  const isNo = answers[0] === questionAnswers.NO;
  switch (_.get(userInfo, 'type', "")) {
    case osStaff:
    case osSecurity:
      return isNo ? "9" : "8b";
    default:
      return "9";
  }
}

function ontarioshores_rapid_test_next_handler(_answers, userInfo) {
  switch (_.get(userInfo, 'type', "")) {
    case osContractor:
      return "10";
    case osVisitor:
      return "11";
    default:
      return null;
  }
}

// This must be kept up to date with the logic in ../../../Honey/surveyResult.js
function ontarioshores_fever_outcome_handler(answers, userInfo) {
  if (answers[0] === questionAnswers.YES) {
    if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
      return screenResults.FAIL + 1;
    } else {
      return screenResults.FAIL;
    }
  } else {
    return screenResults.PASS;
  }
}

// This must be kept up to date with the logic in ../../../Honey/surveyResult.js
function ontarioshores_symptoms_outcome_handler(answers, userInfo) {
  if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
    for (let i = 0; i < answers.length; i++) {
      if (answers[i] === questionAnswers.YES) {
        return (screenResults.FAIL + 1);
      }
    }
    return screenResults.PASS;
  } else {
    return screenResults.PASS; // the staff will receive their appropriate message upon handling the staff-only question following this.
  }
}

// This must be kept up to date with the logic in ../../../Honey/surveyResult.js
function ontarioshores_yesno_outcome_handler(answers, userInfo) {
  if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
    if (answers[0] === questionAnswers.YES) {
      return screenResults.FAIL + 1;
    } else {
      return screenResults.PASS;
    }
  } else {
    return screenResults.PASS; // the staff will receive their appropriate message upon handling the staff-only question following this.
  }
}

function ontarioshores_rapid_test_outcome_handler(answers, userInfo) {
  if (answers[0] === questionAnswers.YES) {
    if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
      return screenResults.FAIL + 1;
    } else {
      return screenResults.FAIL;
    }
  } else {
    return screenResults.PASS;
  }
}

// This must be kept up to date with the logic in ../../../Honey/surveyResult.js
function ontarioshores_ppecc_outcome_handler(answers, userInfo) {
  if (answers[0] === questionAnswers.N_A) {
    return screenResults.PASS;
  } else if (answers[0] === questionAnswers.NO) {
    if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
      return screenResults.FAIL + 1;
    } else {
      return screenResults.FAIL;
    }
  } else if (answers[0] === questionAnswers.YES) {
    if (userInfo.type && userInfo.type !== osStaff && userInfo.type !== osSecurity) {
      return screenResults.PASS;
    } else {
      return screenResults.PASS; // remove when caution screen enabled
      // return screenResults.PASS_RESTRICTION;  // enable when caution screen wanted
    }
  }
}

// Nova Scotia Health custom handlers
const NSH_VISITOR = ["Visitor", "Visiteur"];
const NSH_ACCOMPANYING = ["Accompanying Person", "Personne accompagnatrice"];
const NSH_PATIENT = ["Patient"];

// This must be kept up to date with the logic in ../../../Honey/surveyResult.js
function novaScotiaHealth_outcome(answers, userInfo) {
  for (let i = 0; i < answers.length; i++) {
    if (answers[i] === questionAnswers.YES) {
      if (_.includes(NSH_VISITOR, userInfo.type)) {
        return screenResults.PASS_RESTRICTION + 1;
      } else if (_.includes(NSH_ACCOMPANYING, userInfo.type)) {
        return screenResults.FAIL;
      } else {
        return screenResults.PASS_RESTRICTION;
      }
    }
  }
  return screenResults.PASS;
}

function novaScotiaHealth_outcome_isolation(answers, userInfo) {
  if (answers[0] === questionAnswers.NO) {
    if (_.includes(NSH_PATIENT, userInfo.type)) {
      return screenResults.PASS_RESTRICTION + 1;
    } else  {
      return screenResults.FAIL + 1;
    }
  } else if (answers[0] === questionAnswers.YES) {
    return screenResults.PASS + 1;
  }
  return screenResults.PASS;
}

function novaScotiaHealth_nextQuestion_travel(answers) {
  if (answers[0] === questionAnswers.YES) {
    return "6";
  } else {
    return null;
  }
}

function novaScotiaHealth_nextQuestion_vaccination(answers) {
  if (answers[0] === questionAnswers.YES) {
    return "7";
  } else {
    return null;
  }
}

// This must be kept up to date with the logic in ../../../Honey/surveyResult.js
function newscorp_outcome_symptoms_checklist(answers) {
  const lastFailIfAnyIndex = Math.min(3, answers.length - 1);

  // first questions any checked is a fail
  for (let i = 0; i <= lastFailIfAnyIndex; i++) {
    if (answers[i] === questionAnswers.YES) {
      return screenResults.FAIL;
    }
  }

  let nrChecked = 0;
  // last questions fail if 2 or more are checked
  for (let i = lastFailIfAnyIndex + 1; i < answers.length; i++) {
    if (answers[i] === questionAnswers.YES) {
      nrChecked++;
    }
  }

  if (nrChecked >= 2) {
    return screenResults.FAIL;
  }

  return screenResults.PASS;
}

const NORDIK_OFFSET_CHELSEA = 1;
const NORDIK_OFFSET_WINNIPEG = 2;
const NORDIK_OFFSET_WHITBY = 3;
const NORDIK_OFFSET_GATINEAU_HQ = 4;

const NORDIK_FAIL_RESULTS = {
  "Nordik, Chelsea": screenResults.FAIL + NORDIK_OFFSET_CHELSEA,
  "Thermëa, Winnipeg": screenResults.FAIL + NORDIK_OFFSET_WINNIPEG,
  "Thermëa, Whitby": screenResults.FAIL + NORDIK_OFFSET_WHITBY,
  "Groupe Nordik, Gatineau": screenResults.FAIL + NORDIK_OFFSET_GATINEAU_HQ,
};

function nordik_outcome_yesno(answers, userInfo) {
  if (answers[0] === questionAnswers.YES) {
    return _.get(NORDIK_FAIL_RESULTS, userInfo.location, screenResults.FAIL);
  } else {
    return screenResults.PASS;
  }
}

function nordik_outcome_symptoms_checklist(answers, userInfo) {

  // fail if two or more symptoms are checked
  let nrChecked = 0;

  for (let i = 0; i < answers.length; i++) {
    if (answers[i] === questionAnswers.YES) {
      nrChecked++;
    }
  }
  if (nrChecked >= 2) {
    return _.get(NORDIK_FAIL_RESULTS, userInfo.location, screenResults.FAIL);
  } else {
    return screenResults.PASS;
  }
}

function isTOHPatient(userInfo) {
  return userInfo.type === "Patient";
}

function toh_prequestion_handler(userInfo) {
  if (isTOHPatient(userInfo)) {
    return { startingQuestion: "3", continue: true };
  }
  return { startingQuestion: "1", continue: true };
}

function toh_outcome_symptoms(answers, userInfo) {
  if (answers[0] === questionAnswers.YES) {
    if (isTOHPatient(userInfo)) {
      return screenResults.PASS_RESTRICTION;
    } else {
      return screenResults.FAIL;
    }
  } else {
    return screenResults.PASS;
  }
}

function toh_outcome_quarantine(answers, userInfo) {
  if (answers[0] === questionAnswers.YES) {
    if (isTOHPatient(userInfo)) {
      return screenResults.PASS_RESTRICTION;
    } else {
      return screenResults.FAIL;
    }
  } else {
    return screenResults.PASS;
  }
}

function toh_outcome_recent_activity(answers, userInfo) {
  if (answers[0] === questionAnswers.YES) {
    if (isTOHPatient(userInfo)) {
      return screenResults.PASS_RESTRICTION;
    } else {
      return screenResults.FAIL;
    }
  } else {
    return screenResults.PASS;
  }
}


