import React, { useCallback } from 'react';
import { Grid, Dialog, DialogContent, DialogTitle, Typography, Button, makeStyles, Container } from '@material-ui/core';
import { strings } from "../localizedStrings";


const useStyles = makeStyles((theme) => ({
  titleText: {
    color: theme.palette.primary.main,
    fontStyle: "italic",
    marginTop: "20px",
    marginBottom: "20px",
    padding: 0,
    alignSelf: "center",
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    alignItems: "center",
    textAlign: "center",
    overflow: "auto",
  },
  infoText: {
    color: "black",
    fontStyle: "italic",
    marginBottom: "16px",
  },
  text: {
    color: "black",
    fontStyle: "italic",
    marginBottom: "12px",
  },
  optionsGrid: {
    margin: "16px 0",
    textAlign: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
  },
  buttons: {
    minWidth: "190px",
    marginBottom: "12px",
  },
  buttonsLabel: {
    textTransform: "none",
  },
}));

export default function WrongCodeDialog(props) {

  const classes = useStyles();

  const handleTryAgain = useCallback(() => {
    props.handleCloseErrorDialog();
  }, [props.handleCloseErrorDialog]);

  const handleResendCode = useCallback(() => {
    props.handleResendCode();
    props.handleCloseErrorDialog();
  }, [props.handleResendCode, props.handleCloseErrorDialog]);

  const handleCheckInfo = useCallback(() => {
    props.handleCheckInfo();
    props.handleCloseErrorDialog();
  }, [props.handleCheckInfo, props.handleCloseErrorDialog]);

  return (
    <Dialog open={props.open} onClose={props.handleClose} >

      <DialogTitle variant="h5" className={classes.titleText} >
        {strings.validationIssue}
      </DialogTitle>

      <DialogContent className={classes.dialogContent} >

        <Container >

          <Typography className={classes.infoText} variant="body1" >
            {strings.validationIssueInfo}
          </Typography>

          <Grid className={classes.optionsGrid} >

            <Typography className={classes.text}>
              {strings.checkCode}
            </Typography>

            <Button
              className={classes.buttons}
              variant="contained"
              color="primary"
              onClick={handleTryAgain}
              classes={{
                label: classes.buttonsLabel
              }} >
              {strings.tryAgain}
            </Button>
          </Grid>

          <Grid className={classes.optionsGrid} >

            <Typography className={classes.text}>
              {strings.newCode}
            </Typography>

            <Button
              className={classes.buttons}
              variant="contained"
              color="primary"
              onClick={handleResendCode}
              classes={{
                label: classes.buttonsLabel
              }} >
              {strings.getNewCode}
            </Button>
          </Grid>

          <Grid className={classes.optionsGrid} >

            <Typography className={classes.text}>
              {strings.sentCodeTo.replace("${email}", props.userEmail)}
            </Typography>

            <Button
              className={classes.buttons}
              variant="contained"
              color="primary"
              onClick={handleCheckInfo}
              classes={{
                label: classes.buttonsLabel
              }} >
              {strings.checkDetails}
            </Button>
          </Grid>

        </Container>

      </DialogContent>

    </Dialog>
  );
}