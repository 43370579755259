import React, { createContext, useMemo, useState } from 'react';
import { useCookies } from 'react-cookie';
import { formatLocations, formatUnits } from 'healthq-shared-library';
import { createHiveBee } from '../services/HiveService';
import { useAsyncEffect } from '../util/hooks';
import { useCustomization } from '../customization/Customization';

import _ from 'lodash';
import _fp from 'lodash/fp';

export const DataContext = createContext({});

export function DataContextProvider({ children }) {
  const [{ language = 'en' }] = useCookies(['language']);

  // Fetch the external locations and units
  const [ externalLocations, setExternalLocations ] = useState([]);
  const [ externalUnits, setExternalUnits ] = useState([]);
  useAsyncEffect(async () => {
    let bee;

    try {
      [bee] = await createHiveBee(
        process.env.REACT_APP_BEE_NAME,
        process.env.REACT_APP_BEE_PASS
      );

      let externalLocations =
        await bee.storage.getClassInstancesByClassName('screening', 'location');
      externalLocations = formatLocations(externalLocations, language);
      setExternalLocations(externalLocations);

      let externalUnits = await bee.storage.getClassInstancesByClassName(
        'screening',
        'unit'
      );
      externalUnits = formatUnits(externalUnits, language);
      setExternalUnits(externalUnits);

    } catch (error) {
      console.error(error);
    }

    if (bee) {
      bee.auth.revokeToken().catch(console.error);
    }
  }, [language]);

  // Get the current configuration, based on language
  const { surveyConfig } = useCustomization(language) || {};

  // Build the proper list of locations based on config/language and whether external locations are
  // to be used.
  const effectiveLocations = useMemo(
    () => {
      const sortList = _.get(surveyConfig, 'userInfo.locationsListSorted', false);
      const useExternalLocations = _.get(surveyConfig, 'userInfo.externalLocations', false);

      if (useExternalLocations) {
        return sortList
          ? _.sortBy(externalLocations, 'name')
          : externalLocations
        ;
      }

      return _fp.flow([
        _fp.get('userInfo.locationsList'),
        _fp.filter(v => !!v),
        sortList ? _fp.sortBy('location') : _fp.identity,
        _fp.map(location => _.assignIn(
          {
            id: location.location,
            name: location.location,
          },
          location,
        )),
      ])(surveyConfig);
    },
    [surveyConfig, externalLocations]
  );

  const values = useMemo(
    () => ({
      surveyConfig,
      externalLocations,
      externalUnits,
      effectiveLocations,
    }),
    [
      surveyConfig,
      externalLocations,
      externalUnits,
      effectiveLocations,
    ]
  );

  return (
    <DataContext.Provider value={values}>
      {children}
    </DataContext.Provider>
  );
}
