import React, { useState } from "react";
import { ConnectionService } from "hive-client-utils";
import { createHiveBee } from "./HiveService";
import { useAsyncEffect } from "../util/hooks";
import { useCookies } from "react-cookie";
import { getCustomization } from "../customization/Customization";
import DefaultLogo from '../assets/healthQ_logo.svg';
import _ from 'lodash';

const beeName = process.env.REACT_APP_BEE_NAME;
const beePassword = process.env.REACT_APP_BEE_PASS;

export const HiveContext = React.createContext();

export function HiveProvider(props) {
  const [configuration, setConfiguration] = useState(null);
  const [configActive, setConfigActive] = useState(false);
  const [orgName, setOrgName] = useState('healthQ');
  const [logoUrl, setLogoUrl] = useState(DefaultLogo);
  const [error, setError] = useState();
  const [cookies] = useCookies(["language"]);

  useAsyncEffect(async() => {
    let bee, configActive, orgName;
    try {
      [bee, configActive, orgName] = await createHiveBee(beeName, beePassword);
      setConfigActive(!!configActive);
      setOrgName(orgName || 'healthQ');

      // Retrieve Screening configuration
      const config = await bee.storage.getClassInstancesByClassName("screening", "configuration");
      setConfiguration(config);

      // Load the logo blob
      const customization = getCustomization(config, cookies.language);
      const blobId = _.get(customization, 'customerLogo.id');
      if (blobId) {
        let blob = await bee.blob.download(customization.customerLogo.id);
        blob = new Blob([blob.content], { type: blob.contentType });
        const url = URL.createObjectURL(blob);
        setLogoUrl(url);
      }

      await ConnectionService.releaseBee(bee).catch(console.error);
    } catch (error) {
      if (bee) {
        await ConnectionService.releaseBee(bee).catch(console.error);
      }
      setError(error);
    }
  }, [cookies.language]);

  if (error) {
    throw error;
  }

  return (
    <HiveContext.Provider value={{ configuration, configActive, orgName, logoUrl }}>
      {props.children}
    </HiveContext.Provider>
  );
}
