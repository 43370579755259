import React, { useCallback, useContext, useState } from 'react';
import { Button, Grid, IconButton, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ChevronLeft from '@material-ui/icons/ChevronLeft';

import _ from 'lodash';

import { strings } from '../localizedStrings.js';
import { DataContext } from '../contexts/DataContext.js';
import { FontSizeSelector } from '../components/FontSizeSelector.js';
import { useKiosk } from "../hooks/useKiosk";

const useStyles = makeStyles(() => ({
  topContainer: {
    alignItems: 'center',
    alignContent: 'center',
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
  },
  header: {
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: '0px 24px 0px 24px'
  },
  logo: {
    boxSizing: 'border-box',
    height: 'inherit',
    maxWidth: '100%',
    objectFit: 'contain',
  },
  headerText: {
    marginBottom: '32px',
  },
  buttons: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '340px',
  },
  button: {
    marginBottom: '20px',
    backgroundColor: '#EAF0F4',
    "&:hover": {
      backgroundColor: '#EAF0F4CC',
    },
  },
  buttonSelected: {
    backgroundColor: '#26436F',
    color:'#FFFFFF',
    "&:hover": {
      backgroundColor: '#26436FCC',
    },
  },
  bottomAnchored: {
    position: 'absolute',
    bottom: 0,
  },
  submitButton: {
    display: 'block',
    marginTop: '20px',
    marginBottom: '20px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '25vw',
    minHeight: '3vh',
    maxWidth: '150px',
    color: 'white',

    '&.Mui-disabled': {
      color: '#939398',
      backgroundColor: '#E6E6E6',
      borderWidth: '1px',
      borderStyle: 'solid',
      borderColor: '#939398',
    },
  },
}));

export default function LocationSelection({
  logoUrl,
  onBackScreen,
  onSelectLocation,
}) {
  const classes = useStyles();
  const { effectiveLocations, surveyConfig } = useContext(DataContext);

  const { isKiosk } = useKiosk();
  const displayFontSizeSelector = isKiosk ?
    _.get(surveyConfig, "showFontSizeSelectorKiosk", false)
    :
    _.get(surveyConfig, "showFontSizeSelectorMobile", false);

  const title =
    _.get(surveyConfig, 'userInfo.selectLocationTitle') ||
    strings.locationSelectionDefaultTitle;
  const [locationId, setLocationId] = useState('');

  const onNextClicked = useCallback(() => {
    onSelectLocation({ location: locationId });
  }, [onSelectLocation, locationId]);

  const changeLocation = useCallback(
    (newLocationId) => {
      const previousLocationId = locationId;
      setLocationId(newLocationId);

      if (!!newLocationId && previousLocationId === newLocationId) {
        onNextClicked();
      }
    },
    [locationId, onNextClicked]
  );

  const nextDisabled = !locationId;

  return (
    <Grid container className={classes.topContainer}>
      {/* Logo container */}
      <Grid item className={classes.header}>
        <Grid container alignItems='center' spacing={2}>
          <Grid item>
            <IconButton
              color="primary"
              onClick={() => onBackScreen('locationSelectionPage')}
            >
              <ChevronLeft />
            </IconButton>
          </Grid>
          <Grid item xs>
            <div style={{ height: '100px' }}>
              <img src={logoUrl} alt="Client Logo" className={classes.logo} />
            </div>
          </Grid>
          <Grid item>
            <FontSizeSelector
              variant="menu"
              display={displayFontSizeSelector}
            />
          </Grid>
        </Grid>
      </Grid>

      <div style={{ flexGrow: '1' }} />

      <Typography
        variant="h5"
        color="primary"
        className={classes.headerText}
        style={{ display: 'flex', textAlign: 'center'}}
      >
        {title}
      </Typography>

      <div className={classes.buttons}>
        {_.map(effectiveLocations, (l) => (
          <Button
            color="primary"
            variant="outlined"
            key={l.id}
            value={l.id}
            className={`${classes.button} ${
              l.id === locationId ? classes.buttonSelected : ''
            }`}
            onClick={() => changeLocation(l.id)}
          >
            {l.name}
          </Button>
        ))}
      </div>

      <div style={{ flexGrow: '1' }} />

      <div className={classes.bottomAnchored}>
        <Button
          variant="contained"
          color="primary"
          className={classes.submitButton}
          onClick={onNextClicked}
          disabled={nextDisabled}
        >
          {strings.next}
        </Button>
      </div>
    </Grid>
  );
}
