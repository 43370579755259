import _ from 'lodash';

const processItems = (items, language) => _.map(items, (i) => ({
  ...i.properties,

  // Try to find the proper language value and store it in name, so the app
  // does not have to bother with languages (at least for this)
  name: _.get(i, `properties.languages.${language}`) || i.properties.name,
}));

// Gives a better name for locations and units
export const formatLocations = processItems;
export const formatUnits = processItems;

export const locationNameFromId = _.curry((locations, id) => {
  const location = _.find(locations, { id });
  if (location && location.name) {
    return location.name;
  }

  return id;
});

export const unitNameFromId = _.curry((units, id) => {
  const unit = _.find(units, { id });
  if (unit && unit.name) {
    return unit.name;
  }

  return id;
});