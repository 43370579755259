import {DeferredPromise} from 'hive-client-utils';

const _ = require('lodash');


export async function invokeAndWaitForInvocationReaction(bee, invokeFn = _.identity, processReactionFn = _.identity, timeout = 30000) {

  let { promise, resolveFn, rejectFn } = DeferredPromise();

  let invocationId = undefined;
  let timeoutId;

  let reactionQueue = [];
  let setInvocationId = id => {
    invocationId = id;
    // Check if we received already the reaction
    let reaction = _.find(reactionQueue, (r) => _.includes(r.cc, `invocation.${invocationId}`));

    if (reaction) {
      // We found it
      if (timeoutId) {
        clearTimeout(timeoutId);
      }
      return resolveFn(reaction);
    }

    // Reaction has not been received yet, we'll cath it in the call back
    return;
  };

  // Listen to reaction for fetchClientInfo
  let callbackId = bee.reactions.setCallback(`core`, reaction => {
    if (invocationId) {
      // CHeck if we received the reaction for the right invocation ID
      if (_.includes(reaction.cc, `invocation.${invocationId}`)) {
        if (timeoutId) {
          clearTimeout(timeoutId);
        }
        return resolveFn(reaction);
      }

      // Not the reaction we are waiting for, wait for the next one
      return;
    }

    // Invocation Id is not know yet, let's sae the reactions in a queue for later
    reactionQueue.push(reaction);
  });

  if (timeout) {
    const timeoutFn = () => rejectFn({ code: -1, message: `timeout waiting for reaction to ${invocationId}` });
    timeoutId = setTimeout(timeoutFn, timeout);
  }

  // Invoke the action
  let res = await invokeFn();
  setInvocationId(_.get(res, 'invocationId'));

  try {
    let reaction =  await promise;

    // Check the reaction, if there's a code, it means it failed
    const details = _.get(reaction, 'details', {});
    if (details.code) {
      throw details;
    } else {
      return processReactionFn(reaction);
    }

  } finally {
    bee.reactions.removeCallback(callbackId);
  }
}

export function extractInfo(id, numberItems = []) {
  return (paramsStr) => {
    const params = new URLSearchParams(paramsStr);

    const info = _.pickBy({ id });

    for (let [ key, value ] of params.entries()) {
      _.set(info, key, value);
    }

    numberItems = _.compact(_.castArray(numberItems));
    for (let numberItem of numberItems) {
      const value = _.get(info, numberItem);
      if (typeof(value) === 'string') {
        _.set(info, numberItem, parseInt(value));
      }
    }

    return info;
  };
}
