import React, { useCallback, useContext, useEffect, useMemo, useRef } from 'react';
import _ from 'lodash';
import { DataContext } from '../contexts/DataContext';
import {
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';

export function ExternalUnits({
  classes = {},
  locationId,
  unit = '',
  setUnit = () => {},
  setNeedsVisiting = () => {},
  unitPrompt,
  alwaysDisplay = false,
}) {
  const { surveyConfig, externalUnits } = useContext(DataContext);

  const units = useMemo(
    () =>
      _.filter(externalUnits, u => u.locationId === locationId),
    [externalUnits, locationId]
  );

  const unitIds = useMemo(() => _.map(units, 'id'), [units]);

  const handleUnitChange = useCallback(
    (event) => {
      setUnit(event.target.value);
      const unit = _.find(units, ['id', event.target.value]);
      const needsVisiting = _.get(unit, 'needsVisiting', false);
      setNeedsVisiting(needsVisiting);
    },
    [ setUnit ]
  );

  // Automatically select the unit
  const unitRef = useRef(unit);
  useEffect(
    () => {
      // We automatically select the unit if none of the following conditions occur:
      if (
        // We are using non-external units
        _.get(surveyConfig, 'userInfo.unit', false)

        // We are NOT using external units
        || !_.get(surveyConfig, 'userInfo.externalUnits', false)

        // The unit value is part of the possible values
        // Careful: nil/"" unit value returns false, so we need a proper value
        || (!!unitRef.current && _.includes(unitIds, unitRef.current))

        // We don't have a single unit
        || _.size(unitIds) !== 1
      ) {
        return;
      }

      unitRef.current = unitIds[0];
      setUnit(unitIds[0]);
    },
    [surveyConfig, unitIds, unitRef]
  );

  useEffect(
    () => {
      const u = _.find(units, ['id', unit]) || { needsVisiting: false };
      setNeedsVisiting(!!u.needsVisiting);
    },
    [units, unit]
  );

  if (_.get(surveyConfig, 'userInfo.unit', false)) {
    // Configuration is telling us to use the text based unit, so don't render
    return null;
  }
  if (!_.get(surveyConfig, 'userInfo.externalUnits', false)) {
    // Configuration is telling us to use the text based unit, so don't render
    return null;
  }

  if (units.length <= 1 && !alwaysDisplay) {
    // no need to force the user to select from zero/one units
    return null;
  }

  return (
    <Grid container>
      {unitPrompt}
      <FormControl variant="outlined" margin="dense" fullWidth>

        <InputLabel
          required
          id="external-unit-input-label"
          className={classes.textFieldInput}
        >
          {surveyConfig.userInfo.unitLabel}
        </InputLabel>

        <Select
          labelId="external-unit-select-label"
          label={surveyConfig.userInfo.unitLabel}
          value={unit}
          onChange={handleUnitChange}
          className={classes.textFieldInput}
        >
          {_.map(units, (u) => (
            <MenuItem key={u.id} value={u.id}>
              {u.name}
            </MenuItem>
          ))}
        </Select>

      </FormControl>
    </Grid>
  );
}
