const styleOverrides = {
  root: {
    fontSize: "1rem",
    textTransform: "none",
  }
};

export const MuiToggleButton = {
  ...styleOverrides,
};
