import Container from "@material-ui/core/Container";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { ThemeProvider, createTheme, makeStyles } from '@material-ui/core/styles';
import { Button } from "@material-ui/core";
import CloseIcon from '@material-ui/icons/Close';
import { useCookies } from "react-cookie";
import { useIdleTimer } from 'react-idle-timer';
import ReactHtmlParser from 'react-html-parser';
import { strings } from "../localizedStrings";
import { useCustomization } from "../customization/Customization";
import { HiveContext } from "../services/HiveContext";
import { calculateResult, questionAnswers } from '../util/testResults';
import InactivityWarningDialog from "../components/InactivityWarningDialog";

import WelcomePage from "./Welcome";
import NearMePage from "./NearMe";
import UserInfoPage from "./UserInfo";
import QuestionPage from "./Question";
import ReviewPage from "./Review";
import AcknowledgePage from "./Acknowledge";
import TemperaturePage from "./Temperature";
import ResultPage from "./Result";
import WarningPage from "./Warning";
import EmailValidationPage from "./EmailValidation";
import moment from "moment";
import { calculateDisabledColor } from "../util/colors";
import ConsentPage from "./Consent";
import { createHiveBee } from "../services/HiveService";
import {
  ConnectionService,
  utils as ConnectionServiceUtils,
} from "hive-client-utils";
import { DataContext } from "../contexts/DataContext";
import LocationSelection from "./LocationSelection";
import VisitInformation from "./visitInformation/VisitInformation";
import { visitInformationTheme } from "../theme/visitInformation/theme";
import { mainTheme } from "../theme/theme";
import { getKioskableValue } from "../util/getKioskableValue";
import { useKiosk } from "../hooks/useKiosk";

const { waitForInvocationReaction } = ConnectionServiceUtils;
const screenResults = require('../util/screenResults');
const _ = require('lodash');
const IS_NETFLIX = process.env.REACT_APP_ENTERPRISE_NAME === "netflix";

const useStyles = makeStyles(() => ({
  topContainer: {
    padding: "0px 0px",
    width: "100%",
    height: "100vh",    // fallback for browsers that do not support custom properties
    // eslint-disable-next-line no-dupe-keys
    height: "calc(var(--vh, 1vh) * 100)"
  },
  submittingSurvey: {
    zIndex: "100",
    display: "block",
    position: "absolute",
    left: "32px",
    right: "32px",
    border: "1px solid #077AD3",
    borderRadius: "4px",
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.75)",
    textAlign: "center",
    backgroundColor: "#00BAFF",
    paddingLeft: "32px",
    paddingTop: "16px",
    paddingRight: "32px",
    paddingBottom: "16px",
    color: "white"
  },
  validationError: {
    zIndex: "1000",
    display: "block",
    position: "absolute",
    left: "32px",
    right: "32px",
    border: "1px solid #D0021B",
    borderRadius: "4px",
    boxShadow: "0px 2px 6px 0px rgba(0,0,0,0.75)",
    textAlign: "center",
    backgroundColor: "#FB4F4F",
    paddingLeft: "32px",
    paddingTop: "16px",
    paddingRight: "32px",
    paddingBottom: "16px",
    color: "white"
  },
  validationErrorButton: {
    position: 'absolute',
    top: '50%',
    right: '0px',
    transform: 'translate(0, -50%)',
    color: 'white',
  },
  userAgent: {
    fontSize: "small"
  },
}));

export default function Home() {
  const classes = useStyles();
  const [cookies, setCookies] = useCookies(["language", "userInfo", "screenerUserId"]);
  // Need to keep language and userInfo in separate state variable in case cookies are blocked
  const [language, setLanguage] = useState(cookies.language);
  const [userInfo, setUserInfo] = useState(() => cookies.userInfo);

  const { isKiosk, locationId } = useKiosk();

  const configuration = useCustomization(cookies.language).surveyConfig;

  const visitInformation = _.get(configuration, 'visitInformation');
  const visitInformationSeparateFromUserInfo = useMemo(
    () => {
      if (!_.get(visitInformation, 'separateFromUserInfo', false)) {
        return false;
      }

      const reason = getKioskableValue(isKiosk, visitInformation, "reason");
      const unit = getKioskableValue(isKiosk, visitInformation, "unit");
      const visiting = getKioskableValue(isKiosk, visitInformation, "visiting");
      const arrivalTime = getKioskableValue(isKiosk, visitInformation, "arrivalTime");
      return reason || unit || visiting || arrivalTime;
    },
    [isKiosk, visitInformation]
  );

  const selectLocationOnSeparatePage = _.get(configuration, 'userInfo.selectLocationOnSeparatePage', false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorDetails, setErrorDetails] = useState();
  const { configActive, logoUrl } = useContext(HiveContext);
  const [alternateResult, setAlternateResult] = useState();
  const { effectiveLocations, externalUnits } = useContext(DataContext);

  const [submittingSurvey, setSubmittingSurvey] = useState(false);
  const [surveyResult, setSurveyResult] = useState();

  const [emailNeedsValidation] = useState(getKioskableValue(isKiosk, configuration, "emailNeedsValidation"));
  const [loading, setLoading] = useState(false);

  const [questionnaireStartTime, setQuestionnaireStartTime] = useState(moment());

  const QUESTIONNAIRE_METHOD = isKiosk ? "/kiosk" : "/";

  const NEAR_ME_THRESHOLD = 50;

  // Kiosk Inactivity timeout set to 2 minutes
  const KIOSK_INACTIVITY_TIMEOUT = _.get(configuration, "kioskInactivityTimeout", 1000 * 60 * 2);
  const KIOSK_RESULT_PAGE_INACTIVITY_TIMEOUT = _.get(configuration, "kioskResultPageInactivityTimeout", KIOSK_INACTIVITY_TIMEOUT);
  // Kiosk Inactivity timeout warning message
  const KIOSK_INACTIVITY_WARNING = _.get(configuration, "kioskInactivityWarning", Number.MAX_SAFE_INTEGER);
  const KIOSK_RESULT_PAGE_INACTIVITY_WARNING = _.get(configuration, "kioskResultPageInactivityWarning", KIOSK_INACTIVITY_WARNING);
  const [displayInactivityWarning, setDisplayInactivityWarning] = useState(false);
  const [countdown, setCountdown] = useState(KIOSK_INACTIVITY_TIMEOUT - KIOSK_INACTIVITY_WARNING);

  const userInfoHasData = () => {
    return _.some(_.values(userInfo), (value) => {
      if (typeof value === "object") {
        return !_.isEmpty(value);
      }
      return !!value;
    });
  };

  const unitName = useMemo(() => {
    const id = _.get(userInfo, 'unit') || "";
    const u = _.find(externalUnits, { id }) || { name: '' };
    return u.name;
  }, [externalUnits, userInfo]);

  // Keep which page is visible
  const [visiblePages, setVisiblePages] = useState({
    welcomePage: true,
    locationSelectionPage: false,
    nearMePage: false,
    userInfoPage: false,
    visitInformationPage: false,
    emailValidationPage: false,
    consentPage: false,
    questionPage: false,
    reviewPage: false,
    acknowledgePage: false,
    temperaturePage: false,
    resultPage: false,
    outbreakPage: false,
    capacityPage: false,
  });

  // In kiosk mode reload page after Inactivity timeout
  const handleOnIdle = event => {
    if (!isKiosk) {
      return;
    }
    if (visiblePages.welcomePage && !userInfoHasData()) {
      return;
    }
    console.log('user is idle, reload screener', event);
    setDisplayInactivityWarning(false);
    window.location.reload();
  };

  // In kiosk mode can display inactivity warning before page reload
  const handleOnIdleWarning = (warning, timeout) => {
    if (!isKiosk) {
      return;
    }
    if (visiblePages.welcomePage) {
      return;
    }
    if (warning >= timeout) {
      return;
    }
    console.log('user is idle, display warning', warning, timeout);
    setCountdown(timeout - warning);
    setDisplayInactivityWarning(true);
  };

  useEffect(
    () => {
      let interval;
      if (displayInactivityWarning) {
        const increment = 200;
        interval = setInterval(() => {
          setCountdown((prev) => prev - increment);
        }, increment);
      } else {
        clearInterval(interval);
      }
      return () => {
        clearInterval(interval);
      };
    },
    [displayInactivityWarning]
  );

  useEffect(() => {
    if (KIOSK_INACTIVITY_WARNING <= KIOSK_INACTIVITY_TIMEOUT
      && countdown <= 0) {
      handleOnIdle();
    }
  }, [countdown]);

  useIdleTimer({
    timeout: KIOSK_INACTIVITY_TIMEOUT,
    onIdle: () => {
      if (!visiblePages.resultPage) {
        handleOnIdle();
      }
    },
    debounce: 500
  });

  useIdleTimer({
    timeout: KIOSK_INACTIVITY_WARNING,
    onIdle: () => {
      if (!visiblePages.resultPage) {
        handleOnIdleWarning(KIOSK_INACTIVITY_WARNING, KIOSK_INACTIVITY_TIMEOUT);
      }
    },
    debounce: 500
  });

  useIdleTimer({
    timeout: KIOSK_RESULT_PAGE_INACTIVITY_TIMEOUT,
    onIdle: () => {
      if (visiblePages.resultPage) {
        handleOnIdle();
      }
    },
    debounce: 500
  });

  useIdleTimer({
    timeout: KIOSK_RESULT_PAGE_INACTIVITY_WARNING,
    onIdle: () => {
      if (visiblePages.resultPage) {
        handleOnIdleWarning(KIOSK_RESULT_PAGE_INACTIVITY_WARNING, KIOSK_RESULT_PAGE_INACTIVITY_TIMEOUT);
      }
    },
    debounce: 500
  });

  const clearError = () => {
    setErrorDetails(null);
    setErrorMsg(null);
  };

  // Create theme
  const appTheme = useMemo(() => createTheme({
    palette: {
      primary: {
        main: configuration.primaryColor,
      },
      text: {
        primary: configuration.primaryColor
      },
      action: {
        disabledBackground: calculateDisabledColor(configuration.primaryColor),
        disabled: "#fff", // "white" here breaks ToggleButtons
      }
    },
    overrides: {
      MuiMobileStepper: {
        dots: {
          margin: 'auto',
        },
        dot: {
          marginRight: '15px',
          backgroundColor: 'rgba(0, 0, 0, 0.23)'
        },
      },
    },
  }, mainTheme, visitInformationTheme), []);

  let qFlow = configuration.surveyFlow;

  const [userAnswers, setUserAnswers] = useState(Array(qFlow.length).fill([-1]));
  const [customUserFields, setCustomUserFields] = useState({});

  const [nearMeHasSeparatePage, setNearMeHasSeparatePage] = useState(false);
  const [nearMeShowsFilter, setNearMeShowsFilter] = useState(true);

  useEffect(() => {
    moment.locale(language);
  }, [language]);

  const initUserInfo = () => {
    setCookies("userInfo", {
      firstName:"",
      lastName:"",
      email:"",
      phone:"",
      unit:"",
      type:"",
      location:"",
      otherLocation:"",
      customFields:{},
    });
    setUserInfo({
      firstName:"",
      lastName:"",
      email:"",
      phone:"",
      unit:"",
      type:"",
      location:"",
      otherLocation:"",
      customFields:{}
    });
  };

  // Only fires on mount. If we are in kiosk mode or if there are no userInfo from the cookies, we set them to empty strings
  useEffect(() => {
    if (!userInfo || isKiosk) {
      initUserInfo();
    } else {
      clearNonRequiredUserInfoPropertyValues();
    }
  }, []);

  const clearNonRequiredUserInfoPropertyValues = () => {
    const usingLocations = configuration.userInfo.locations || configuration.userInfo.externalLocations;
    const usingUnits = configuration.userInfo.unit || configuration.userInfo.externalUnits;

    const email = configuration.userInfo.email ? userInfo.email : "";
    const phone = configuration.userInfo.phone ? userInfo.phone : "";
    const unit = usingUnits ? userInfo.unit : "";
    const type = configuration.userInfo.types ? userInfo.type : "";
    const location = usingLocations ? userInfo.location : "";
    const otherLocation = usingLocations ? userInfo.otherLocation : "";

    updateUserInfo({
      firstName: userInfo.firstName,
      lastName: userInfo.lastName,
      email,
      phone,
      unit,
      type,
      location,
      otherLocation,
      customFields: userInfo.customFields,
      allowSmsNotifications: userInfo.allowSmsNotifications
    });
  };

  const resetNearMeFlow = useCallback(() => {
    // check if all config locations have coordinates
    const allLocationDataPresent = _.every(effectiveLocations, location => !!location.coordinates);

    // determine whether or not to show the near me page
    if (effectiveLocations.length >= NEAR_ME_THRESHOLD) {
      setNearMeHasSeparatePage(true);
      if (!allLocationDataPresent) {
        setNearMeShowsFilter(false);
      }
    } else {
      setNearMeHasSeparatePage(allLocationDataPresent);
    }
  }, [configuration, effectiveLocations]);

  const [locationValid,setLocationValid] = useState(false);

  const getFirstPageAfterWelcome = (nextPage) => {

    if (isKiosk) {
      if (selectLocationOnSeparatePage) {
        const location = _.find(effectiveLocations, { id: locationId });
        setLocationValid(location);
        if (!location) {
          return 'locationSelectionPage';
        }
        updateUserInfo({
          ...userInfo,
          location: locationId,
        });
        return 'userInfoPage';
      }
    }

    if (selectLocationOnSeparatePage) {
      return 'locationSelectionPage';
    }

    if (nearMeHasSeparatePage) {
      return 'nearMePage';
    }

    return nextPage;
  };

  const onSetLanguage = (l) => {
    // Set the cookie for 1 year (31536000 seconds)
    setCookies("language", l, { path: "/", maxAge: 31536000 });
    setLanguage(l);
    strings.setLanguage(l);
  };

  const updateQuestionnaireStartTime = async () => {
    let bee;
    try {
      [bee] = await createHiveBee(process.env.REACT_APP_BEE_NAME, process.env.REACT_APP_BEE_PASS);
      await bee.actions.invoke('screening.requestHiveTimeNow')
        .then(waitForInvocationReaction(bee, r => setQuestionnaireStartTime(moment(r.details.message))));

      await ConnectionService.releaseBee(bee);
    } catch (e) {
      console.log("Error: " + e);
      await ConnectionService.releaseBee(bee);
      return;
    }

  };

  const onStartSurvey = () => {
    let startPage = getFirstPageAfterWelcome('userInfoPage');
    setVisiblePages(updateVisiblePage(startPage));
    updateQuestionnaireStartTime();
  };

  const updateUserInfo = (newUserInfo) => {
    // Set the cookie for 1 year (31536000 seconds)
    // Don't save cookies if in kiosk mode
    if (!isKiosk) {
      setCookies("userInfo", newUserInfo, { path: "/", maxAge: 31536000 });
    }
    setUserInfo(newUserInfo);
    setCustomUserFields(newUserInfo.customFields);  // Custom Fields are not saved in Cookies for now
  };

  const onSelectLocation = (location, aborted = false) => {
    if (!aborted) {
      updateUserInfo({
        ...userInfo,
        location: location.location,
      });
    } else {
      setNearMeHasSeparatePage(false);
    }
    setVisiblePages(updateVisiblePage('userInfoPage'));
  };

  const onSetUserInfo = async (newUserInfo) => {

    updateUserInfo(newUserInfo);
    const { email, firstName } = newUserInfo;

    if (_.get(configuration, "allowOnlyRegisteredUsers", false)) {
      let bee;
      try {
        [bee] = await createHiveBee(process.env.REACT_APP_BEE_NAME, process.env.REACT_APP_BEE_PASS);
        await bee.actions.invoke('screening_groupManagement.validateRegisteredUser', email)
          .then(waitForInvocationReaction(bee, r => r.details.code));

        await ConnectionService.releaseBee(bee);
      } catch (e) {
        if (_.get(e, "code") === -1) {
          // This means the error is due to not being registered and not due to some connection issue
          setAlternateResult({
            rawResultCode: screenResults.FAIL,
            alternateDeniedText: ReactHtmlParser(strings.notRegisteredUserDenied),
          });
          setVisiblePages(updateVisiblePage('resultPage'));
        }
        await ConnectionService.releaseBee(bee);
        return;
      }
    }

    if (email && emailNeedsValidation) {
      setLoading(true);
      let bee;
      let result;
      try {
        [bee] = await createHiveBee(process.env.REACT_APP_BEE_NAME, process.env.REACT_APP_BEE_PASS);
        result = await checkEmailNeedsValidation(bee, firstName, email, cookies.language);
        await ConnectionService.releaseBee(bee);
      } catch (e) {
        setLoading(false);
        console.error(e);
        await ConnectionService.releaseBee(bee);
      }
      if (_.get(result, "needsValidation", false)) {
        setCookies("screenerUserId", result.screenerUserId);
        setVisiblePages(updateVisiblePage('emailValidationPage'));
        setLoading(false);
        return;
      }
      setLoading(false);
    }

    if (visiblePages.userInfoPage && visitInformationSeparateFromUserInfo) {
      setVisiblePages(updateVisiblePage('visitInformationPage'));
      return;
    }

    if (configuration.consent) {
      setVisiblePages(updateVisiblePage('consentPage'));
    } else {
      setVisiblePages(updateVisiblePage('questionPage'));
    }
  };

  const checkEmailNeedsValidation = (bee, firstName, email, language) => {
    return bee.actions.invoke('screening_client.checkEmailNeedsValidation', firstName, email, language || "en")
      .then(waitForInvocationReaction(bee, r => r.details.message));
  };

  const onUserValidated = () => {
    if (configuration.consent) {
      setVisiblePages(updateVisiblePage("consentPage"));
    } else if (visitInformationSeparateFromUserInfo) {
      setVisiblePages(updateVisiblePage('visitInformationPage'));
    } else {
      setVisiblePages(updateVisiblePage("questionPage"));
    }
  };

  const onConsentAccepted = useCallback(() => {
    setVisiblePages(updateVisiblePage('questionPage'));
  }, []);

  const onConsentRejected = useCallback(() => {
    setVisiblePages(updateVisiblePage('resultPage'));
    setAlternateResult({
      rawResultCode: screenResults.FAIL,
      alternateDeniedText: configuration.consent.deniedText,
    });
  }, [configuration]);

  const onQuestionsAnswered = (answers, lastQuestion, skipReviewAndAcknowledged) => {
    setUserAnswers(answers);
    // configuration specific instructions
    const showReviewPage = _.get(configuration, "showReviewPage", true);
    const showAcknowledgePage = _.get(configuration, "showAcknowledgePage", true);
    const submitImmediately = !showReviewPage && !showAcknowledgePage;

    // If a question, has an exit set to true, jump to the result page
    // We let the useEffect handle the transition to the result page so we are sure that the results have been reported
    if (qFlow[lastQuestion].exit || submitImmediately) {
      reportResult(answers, 0.0);
    } else if (!showReviewPage) {
      setVisiblePages(updateVisiblePage('acknowledgePage'));
    } else if (skipReviewAndAcknowledged) {
      setVisiblePages(updateVisiblePage('acknowledgePage'));
      reportResult(answers, 0.0);
    } else {
      setVisiblePages(updateVisiblePage('reviewPage'));
    }
  };

  const onReviewDone = (answers, completedCb = _.identity) => {
    setUserAnswers(answers);
    // If the property is set to true or the property isn't in the survey because the default is true
    if (configuration.showAcknowledgePage || !("showAcknowledgePage" in configuration)) {
      setVisiblePages(updateVisiblePage('acknowledgePage'));
      completedCb();
    } else {
      onAcknowledged(completedCb);
    }
  };

  const onAcknowledged = (completedCb = _.identity) => {
    // This is the very last case where the client needs to internally calculate the result of the survey.
    // The idea someday is to remove the duplicate business logic in favour of moving it all to the backend where it normally belongs.
    // TODO develop an mechanism in Honey where the draft answers are sent to the backend and a reaction is sent indicating that a temperature check is required, which brings up the temperature check page and provides a final submission.
    let res = calculateResult(qFlow, userAnswers, userInfo);

    // Check if we have the take temperature extra step
    if ((res === screenResults.PASS) && configuration.temperatureCheck) {
      setVisiblePages(updateVisiblePage('temperaturePage'));
      completedCb();
    } else {
      setErrorMsg(null);
      setErrorDetails(null);
      reportResult(userAnswers, 0.0, completedCb);
    }
  };

  const onTemperatureSet = (temp) => {
    reportResult(userAnswers, temp);
  };

  const onRepeatSurvey = useCallback(
    (disablePeriod) => {
      switch (Math.floor(disablePeriod / 60)) {
        case 0:
          disablePeriod = strings.repeatSurveyPeriodMinutes.replace('<locked period>', disablePeriod);
          break;

        case 1:
          disablePeriod = strings.repeatSurveyPeriodHoursSingular.replace('<locked period>', disablePeriod / 60);
          break;

        default:
          disablePeriod = strings.repeatSurveyPeriodHoursPlural.replace('<locked period>', disablePeriod / 60);
          break;
      }

      let resultMsg = IS_NETFLIX ? strings.repeatSurveyMessageNetflix : strings.repeatSurveyMessage;
      resultMsg = resultMsg
        .replace('<locked period>', disablePeriod)
      ;

      let alternateDeniedText = IS_NETFLIX ? strings.repeatSurveyDeniedTextNetflix : strings.repeatSurveyDeniedText;
      alternateDeniedText = alternateDeniedText
        .replace('<locked period>', disablePeriod)
      ;

      setAlternateResult({
        rawResultCode: screenResults.FAIL,
        resultMsg,
        alternateDeniedText,
      });

      setVisiblePages(updateVisiblePage('resultPage'));

      setSubmittingSurvey(false);
    },
    [ strings ]
  );

  const onLocationOrUnitOutbreak = useCallback(
    (reason) => {
      switch (reason) {
        default:
        case 'outbreak':
          setVisiblePages(updateVisiblePage('outbreakPage'));
          break;

        case 'capacity':
          setVisiblePages(updateVisiblePage('capacityPage'));
          break;
      }

      setSubmittingSurvey(false);
    },
    [ strings ]
  );

  const reportResult = async (answers, temp, completedCb = _.identity) => {
    // build the answer array
    let formattedAnswers = [];
    for (let i = 0; i < qFlow.length; i++) {
      const currentFlow = qFlow[i];

      if (currentFlow.questionType === 'TextInput') {
        formattedAnswers.push({
          questionType: currentFlow.questionType,
          answers: [questionAnswers.N_A],
          stringAnswer: _.first(answers[i])
        });
      } else if (currentFlow.questionType === 'DateInput') {

        let resultCode;
        let stringAnswer;

        if (answers[i][0] === questionAnswers.N_A_STRING) {
          stringAnswer = questionAnswers.N_A_STRING;
          resultCode = -1;
        } else {
          const userInputDate = moment(answers[i][0]);
          const today = moment();
          const diffDays = today.diff(userInputDate, "days");
          const beforeThreshold = _.parseInt(currentFlow.thresholdValues.before);
          const afterThreshold = _.parseInt(currentFlow.thresholdValues.after);


          if (diffDays >= beforeThreshold) {
            resultCode = questionAnswers.BEFORE;
          } else if (diffDays <= afterThreshold) {
            resultCode = questionAnswers.AFTER;
          } else {
            resultCode = questionAnswers.BETWEEN;
          }

          stringAnswer = userInputDate.format("YYYY-MM-DD"); // ISO 8601
        }

        formattedAnswers.push({
          questionType: currentFlow.questionType,
          answers: [resultCode],
          stringAnswer,
        });
      } else {
        formattedAnswers.push({
          questionType: currentFlow.questionType,
          answers: answers[i]
        });
      }
    }


    let location = userInfo.location;
    // Handle user specified location
    if ( configuration.userInfo.otherLocation) { // This check makes sure data is formatted correctly if you to use location value "Other" without allowing user input
      if (location === "Other") {
        location = `Other: ${userInfo.otherLocation}`;
      }
    }

    setSubmittingSurvey(true);

    let bee;
    try {
      [bee] = await createHiveBee(process.env.REACT_APP_BEE_NAME, process.env.REACT_APP_BEE_PASS);

      const visitInformation = _.pickBy({
        reason: userInfo.reason,
        visiting: userInfo.visiting,
        arrivalTime: userInfo.arrivalTime ? moment(userInfo.arrivalTime).utc().toISOString() : undefined,
      });

      const results = {
        firstName: userInfo.firstName,
        lastName: userInfo.lastName,
        phoneNumber: userInfo.phone,
        email: userInfo.email,
        unit: userInfo.unit,
        type: userInfo.type,
        location: location,
        temperature: temp,
        questionnaireStartTime: moment(questionnaireStartTime).utc().toISOString(),
        questionnaireMethod: QUESTIONNAIRE_METHOD,
        customUserFields: customUserFields,
        answersV2: formattedAnswers,
        allowSmsNotifications: _.isNil(userInfo.allowSmsNotifications) ? true : userInfo.allowSmsNotifications,
        ...visitInformation,
      };

      // Note that this will independently determine the result and send appropriate emails
      let surveyResult = await bee.actions.invoke('screening.addSurveyResult', moment().utcOffset(), (language || "en"), results)
        .then(waitForInvocationReaction(bee, r => r.details.message, 30000));

      setSurveyResult(surveyResult);

      setVisiblePages(updateVisiblePage('resultPage'));

      await ConnectionService.releaseBee(bee);
    } catch (e) {
      console.error("Error submitting survey:", e);
      if (bee) {
        await ConnectionService.releaseBee(bee).catch(console.error);
      }

      const disablePeriod = _.get(e, 'message.disablePeriod');
      const lastSurveyTime = _.get(e, 'message.lastSurveyTime');
      if (disablePeriod && lastSurveyTime) {
        onRepeatSurvey(disablePeriod, lastSurveyTime);
        return;
      }

      setErrorMsg(strings.submitErrorMsg);
      let details = _.isString(e)
        ? e
        : _.get(e, 'message')
          || _.get(e, 'content.message')
          || _.get(e, 'error_description')
      ;
      if (details) {
        details = <div>
          <pre>{details}</pre>
          {
            navigator && navigator.userAgent &&
            <div className={classes.userAgent}>{navigator.userAgent}</div>
          }
        </div>;
      }
      setErrorDetails(details);
    }

    // Remove values we don't want persist in the cookies
    // TODO: Use custom fields, if you need to have fields that are not persisted in Cookies
    if (configuration.userInfo.enterUnitAlways || configuration.userInfo.enterLocationAlways || configuration.userInfo.enterTypeAlways) {
      let newUserInfo = { ...userInfo };

      if (configuration.userInfo.enterUnitAlways) {
        // Remove the unit from the saved cookie
        newUserInfo.unit = "";
      }
      if (configuration.userInfo.enterLocationAlways) {
        // Remove the location from the saved cookie
        newUserInfo.location = "";
        newUserInfo.otherLocation = "";
      }
      if (configuration.userInfo.enterTypeAlways) {
        // Remove the type from the saved cookie
        newUserInfo.type = "";
      }

      if (configuration.userInfo.enterCustomFieldsAlways) {
        // Remove the custom fields from the saved cookie
        newUserInfo.customFields = {};
      }

      setCookies("userInfo", newUserInfo, { path: "/", maxAge: 31536000 });
    }

    setSubmittingSurvey(false);
    completedCb();
  };

  const onDone = () => {
    // Instead of going back to the Welcome page, Force a reload of the browser. Like
    // that if users keep their browser page opens to use the app every day, they will get the
    // latest version the next day.
    window.location.reload();
  };

  const onOutbreakPageContinue = () => {
    setAlternateResult({
      rawResultCode: screenResults.FAIL,
      alternateDeniedText: strings.outbreak.resultPageText,
    });
    setVisiblePages(updateVisiblePage('resultPage'));
  };

  const onCapacityPageContinue = () => {
    onSetUserInfo(userInfo);
  };

  // Call when the user click the back arrow want to go back
  const onBackScreen = (origin) => {
    if (origin === "nearMePage") {
      setVisiblePages(updateVisiblePage('welcomePage'));
    } else if (origin === 'locationSelectionPage') {
      setVisiblePages(updateVisiblePage('welcomePage'));
    } else if (origin === "userInfoPage") {
      const previousPage = locationValid? 'welcomePage': getFirstPageAfterWelcome('welcomePage');
      setVisiblePages(updateVisiblePage(previousPage));
    } else if (origin === "visitInformationPage") {
      setVisiblePages(updateVisiblePage('userInfoPage'));
    } else if (origin === 'consentPage') {
      if (visitInformationSeparateFromUserInfo) {
        setVisiblePages(updateVisiblePage('visitInformationPage'));
      } else {
        setVisiblePages(updateVisiblePage('userInfoPage'));
      }
    } else if (origin === "questionPage") {
      if (configuration.consent) {
        setVisiblePages(updateVisiblePage('consentPage'));
      } else {
        setVisiblePages(updateVisiblePage('userInfoPage'));
      }
    } else if (origin === "reviewPage") {
      setVisiblePages(updateVisiblePage('questionPage'));
    } else if (origin === "acknowledgePage") {
      setVisiblePages(updateVisiblePage('reviewPage'));
    } else if (origin === "emailValidationPage") {
      setVisiblePages(updateVisiblePage("userInfoPage"));
    }
  };

  const updateVisiblePage = (page) => {
    let pages = {
      welcomePage: false,
      nearMePage: false,
      userInfoPage: false,
      visitInformationPage: false,
      consentPage: false,
      questionPage: false,
      reviewPage: false,
      acknowledgePage: false,
      resultPage: false,
      outbreakPage: false,
    };
    _.set(pages, page, true);
    return pages;
  };

  return (
    <Container className={classes.topContainer}>
      <ThemeProvider theme={appTheme}>
        {submittingSurvey &&
          <div className={classes.submittingSurvey}>
            {strings.submittingSurvey}
          </div>
        }

        {(errorMsg || errorDetails) &&
          <div className={classes.validationError}>
            <Button className={classes.validationErrorButton} onClick={clearError}>
              <CloseIcon />
            </Button>
            {errorMsg}
            {errorDetails}
          </div>
        }

        {visiblePages.welcomePage && <div>
          <WelcomePage
            selfServe={process.env.REACT_APP_SELF_SERVE === 'true'}
            configActive={configActive}
            language={language}
            strings={strings}
            supportedLanguage={configuration.supportedLanguages}
            onSetLanguage={onSetLanguage}
            onStartSurvey={onStartSurvey}
            logoUrl={logoUrl}
            reset={resetNearMeFlow}
          />
        </div>}

        {
          visiblePages.locationSelectionPage &&
          <LocationSelection
            logoUrl={logoUrl}
            onBackScreen={onBackScreen}
            onSelectLocation={onSelectLocation}
          />
        }

        {visiblePages.nearMePage && <div>
          <NearMePage
            locations={configuration.userInfo.locationsList}
            onSelectLocation={onSelectLocation}
            onBackScreen={onBackScreen}
            logoUrl={logoUrl}
            showFilter={nearMeShowsFilter}
          />
        </div>}

        {visiblePages.userInfoPage && <div>
          <UserInfoPage
            language={language}
            userInfo={userInfo}
            onSetUserInfo={onSetUserInfo}
            onBackScreen={onBackScreen}
            didNearMeAlready={nearMeHasSeparatePage}
            isKiosk = {isKiosk}
            isLoading = {loading}
            onRepeatSurvey={onRepeatSurvey}
            onLocationOrUnitOutbreak={onLocationOrUnitOutbreak}
          />
        </div>}

        {
          visiblePages.visitInformationPage &&
          <VisitInformation
            language={language}
            userInfo={userInfo}
            onSetUserInfo={onSetUserInfo}
            onBackScreen={onBackScreen}
            isKiosk={isKiosk}
            onRepeatSurvey={onRepeatSurvey}
            onLocationOrUnitOutbreak={onLocationOrUnitOutbreak}
          />
        }

        {visiblePages.emailValidationPage &&
          <div>
            <EmailValidationPage
              userEmail={userInfo.email}
              onBackScreen={onBackScreen}
              onValidated={onUserValidated} >
            </EmailValidationPage>
          </div>

        }

        {visiblePages.consentPage && <div>
          <ConsentPage
            language={language}
            onBackScreen={onBackScreen}
            onAccepted={onConsentAccepted}
            onRejected={onConsentRejected}
          />
        </div>}

        {visiblePages.questionPage && <div>
          <QuestionPage
            language={language}
            onQuestionsAnswered={onQuestionsAnswered}
            onBackScreen={onBackScreen}
            userInfo={userInfo}
          />
        </div>}

        {visiblePages.reviewPage && <div>
          <ReviewPage
            language={language}
            answers={userAnswers}
            onReviewDone={onReviewDone}
            onBackScreen={onBackScreen}
          />
        </div>}

        {visiblePages.acknowledgePage && <div>
          <AcknowledgePage
            language={language}
            onAcknowledged={onAcknowledged}
            onBackScreen={onBackScreen}
          />
        </div>}

        {visiblePages.temperaturePage && <div>
          <TemperaturePage
            language={language}
            onTemperatureSet={onTemperatureSet}
          />
        </div>}

        {visiblePages.resultPage && <div>
          <ResultPage
            language={language}
            result={surveyResult || alternateResult}
            userInfo={userInfo}
            userInfoConfiguration={configuration.userInfo}
            logoUrl={logoUrl}
            timestamp={moment()}
            onDone={onDone}
          />
        </div>}

        {displayInactivityWarning &&
          <InactivityWarningDialog
            handleChangeOpen={setDisplayInactivityWarning}
            inactivityRedirect={() => window.location.reload()}
            countdown={countdown}
          />
        }

        {
          visiblePages.outbreakPage &&
          <WarningPage
            title={strings.outbreak.title}
            language={language}
            result={{
              rawResultCode: screenResults.FAIL,
              resultMsg: strings.outbreak.message1,
              alternateDeniedText: strings.outbreak.message2,
            }}
            logoUrl={logoUrl}
            onContinue={onOutbreakPageContinue}
          />
        }

        {
          visiblePages.capacityPage &&
          <WarningPage
            title={strings.capacity.title}
            language={language}
            result={{
              rawResultCode: screenResults.PASS_RESTRICTION,
              resultMsg: strings.capacity.message1.replace('<unit name>', unitName),
              alternateDeniedText: strings.capacity.message2,
            }}
            logoUrl={logoUrl}
            onContinue={onCapacityPageContinue}
          />
        }

      </ThemeProvider>
    </Container>
  );
}
