import React from "react";

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, redirectToCreateAccount: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    console.error(error);
    return { hasError: true, redirectToCreateAccount: error.redirectToCreateAccount };
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <p>{this.state.error}</p>;
    }

    return this.props.children;
  }
}