const _ = require("lodash");
const { ConnectionService } = require('hive-client-utils');
const { invokeAndWaitForInvocationReaction } = require('../util/reactions_utils');
const formatErr = e => (_.isError(e) ? e.stack : JSON.stringify(e, null, 2));

const HIVE_HOST = process.env.REACT_APP_HIVE_HOST;
const ORG_ID = process.env.REACT_APP_HIVE_ORG_ID;
const APP_ID = process.env.REACT_APP_HIVE_APP_ID;

const getOrgName = () => {
  const hostname = window.location.hostname;
  const comps = hostname.split('.');
  return comps && comps.length > 0 ? comps[0] : undefined;
};

const fetchClientInfo = async (bee) => {
  const orgName = getOrgName();

  let info = await invokeAndWaitForInvocationReaction(bee, () => bee.actions.invoke('provisioning.getClientInfo', orgName), r => r.details.message);
  return info;
};

export async function createHiveBee(beeUsername, beePassword) {
  // Don't connect to MHC if we all the required parameters are not provided
  if (!beeUsername || !beePassword || !ORG_ID || !APP_ID || !HIVE_HOST) {
    return null;
  }

  let configActive = true;
  let orgName = 'healthQ';
  let orgId = ORG_ID;
  let username = beeUsername;
  let password = beePassword;
  let newBeeFn = ConnectionService.newBeeAsBee;

  // For self-serve, we need to fetch some information about the sub-org/domain
  if (process.env.REACT_APP_SELF_SERVE === 'true') {
    let bee;
    try {
      const config = {
        host: HIVE_HOST,
        orgId: ORG_ID,
        appId: APP_ID,
        categories: [ 'core' ],
      };
      bee = await ConnectionService.newBeeAsBee(config, username, password);
      let info = await fetchClientInfo(bee);
      console.log('info=',info);
      await ConnectionService.releaseBee(bee);

      configActive = info.active;
      orgName = info.orgName;
      orgId = info.orgId;
      newBeeFn = ConnectionService.newBeeAsUser;
      username = process.env.REACT_APP_SCREENER_USER_NAME;
      password = process.env.REACT_APP_SCREENER_USER_PASS;
    } catch (e) {
      console.error('Could not fetch information', e);
      if (bee) {
        await ConnectionService.releaseBee(bee);
      }
      throw e;
    }
  }

  console.info(`authenticating bee: ${username}`);
  try {
    const config = {
      host: HIVE_HOST,
      orgId: orgId,
      appId: APP_ID,
      categories: [ 'core', 'invocation' ],
    };

    const bee = await newBeeFn(config, username, password);
    console.info(`authenticated, initializing bee...`);

    return [bee, configActive, orgName];
  } catch (e) {
    console.error(`Unable to initialize bee: ${formatErr(e)}`);
    throw e;
  }
}
