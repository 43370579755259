export let PrivacyPolicy_en = "\
July 1 2020\
<br><br>\
Macadamian (“Macadamian”, “we”, “us” or “our”) is committed to respecting your privacy and protecting your personal information.\
<br><br>\
The purpose of this Privacy Policy (hereinafter the “Policy”) is to inform you of how Macadamian collects, uses, discloses, retains, and protects your personal information.\
<br><br>\
To provide you with quality services, we need to have access to certain personal information about you. We make sure that our employees manage this information with all the necessary discretion and diligence in compliance with the legal and regulatory requirements in force.\
<br><br>\
When using our services, you consent to the hereby Policy.\
<br><br>\
1.	PERSONAL INFORMATION<br><br>\
We define “personal information” as any information about an identifiable individual, or that taken alone or combined with other data, allows an individual to be identified.\
<br><br>\
2.	COLLECTION OF PERSONAL INFORMATION<br><br>\
During our interactions with you, whether it be while providing our services or during our business activities, we must collect certain personal information about you. The personal information we collect is necessary to provide you with the requested services. This includes, but is not limited to:\
<br>\
<ul>\
<li>Your contact information such as name, address, email, or phone number;</li>\
<li>Business and contact information such as the company for whom you work for, the department and the location of the place where you will be working for the day;</li>\
<li>Information related to your health such as symptoms, your physical conditions or any other information in connection with COVID-19 virus;</li>\
<li>Information related to the use of our web application including technical information about visits or other information collected through cookies or similar tools;</li>\
<li>Any other personal information provided.</li>\
</ul>\
<br>\
3.	CONSENT<br><br>\
Generally, Macadamian will obtain the necessary personal information directly from you, with your consent, subject to the exceptions provided by law.\
<br><br>\
By providing us with personal information, whether through our web application, by telephone, e-mail, in person or by any other means, YOU CONSENT TO THE COLLECTION, USE AND DISCLOSURE OF YOUR PERSONAL INFORMATION AS PROVIDED IN THIS POLICY.\
<br><br>\
We may also collect personal information from third parties or from publicly available records as permitted by law or where we have obtained consent.\
<br><br>\
Macadamian will not collect, use, or disclose personal information without your consent unless it is authorized or required to do so by applicable regulations. For example, Macadamian may need to do so without your consent for the following reasons:\
<ul>\
<li>When, for security, medical or legal reasons, it is impossible for us to obtain your consent;</li>\
<li>When, for reasons of fraud detection or prevention or for law enforcement, seeking consent would defeat the purpose of obtaining your consent.</li>\
</ul>\
<br>\
Please note that you have the right to refuse to provide us with personal information. You also have the right, subject to reasonable notice and applicable legal or contractual restrictions, to withdraw your consent regarding the use of your personal information already collected by contacting our Privacy Officer. The refusal to disclose information or withdrawal of your consent may prevent us from providing or continuing to provide you with some of our services.\
<br><br>\
Macadamian may also seek your consent for secondary purposes. As mentioned above, we will ask for your consent in this regard. In addition, your consent for secondary purposes may be withdrawn at any time, without affecting the rendering of our services to you.\
<br><br>\
4.	PURPOSES OF PERSONAL INFORMATION COLLECTION<br><br>\
Macadamian collects, uses, and discloses personal information for the purposes identified at the time of collection or as permitted or required by regulations. This includes, but is not limited to, the following purposes:\
<ul>\
<li>To answer the survey’s questions;\
<li>To analyze the survey’s answers;</li>\
<li>To share personal information with your employer when analyzing the surveys;</li>\
<li>To send occasional communications regarding your account or the web application via e-mail;</li>\
<li>To facilitate the use of our web application and ensure its applicability, to monitor the use of our web application, to answer questions submitted through our web application;</li>\
<li>To conduct development and research to ensure that we maintain the highest standard of security, to understand our clients’ requirements and to improve our service offer;</li>\
<li>As permitted or required by any applicable legal or regulatory obligations or provisions;</li>\
<li>Any other purpose to which you have consented.</li>\
</ul>\
a)	Use of cookies<br><br>\
A cookie is a small data file created by your browser and placed on your computer when you visit a website, a page or a web application. Cookies allow web applications to store certain information about you to facilitate your access and browsing of the Internet within our sites. Cookies can only be read by the web application that sends them to your computer.\
<br><br>\
We use certain cookies on the web platform « Screening Tool for COVID-19 », specifically to:\
<ul>\
<li>Store your settings and preferences. Cookies tell us your preferences for example, your province or language.</li>\
</ul>\
Macadamian uses 1 types of cookies:\
<ul>\
<li>Performance and functionality connection cookies: These cookies are not essential to the provision of our services. They facilitate the personalization and enhancement of the user experience. For example, they may remember your preferences and avoid having to re-enter information more than once or remember your credentials, so they don't have to enter them each time you use them.</li>\
</ul>\
Please note that the use of cookies only identifies you as a user and does not recognize you by name or address.\
<br><br>\
b)	Deactivation of Cookies<br><br>\
It is possible to prevent the personalization functions of our site by disabling cookies on your browser. You can do this by changing the settings in your browser or mobile device.\
<br><br>\
However, if you choose to decline cookies, certain pages or sections of our web application may not display properly or certain features may not be available.\
<br><br>\
5.	LIMIT TO COLLECTION, USE AND DISCLOSURE OF PERSONAL INFORMATION<br><br>\
Macadamian strives to limit the collection of personal information to what is strictly required to fulfill the purposes for which it is collected. Rest assured that we will not disclose or use your personal information for any other purpose unless you consent to such or as required by law.\
<br><br>\
In addition, as you will see in the Privacy Safeguards section, we limit access to your personal information only to those individuals who have the authority and need to access it for a specific purpose.\
<br><br>\
Please note that we do not voluntarily collect information from persons under the age of 16. If we learn that we have accidentally collected personal Information from a person under age 16 without verification of parental consent, we will delete that information.  If you believe that we might have any information from or about a person under 16, please notify us using the contact information found at https://www.macadamian.com\
<br><br>\
6.	PERSONAL INFORMATION SHARING<br><br>\
To provide you with our services, we may share the personal information we collect with certain third parties.\
<br><br>\
During its activities, Macadamian may disclose your information to third parties, which includes but is not limited to:\
<ul>\
<li>Third-party service providers, including but not limited to services related to our web application (hosting, security, etc.). The personal information provided is then limited to only the information required to enable them to carry out their services and these suppliers are obliged to maintain the confidentiality of this information,</li>\
<li>Governmental public authorities and law enforcement agencies when required by applicable laws or when necessary for the rendering of our service.</li>\
</ul>\
Macadamian will never, by any means, sell or exchange your personal information for payment.\
<br><br>\
a)	Transfer of personal information abroad<br><br>\
Macadamian occasionally calls upon service providers located outside of Canada to carry out specific mandates in the normal course of business. Although most of our service providers are located in Canada, it is possible that some of your personal information may be transferred to another country and be subject to the laws of that country.\
<br><br>\
We do, however, ensure that we protect the personal information in our hands, including personal information entrusted to a supplier whether located in Canada or abroad. Our privacy policies and practices require that a written commitment be signed by that service provider to comply with its obligation to maintain the confidentiality and security of personal information entrusted to it. This includes implementing solid and effective security measures, as well as prohibiting the disclosure of your information to others.\
<br><br>\
If you have any additional questions regarding the above, you may contact us using the contact information provided below.\
<br><br>\
7.	PERSONAL INFORMATION RETENTION<br><br>\
Personal information is retained for as long as necessary for the purposes set out in this Policy and to ensure that we comply with our legal and regulatory obligations.\
<br><br>\
In addition, and as mentioned in the previous section, your personal information may be kept in Canada or abroad. Of course, we ensure that we have the necessary security measures in place, as well as the relevant contractual agreements, to protect your personal information.\
<br><br>\
If you wish to learn more about how long we keep your personal information, we invite you to contact us using the contact information provided below.\
<br><br>\
8.	PRIVACY SAFEGUARDS<br><br>\
Macadamian strives to apply the necessary and appropriate security measures to ensure the confidentiality of the personal information in its possession. In doing so, we follow generally accepted industry standards and all of our connections are encrypted in accordance with the security protocol Transport Layer Security («TLS»). The personal information we hold is therefore accessible only to persons who are qualified to have access to it and who consult it only when necessary in the performance of their duties.\
<br><br>\
Appropriate physical, technical, and/or administrative safeguards and security measures have been put in place and are maintained to prevent:\
<ul>\
<li>Any accidental or unlawful destruction,</li>\
<li>Accidental loss,</li>\
<li>Unauthorized disclosure,</li>\
<li>Unauthorized modification,</li>\
<li>Unauthorized access,</li>\
<li>Any illegal treatment.</li>\
</ul>\
In order to do so, we ensure, among other things, that access to our offices and computer equipment is restricted, that our staff is trained and that our agents and suppliers of goods and services with access to personal information are required to sign confidentiality agreements and implement security measures deemed equivalent.\
<br><br>\
It should be noted, however, that no method of transmission over the Internet, nor any means of electronic storage is completely secure. Therefore, we cannot guarantee the security of any personal information transmitted to us or that you provide to us, and that you do so at your own risk.\
<br><br>\
We also cannot guarantee that such personal information will not be obtained, accessed, disclosed, modified, or destroyed as a result of a breach of our protection and security measures.\
<br><br>\
If you believe that your personal information has been compromised, we invite you to contact us.\
<br><br>\
9.	INDIVIDUAL RIGHTS<br><br>\
a)	Right of access and modification<br><br>\
You may request access to your personal information or inquire about the existence of such information or about the way we collect, use, maintain and/or disclose your personal information. You may also request that information held by Macadamian be corrected if it is found to be inaccurate, equivocal, or incomplete. You may submit a written request to this effect.\
<br><br>\
If you wish to obtain copies of your personal information, we may be required to charge a nominal fee, which will be disclosed to you in advance, simply to cover the cost of transcription, reproduction or transmission.\
<br><br>\
b)	Right to data portability<br><br>\
You will be entitled to receive the personal information you have provided to us, in a portable, structured, commonly used, machine readable, and, to the extent technically feasible, readily useable format that allows you to transmit this information to another entity without hindrance.\
<br><br>\
c)	Right to be forgotten and right to erasure<br><br>\
You also have the right to request that all of the personal information we collected about you be deleted, unless we are required by law to do otherwise. Once we receive your request, and we confirm your identity, we shall delete all of your personal information we from our records and we will also instruct our service providers to also delete them form their records.\
<br><br>\
d)	Right to Restrict Processing<br><br>\
You will be entitled to obtain from Macadamian a restriction to process when any of the following applies:\
<ul>\
<li>For a period of time that allows us to verify the accuracy of the personal data while you dispute the accuracy of the personal information;</li>\
<li>The processing is unlawful and you object to the deletion of personal information and instead require a restriction to process by limiting the personal information use;</li>\
<li>We no longer require the personal information for the purposes of the processing, but it is still necessary for you to establish, exercise or defend legal rights;</li>\
<li>You object to the processing during a verification as to whether the legitimate grounds pursued by us prevail over your own.</li>\
</ul>\
e)	Right to Object<br><br>\
You may object, at any time, for reasons relating to your particular situation, to the processing of your personal information which is necessary for the legitimate interests pursued by Macadamian or by a third party or for the performance of a task carried out in the public interest or in the exercise of a public authority vested in Macadamian, including profiling. We will no longer process Personal Information unless we demonstrate that there are compelling legitimate reasons for the processing that prevail over your interests, rights and freedoms or for the establishment, exercise or defence of legal rights.<br><br>\
f)	Automated individual Decision-Making, Including Profiling<br><br>\
You may not be the subject of a decision based exclusively on automated processing, including profiling, that produces legal effects concerning you or significantly affects you in a similar manner, unless the decision is:\
<ul>\
<li>necessary for the conclusion or performance of a contract between you and Macadamian;</li>\
<li>authorized by the law to which Macadamian is subject and which also provides for appropriate measures to safeguard your rights, freedoms and legitimate interests; or</li>\
<li>based on your explicit consent.</li>\
</ul>\
To learn more about your rights or the steps to follow to make such request, please communicate with us to the following address: dkandanchatha@macadamian.com  Note that we will not discriminate against you for the exercise of these rights.\
<br><br>\
10.	LINKS TO SITES<br><br>\
It is important to understand that this Policy does not apply to other third-party websites that may be accessed through links on our web application. We are not responsible for those third-party sites, their content or access. Therefore, any personal information you transmit through these sites is subject to the privacy policies of those sites. It is your responsibility to review their privacy policies to ensure the protection of your personal information.\
<br><br>\
11.	QUESTIONS, COMMENTS, COMPLAINTS<br>\
To contact us, ask questions about this Policy, provide us with your comments, submit a request for access or correction, exercise any applicable rights, file a complaint or obtain information about our practices or policies, we invite you to contact us at dkandanchatha@macadamian.com.\
<br><br>\
12.	UPDATING THIS POLICY<br><br>\
The content of our web application can be changed at any time. Accordingly, this Policy may be subject to change from time to time in the future. We recommend that you review it whenever you visit our web application to stay informed about how we handle personal information.\
<br><br>\
This Policy was last updated on July 1 2020.\
";

export let PrivacyPolicy_fr = "\
1 juillet 2020\
<br><br>\
Macadamian Technologies (ci-après « Macadamian ») accorde une importance particulière au respect de votre vie privée et à la protection de vos renseignements personnels.\
<br><br>\
La présente Politique sur la protection des renseignements personnels (ci-après la « Politique ») a pour objectif de vous informer de la façon dont Macadamian collecte, utilise, divulgue, conserve et protège vos renseignements personnels.\
<br><br>\
Afin de vous offrir des services de qualité, nous devons avoir accès à certains renseignements personnels vous concernant. Nous nous assurons que nos employés et nos sous-traitants gèrent ces renseignements avec toute la discrétion et la rigueur nécessaires, et ce, en conformité avec les exigences légales et réglementaires en vigueur.\
<br><br>\
En utilisant cette application, vous consentez à la présente Politique.\
<br><br>\
1.	RENSEIGNEMENT PERSONNEL<br><br>\
Nous entendons par « renseignement personnel » tout renseignement concernant une personne identifiable ou qui, pris séparément ou combiné avec d’autres données, permet d’identifier une personne.\
<br><br>\
2.	COLLECTE DE RENSEIGNEMENTS PERSONNELS<br><br>\
Dans le cadre de nos interactions avec vous, que ce soit lors de notre prestation de services ou lors de nos activités, nous devons recueillir certains renseignements personnels vous concernant. Les renseignements personnels que nous recueillons sont nécessaires afin de vous offrir les services demandés. Cela comprend notamment sans s’y limiter :\
<br>\
<ul>\
<li>Vos coordonnées comme un nom, un courriel ou un numéro de téléphone;</li>\
<li>Vos coordonnées professionnelles comme l’entreprise pour laquelle vous travaillez, le département et le lieu où vous travaillez pour la journée;</li>\
<li>Des renseignements relatifs à votre état de santé comme vos symptômes, votre état physique ou toutes autres informations nécessaires en lien avec le virus COVID-19;</li>\
<li>Des renseignements relatifs à l’utilisation de notre application Web incluant des renseignements techniques sur les visites ou tout autre renseignement collecté à travers des témoins ou d’autres outils similaires;</li>\
<li>Tout autre renseignement personnel fourni.</li>\
</ul>\
<br>\
3.	CONSENTEMENT<br><br>\
Règle générale, Macadamian obtiendra directement les renseignements personnels nécessaires auprès de vous, et ce, avec votre consentement, sous réserve des exceptions prévues à la loi.\
<br><br>\
En nous fournissant des renseignements personnels, par l’intermédiaire de notre application Web, par courriel, ou par tout autre moyen, VOUS CONSENTEZ À CE QUE VOS RENSEIGNEMENTS PERSONNELS SOIENT COLLECTÉS, UTILISÉS ET DIVULGUÉS COMME PRÉVU DANS LA PRÉSENTE POLITIQUE.\
<br><br>\
Nous pouvons également recueillir des renseignements personnels auprès de tiers selon ce qui est permis par la loi ou si nous avons recueilli le consentement nécessaire.\
<br><br>\
Macadamian ne recueillera, n’utilisera, ni ne divulguera les renseignements personnels sans votre consentement à moins qu’il ne soit autorisé ou obligé de le faire en vertu de la réglementation applicable. À titre d’exemple, Macadamian pourrait devoir procéder sans votre consentement notamment pour les raisons suivantes :\
<ul>\
<li>Lorsque, pour des raisons de sécurité ou d’ordre médical ou juridique, il nous est impossible d’obtenir votre consentement;</li>\
<li>Lorsque, pour des raisons de détection ou de prévention de la fraude ou pour l’application de la loi, le fait de demander le consentement de l’individu ferait échouer la démarche.</li>\
</ul>\
<br>\
À noter que vous avez le droit de refuser de nous communiquer des renseignements personnels. Vous avez également le droit, sous réserve d’un préavis raisonnable et des restrictions légales ou contractuelles applicables, de retirer votre consentement concernant l’utilisation des renseignements personnels déjà collectés en communiquant avec notre Responsable de la vie privée. Le refus de communiquer des renseignements ou le retrait de votre consentement pourraient nous empêcher de fournir ou de continuer à vous fournir certains de nos services.\
<br><br>\
Macadamian pourrait également vous demander votre consentement à des fins secondaires. Tel que nous l’avons mentionné précédemment, nous vous demanderons votre consentement à cet égard. De plus, votre consentement à des fins secondaires peut être retiré en tout temps, et ce, sans affecter la prestation de nos services auprès de vous.\
<br><br>\
4.	FINS AUXQUELLES LES RENSEIGNEMENTS PERSONNELS SONT RECUEILLIS<br><br>\
Macadamian collecte, utilise et divulgue des renseignements personnels aux fins déterminées au moment de la collecte ou selon ce qui est permis ou exigé par la loi. Cela comprend notamment les fins suivantes :\
<ul>\
<li>Répondre aux questions du questionnaire;\
<li>Analyser les réponses aux questionnaires;</li>\
<li>Partager les renseignements personnels avec votre employeur lors de l’analyse des questionnaires;</li>\
<li>Envoyer des communications occasionnelles à propos de votre compte ou de notre application web par courriel;</li>\
<li>Permettre de faciliter l’utilisation de notre application Web et assurer sa pertinence, suivre l’utilisation faite de notre application Web, répondre aux questions soumises par l’entremise de notre application Web;</li>\
<li>Effectuer du développement et de la recherche afin de s’assurer de maintenir une sécurité du plus haut standard, et de comprendre les exigences de notre clientèle pour améliorer notre offre;</li>\
<li>Tel que cela est permis ou exigé, pour toute obligation ou disposition légale ou réglementaire applicable;</li>\
<li>Toute autre fin à laquelle vous avez consenti.</li>\
</ul>\
a)	Utilisation de fichiers témoins (cookies)<br><br>\
Un témoin est un petit fichier de données créé par votre navigateur et placé sur votre ordinateur lorsque vous visitez un site, une page ou une application Web. Les témoins permettent aux sites Web de mémoriser certaines informations à votre sujet en vue de faciliter votre accès et votre navigation Internet à l’intérieur de nos sites. Les fichiers témoins ne peuvent être lus que par l’application Web qui les transmet à votre ordinateur.\
<br><br>\
Nous utilisons certains fichiers témoins sur l’application Web du « Questionnaire de dépistage de la COVID-19 » notamment pour :\
<ul>\
<li>Mémoriser vos réglages et préférences. Les témoins nous indiquent vos préférences, par exemple, votre langue.</li>\
</ul>\
Macadamian utilise 1 type de témoins :\
<ul>\
<li>Le témoin de connexion de performance et de fonctionnalité : Ces témoins ne sont pas essentiels à la prestation de nos services. Or, ils facilitent la personnalisation et l’amélioration de l’expérience de l’utilisation. À titre d’exemple, ils peuvent se souvenir de vos préférences et ainsi vous permettre d’éviter de devoir saisir les informations plus d'une fois ou se souvenir de vos identifiants, de sorte que vous n’avez pas à les saisir à chaque fois que vous les utilisez.</li>\
</ul>\
Veuillez noter que l’utilisation de fichiers témoin permet seulement de vous identifier en tant qu’utilisateur et ne vous reconnaît pas au moyen de votre nom ni de votre adresse.\
<br><br>\
b)	Désactivation de fichiers témoins (cookies)<br><br>\
Il est possible d’empêcher les fonctions de personnalisation de notre site en désactivant les témoins sur votre navigateur. Vous pouvez le faire en modifiant les paramètres de votre navigateur ou de votre appareil mobile.\
<br><br>\
Toutefois, si vous décidez de refuser les témoins, certaines fonctionnalités de notre application Web pourraient ne pas s’afficher correctement ou ne pas être disponibles.\
<br><br>\
5.	LIMITE À LA COLLECTE, À L’UTILISATION ET À LA DIVULGATION DE RENSEIGNEMENTS PERSONNELS<br><br>\
Macadamian s’efforce de limiter la collecte de renseignements personnels à ce qui est strictement nécessaire afin d’accomplir les fins pour lesquelles ils sont recueillis. Soyez assurés que nous ne divulguerons ni n’utiliserons vos renseignements personnels à des fins autres, à moins que vous n’y consentiez ou que la loi ne le prévoie.\
<br><br>\
De plus, comme vous pourrez le constater dans la section Mesures de sécurité relatives aux renseignements personnels, nous limitons l’accès de vos renseignements personnels qu’aux personnes ayant la qualité et la nécessité d’y accéder, et ce, pour une fin spécifique.\
<br><br>\
À noter que nous ne collectons pas volontairement des renseignements auprès de personnes âgées de moins de 14 ans. Si nous apprenons que nous avons collecté ce type d’information sans le consentement au préalable du titulaire de l’autorité parentale, nous supprimerons les renseignements personnels collectés. Si vous êtes d’avis que nous pourrions être en possession de renseignements provenant ou concernant une personne de moins de 14 ans, prière de nous notifier en utilisant les coordonnées se trouvant sur le site https://www.macadamian.com\
<br><br>\
6.	PARTAGE DE RENSEIGNEMENTS PERSONNELS<br><br>\
Afin de vous fournir nos services, il se peut que nous procédions à la transmission des renseignements personnels collectés avec certains tiers.\
<br><br>\
Dans le cadre de ses activités, Macadamian pourrait divulguer vos renseignements à de tierces parties ce qui inclut notamment :\
<ul>\
<li>Des tiers fournisseurs de services incluant notamment les services relatifs à notre application Web (hébergement, sécurité, etc.). Les renseignements personnels fournis sont alors limités aux seuls renseignements nécessaires pour leur permettre de réaliser leurs services et ces fournisseurs sont obligés de préserver la confidentialité de ces renseignements;</li>\
<li>Des autorités publiques gouvernementales et des organismes d’application de la loi lorsque les lois applicables l’exigent ou lorsque cela est nécessaire pour la fourniture de nos services.</li>\
</ul>\
En aucun temps, Macadamian ne vendra ni n’échangera vos renseignements personnels moyennant paiement.\
<br><br>\
a)	Transfert de renseignements personnels à l’étranger<br><br>\
Macadamian fait parfois appel à des fournisseurs de services situés à l’extérieur du Canada pour accomplir certaines tâches spécifiques dans le cours normal de nos affaires. Bien que la majorité de nos fournisseurs se trouvent au Canada, il est toutefois possible que certains renseignements personnels vous concernant soient transférés dans un autre pays et qu’ils soient soumis aux lois de ce pays.\
<br><br>\
Nous nous assurons toutefois de protéger les renseignements personnels que nous avons sous notre garde, y compris les renseignements personnels confiés à un fournisseur qu’il soit situés au Canada ou à l’étranger. Nos politiques et pratiques en matière de protection des renseignements personnels requièrent qu’un engagement écrit soit signé par ce fournisseur de services afin de respecter son obligation de préserver la confidentialité et la sécurité des renseignements personnels qui lui sont confiés. Cela comprend notamment de mettre en place des mesures de sécurité robustes et efficaces, mais également l’interdiction de divulguer vos renseignements à autrui.\
<br><br>\
Pour toute question additionnelle à ce sujet, vous pouvez communiquer avec notre Responsable de la vie privée dont les coordonnées figurent ici-bas.\
<br><br>\
7.	CONSERVATION DES RENSEIGNEMENTS PERSONNELS<br><br>\
Les renseignements personnels ne sont conservés que pour la durée nécessaire aux fins prévues dans la présente Politique et afin de nous assurer d’être en conformité avec nos obligations légales et réglementaires.\
<br><br>\
De plus, comme mentionné dans la rubrique précédente, vos renseignements personnels peuvent être conservés au Canada ou à l’étranger. Évidemment, nous nous assurons de mettre en place les mesures de sécurité nécessaires ainsi que les ententes contractuelles pertinentes afin de protéger vos renseignements personnels.\
<br><br>\
Si vous souhaitez en apprendre davantage sur la durée de conservation de vos renseignements personnels, nous vous invitons communiquer avec notre Responsable de la vie privée dont les coordonnées figurent ici-bas.\
<br><br>\
8.	MESURES DE SÉCURITÉ RELATIVES AUX RENSEIGNEMENTS PERSONNELS<br><br>\
Macadamian s’efforce d’appliquer les mesures de sécurité nécessaires et appropriées afin d’assurer le caractère confidentiel des renseignements personnels en sa possession. Pour ce faire, nous suivons notamment les standards généralement acceptés dans l’industrie et toutes nos connexions sont cryptées selon le protocole de sécurité Transport Layer Security («TLS»). Les renseignements personnels que nous détenons ne sont donc accessibles qu’aux personnes qui ont qualité pour en prendre connaissance et qui les consultent uniquement lorsque cela est nécessaire dans l’exercice de leurs fonctions.\
<br><br>\
Des mesures de protection et de sécurité physiques, techniques et/ou administratives appropriées ont été mises en place et sont maintenues à jour afin d’éviter :\
<ul>\
<li>Toute destruction accidentelle ou non conforme aux lois;</li>\
<li>Toute perte accidentelle;</li>\
<li>Toute divulgation non autorisée;</li>\
<li>Toute modification non autorisée;</li>\
<li>Tout accès non autorisé; et</li>\
<li>Tout traitement illégal.</li>\
</ul>\
Pour ce faire, nous nous assurons notamment de restreindre les accès à nos bureaux et aux équipements informatiques, de former notre personnel et de requérir de nos mandataires et fournisseurs de biens et de services ayant accès à des renseignements personnels de signer des ententes de confidentialité et de mettre en place des mesures de sécurité jugées équivalentes.\
<br><br>\
À noter, toutefois qu’aucune méthode de transmission par Internet ni aucun moyen de stockage électronique n’est totalement sécuritaire. Nous ne pouvons donc garantir la sécurité des renseignements personnels qui nous sont transmis ou que vous nous fournissez, cela étant fait à vos risques et périls.\
<br><br>\
Nous ne pouvons également pas garantir que ces renseignements personnels ne seront pas obtenus, consultés, divulgués, modifiés ou détruits à la suite d’une violation de nos mesures de protection et de sécurité.\
<br><br>\
Si vous pensez que vos renseignements personnels ont été compromis, nous vous invitons à prendre contact avec nous en utilisant les coordonnées listées dans la section « Contact » de cette Politique.\
<br><br>\
9.	DROIT DES INDIVIDUS<br><br>\
a)	Droit d’accès et de rectification<br><br>\
Vous pouvez demander l’accès à vos renseignements personnels ou de l’information relative à l’existence de tel renseignement ou sur la façon dont nous collectons, utilisons, conservons et/ou divulguons vos renseignements personnels. Vous pouvez également demander que les renseignements détenus par Macadamian soient rectifiés s’ils s’avéraient inexacts, équivoques ou incomplets.\
<br><br>\
Vous pouvez soumettre une demande écrite à cet effet à écrivant au Responsable de la vie privée à l’adresse ici-bas.\
<br><br>\
Si vous désirez obtenir des copies de vos renseignements personnels, il se pourrait que nous devions vous exiger des frais minimes, lesquels vous seraient préalablement divulgués, et ce, simplement afin de couvrir les coûts de la transcription, de la reproduction ou de la transmission.\
<br><br>\
b)	Droit à la portabilité des données<br><br>\
Vous êtes en droit de recevoir vos renseignements personnels dans un format qui, dans la mesure où c’est techniquement possible, est non seulement facile d’utilisation, mais qui vous permet aussi de transmettre, sans entrave, vos renseignements à toute autre entité.\
<br><br>\
c)	Droit à l’oubli et droit à l’effacement<br><br>\
Vous pouvez demander le retrait et la suppression de tous les renseignements que nous avons collectés à votre sujet, sous réserve des exceptions prévues par la loi. À la suite de votre demande, et une fois votre identité vérifiée, nous supprimerons tous vos renseignements de nos registres et nous avertirons également nos fournisseurs afin qu’ils puissent eux aussi supprimer vos renseignements.\
<br><br>\
d)	Droit à la limitation du traitement<br><br>\
Vous pouvez aussi nous demander de limiter les traitements à exécuter sur vos renseignements personnels dans les cas suivants :\
<ul>\
<li>Pour une période qui nous permet de vérifier la véracité des données personnelles lorsque vous contestez la véracité des renseignements personnels;</li>\
<li>Le traitement est illégal et vous vous opposez à la suppression des renseignements personnels et demandez plutôt que l’usage de ces derniers soit limité;</li>\
<li>Vous vous opposez au traitement lors d’une vérification visant à déterminer si les motifs légitimes que nous invoquons prévalent sur les vôtres.</li>\
</ul>\
e)	Droit d'opposition<br><br>\
Vous êtes en droit de vous opposer, à tout moment, pour des raisons en lien avec votre situation particulière, aux traitements nécessaires de vos renseignements personnels par Macadamian ou par un tiers, ou pour l’exécution d’une tâche d’intérêt public ou pour l’exercice d’une autorité publique octroyée à Macadamian, incluant le profilage. Nous ne traiterons plus vos renseignements personnels, sauf pour démontrer qu’il existe des raisons légitimes à l’exécution d’un traitement qui prévalent sur vos intérêts, droits et libertés ou pour l’établissement, l’exercice et la défense des droits légaux.<br><br>\
f)	Décision individuelle automatisée, incluant le profilage<br><br>\
Vous pouvez demander à ne pas être soumis à une décision fondée exclusivement sur un traitement automatisé, incluant le profilage, qui produit des effets légaux vous concernant ou qui vous affecte considérablement de façon similaire, à moins que la décision soit :\
<ul>\
<li>Nécessaire aux fins de la conclusion ou de l’exécution d’un contrat entre vous et Macadamian;</li>\
<li>Autorisée en vertu de la loi à laquelle Macadamian est soumise et qui prévoit aussi les mesures appropriées à la sauvegarde de vos droits, de vos libertés et de vos intérêts légitimes; ou </li>\
<li>Fondée sur votre consentement explicite.</li>\
</ul>\
Pour en savoir davantage sur le processus à suivre, vous pouvez communiquer avec nous à l’adresse suivante : dkandanchatha@macadamian.com  Notez que nous ne ferons pas de discrimination à votre égard pour l’exercice de ces droits.\
<br><br>\
10.	LIENS VERS DES SITES<br><br>\
Il est important de comprendre que cette Politique ne s’applique pas à d’autres sites Web ou applications Web tiers auxquels il serait possible d’accéder à partir de liens présents sur notre application Web. Nous ne sommes nullement responsables à l’égard de ces sites tiers, de leur contenu ou de leur accès. Par conséquent, tout renseignement personnel que vous transmettez par l’intermédiaire de ces sites est assujetti à la politique de protection de la vie privée de ces derniers. Il est de votre responsabilité d’en prendre connaissance afin d’assurer la protection de vos renseignements personnels.\
<br><br>\
11.	QUESTIONS, COMMENTAIRES, PLAINTES<br>\
Pour prendre contact avec nous, poser des questions relatives à cette Politique, nous faire part de vos commentaires, soumettre une demande d’accès ou de rectification, exercer tout droit applicable, déposer une plainte ou obtenir de l’information sur nos pratiques ou politiques, nous vous invitons à prendre contact avec notre responsable de la protection de la vie privée à l’adresse courriel dkandanchatha@macadamian.com.\
<br><br>\
12.	MISE À JOUR DE CETTE POLITIQUE<br><br>\
Le contenu de notre application Web peut être modifié à tout moment. Par conséquent, cette Politique peut faire l’objet d’une modification de temps à autre à l’avenir. Il est recommandé de la consulter à chacune de vos visites sur notre application Web afin de rester informé de la manière dont nous traitons les renseignements personnels.\
<br><br>\
La présente Politique a été mise à jour la dernière fois en date du 1 juillet 2020.\
";

