import React, { useCallback, useContext, useEffect, useRef } from 'react';
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from '@material-ui/core';
import _ from 'lodash';
import { DataContext } from '../../contexts/DataContext';
import { getKioskableValue } from '../../util/getKioskableValue';

export function Reason({
  reason,
  setReason,
  isKiosk = false,
}) {
  const { surveyConfig } = useContext(DataContext);
  const visitInformation = _.get(surveyConfig, 'visitInformation');

  const reasonEnabled = getKioskableValue(isKiosk, visitInformation, "reason");
  const reasonLabel = _.get(visitInformation, 'reasonLabel', 'TODO: reason');
  const reasonList = _.get(visitInformation, 'reasonList', []);

  const onChange = useCallback(
    (event) => setReason(event.target.value),
    [setReason]
  );

  // Automatically select the first item, if not initialy set
  const reasonRef = useRef(reason);
  useEffect(() => {
    if (!reasonEnabled) {
      return;
    }
    if (reasonRef.current) {
      return;
    }

    reasonRef.current = _.first(reasonList);
    setReason(reasonRef.current);
  }, [reasonEnabled, reasonRef, reasonList]);

  if (!reasonEnabled) {
    return null;
  }

  return (
    <Grid item container direction="column" className="section reason">
      <Typography variant='h5' color="primary">
        {reasonLabel}
      </Typography>

      <RadioGroup value={reason} onChange={onChange}>
        {
          _.map(reasonList, (r, index) => <FormControlLabel
            key={index}
            value={r}
            label={r}
            control={<Radio color="primary" />}
          />)
        }
      </RadioGroup>
    </Grid>
  );
}
